import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import IcoX from '../../icons/IcoX';
import IcoArrDown from '../../icons/IcoArrDown';

import Loader from "../../components/Loader";
import Util, { capitalizeFirstLetter } from '../../utils/Util';
import Fetch from '../../utils/Fetch';
import Constants from '../../Constants';

import styles from './SymbolAddDialog.module.css';

SymbolAddDialog.defaultProps = {
    open: false,
    onOk: null,
    onCancel: null,
    onStatus: null,
};

SymbolAddDialog.propTypes = {
    open: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    onStatus: PropTypes.func,
}

const TradeDialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: "transparent",
    }
}));

function SymbolAddDialog(props) {

    const [loading, setLoading] = useState(true);

    const [symbols, setSymbols] = useState([]);

    const symbolInputElm = useRef(null);
    const [symbolInputFocus, setSymbolInputFocus] = useState(false);
    const [symbol, setSymbol] = useState("");




    useEffect(()=>{
        return ()=>{
            //console.log("unmount");
        }
    }, []);
    

    useEffect(()=>{
        if(props.open){
            setSymbol("");
            getData();
        }
    }, [props.open]);


    function getData(){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/symbolsAddInit.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {   

                let symbolsInUse = {};
                for(let i = 0; i < json.symbols.length; i++){
                    let symbol = json.symbols[i].symbol.toUpperCase();
                    symbolsInUse[symbol] = true
                }
            
                //exchange info
                let filteredSymbols = [];
                if(json.exchange_info != null && json.exchange_info.symbols && Array.isArray(json.exchange_info.symbols)){    
                    let symbols = json.exchange_info.symbols;
                    for(let i = 0; i < symbols.length; i++){
    
                        if(symbols[i].status === "TRADING" && symbols[i].isSpotTradingAllowed === true){
                            if(symbols[i].orderTypes.indexOf('LIMIT') !== -1 && symbols[i].orderTypes.indexOf('MARKET') !== -1){
                                if(symbolsInUse[symbols[i].baseAsset]){
                                    //already in use
                                }else{
                                    if(symbols[i].quoteAsset === "USDT"){
                                        filteredSymbols.push(symbols[i].baseAsset);
                                    }
                                }
                            
                            }
                        }

                    } 
                }

                filteredSymbols.sort(function(a, b) {
                    if (a < b) {
                      return -1;
                    }
                    if (a > b) {
                      return 1;
                    }
                    return 0;
                });

                setSymbols(filteredSymbols);

                symbolInputElm.current.focus();
                setLoading(false); 
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function onOk(){
        if(props.onOk){
            props.onOk();
        }
    }

    function onCancel(){
        if(props.onCancel){
            props.onCancel();
        }
    }

    function onStatus(status){
        let cbObject = {
            status: status,
            symbol: symbol.toUpperCase(),
        }
        if(props.onStatus){
            props.onStatus(cbObject);
        }
    }

    

    function addSymbol(){

        let lcSymbol = symbol.toUpperCase();

        let found = false;
        for(let i = 0; i < symbols.length; i++){
            if(symbols[i] === lcSymbol){
                found = true;
                break;
            }
        }

        if(!found){
            return;
        }

        if(props.onOk){
            props.onOk();
        }

        lcSymbol = symbol.toLowerCase();

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/symbolsAdd.php?symbol="+lcSymbol;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    onStatus("success");
                    return;
                }

                onStatus("error");
                
            })
            .catch((error) => {
                console.log(error);
                onStatus("error");
            });
    }

    function onChangeSymbol(e){
        setSymbol(e.target.value.trim());
    }

    function onSymbolItemClick(symbol){
        setSymbol(symbol);
        setSymbolInputFocus(false);
    }

    let loader = null;
    if(loading){
        loader = (<div className={styles.loading}><Loader /></div>);
    }

    let filteredSymbols = []; 
    for(let i = 0; i < symbols.length; i++){
        // if(symbol === ""){
        //     continue;
        // }

        if(Util.search(symbol, symbols[i])){
            //ok
        }else{
            continue;
        }

        filteredSymbols.push(symbols[i]);

        if(filteredSymbols.length >= 5){
            break;
        }
    }

    return (

        <TradeDialogStyled
            open={props.open}
            onClose={onCancel}>

            <div className={styles.content}>
                <div className={styles.head}>
                    <div className={styles.title}>Add symbol to WT table</div>
                    <div className={styles.close} onClick={onCancel}>
                        <IcoX fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyCenter}>
                        
                        <div className={styles.textInput} style={{marginTop: 12}}>
                            <input 
                                ref={symbolInputElm} 
                                type="text" 
                                placeholder="Enter symbol" 
                                value={symbol} 
                                onFocus={()=>{setSymbolInputFocus(true)}}
                                onChange={onChangeSymbol} />
                            <IcoArrDown className={styles.displayValueArrow} fill="rgba(255,255,255,0.4)" />
                        </div>

                        <div className={styles.sugestions} style={{marginTop: 12, visibility:symbolInputFocus?"visible":"hidden"}}>
                            {filteredSymbols.map((row, index) => {
                                return (
                                    <div 
                                        key={row} 
                                        className={styles.item} 
                                        onClick={()=>{onSymbolItemClick(row)}}>{row}</div>
                                )
                            })}
                        </div>

                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.btnCancel} onClick={onCancel}>Cancel</div>
                    <div className={styles.btnOk} onClick={addSymbol}>Add</div>
                </div>

                {loader}
            </div>

        </TradeDialogStyled>

    );
}

export default SymbolAddDialog;