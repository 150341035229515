import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import IcoBlock from '../../icons/IcoBlock';

import styles from './ItemIndicatorG.module.css';

ItemIndicatorG.defaultProps = {
    nr: 0,

    symbol: "",

    gain_percent: "0",

    wt1_1m: "0",
    wt2_1m: "0",

    wt1_15m: "0",
    wt2_15m: "0",

    wt1_1h: "0",
    wt2_1h: "0",

    v_1h: "0",
    vh_1h: "0",

    lp_5m: "0",

    last_update: "0",

    ticker: 0,

    fav_coin: "0",

    ex_vol_24h: 0,
    ex_volq_24h: 0,

    current_vol_24h: 0,
    current_volq_24h: 0,

    ex_h: 0,
    source: "gainer",

    vol_m_5m: 0,
    volq_m_5m: 0,
    vol_m_15m: 0,
    volq_m_15m: 0,
};

ItemIndicatorG.propTypes = {
    nr: PropTypes.number,

    symbol: PropTypes.string,

    gain_percent: PropTypes.string,

    wt1_1m: PropTypes.string,
    wt2_1m: PropTypes.string,

    wt1_15m: PropTypes.string,
    wt2_15m: PropTypes.string,

    wt1_1h: PropTypes.string,
    wt2_1h: PropTypes.string,

    v_1h: PropTypes.string,
    vh_1h: PropTypes.string,

    lp_5m: PropTypes.string,

    last_update: PropTypes.string,

    ticker: PropTypes.number,

    fav_coin: PropTypes.string,

    ex_vol_24h: PropTypes.number,
    ex_volq_24h: PropTypes.number,

    current_vol_24h: PropTypes.number,
    current_volq_24h: PropTypes.number,

    ex_h: PropTypes.number,
    source: PropTypes.string,
}

function ItemIndicatorG(props) {

    // console.log(props.ex_vol_24h+" / "+props.ex_volq_24h+" / "+props.current_vol_24h+" / "+props.current_volq_24h);

    let wt1_1m = parseInt(props.wt1_1m);
    let wt2_1m = parseInt(props.wt2_1m);

    let wt1_15m = parseInt(props.wt1_15m);
    let wt2_15m = parseInt(props.wt2_15m);

    let wt1_1h = parseInt(props.wt1_1h);
    let wt2_1h = parseInt(props.wt2_1h);

    let style1m={};
    if( (wt1_1m < -37) && (wt2_1m < -37) ){
        style1m.color = "#23D785";
    }

    let style15m={};
    if( (wt1_15m < -37) && (wt2_15m < -37) ){
        style15m.color = "#23D785";
    }

    let style1h={};
    if( (wt1_1h < -37) && (wt2_1h < -37) ){
        style1h.color = "#23D785";
    }

    let vol = 0;
    // if(parseFloat(props.v_1h) !== 0){
    //     vol = ( (parseFloat(props.v_1h) - parseFloat(props.vh_1h))*100 ) / parseFloat(props.v_1h);
    // }

    if(props.current_volq_24h !== 0 && props.ex_volq_24h !== 0){
        vol = (props.current_volq_24h * 100 / props.ex_volq_24h)-100;

        if(props.ex_volq_24h === 0.01){
            vol = 0;
        }

        // vol = (props.current_volq_24h - props.ex_volq_24h)*100/props.ex_volq_24h;
    }
    
    vol = vol.toFixed(2);

    if(parseFloat(vol) > 0){
        vol = "+"+vol;
    }


    //for last 5m vol %
    let volM5m = 0;
    if(props.current_volq_24h !== 0 && props.volq_m_5m !== 0){
        volM5m = (props.current_volq_24h * 100 / props.volq_m_5m)-100;
        volM5m = volM5m.toFixed(2);
        if(parseFloat(volM5m) > 0){
            volM5m = "+"+volM5m;
        }
    }

    //for last 15m vol %
    let volM15m = 0;
    if(props.current_volq_24h !== 0 && props.volq_m_15m !== 0){
        volM15m = (props.current_volq_24h * 100 / props.volq_m_15m)-100;
        volM15m = volM15m.toFixed(2);
        if(parseFloat(volM15m) > 0){
            volM15m = "+"+volM15m;
        }
    }

    let favIcon = "";
    if(props.fav_coin === "1"){
        favIcon = "★";
    }

    let customStyle = {};
    if(props.blacklist == 1){
        customStyle["backgroundColor"] = "#F5465D";
    }    

    let symbolStyle = {};
    if(props.source === "manual"){
        symbolStyle["color"] = "#EFBA0C";
    }

    let lp5mLabel = "Y";
    if(props.ticker > parseFloat(props.lp_5m)){
        lp5mLabel = "N";
    }

    let gainPercentLabel = props.gain_percent;
    if(parseFloat(props.gain_percent) > 0){
        gainPercentLabel = "+"+gainPercentLabel;
    }

    return (
        <tr style={customStyle}>
            <td className={styles.col1}>{props.nr}</td>
            <td className={styles.col2} style={symbolStyle}>{props.symbol} {favIcon}</td>
            <td className={styles.col3} style={style1m}>{wt1_1m} / {wt2_1m}</td>
            <td className={styles.col3} style={style15m}>{wt1_15m} / {wt2_15m}</td>
            <td className={styles.col3} style={style1h}>{wt1_1h} / {wt2_1h}</td>
            <td className={styles.col4}>{gainPercentLabel}</td>
            <td className={styles.col6}>{parseFloat(props.lp_5m)} / {lp5mLabel}</td>
            {/* <td className={styles.col7}>&nbsp;</td> */}
            <td className={styles.col8}>{Util.formatNumber(parseFloat(props.current_volq_24h / 1000000),2,2)} m</td>
            <td className={styles.col9}>{vol}</td>

            <td className={styles.col9}>{volM5m}</td>
            <td className={styles.col9}>{volM15m}</td>

            <td className={styles.col10}>{parseFloat(props.ex_h)}</td>
            <td className={styles.col11}>{Util.timeAgo(props.last_update)} ago</td>
            <td className={styles.col12}>
                <div className={styles.menu}><IcoBlock style={{height: 16}}/></div>
                {props.children}
            </td>
        </tr>
    );
    
}


export default ItemIndicatorG;