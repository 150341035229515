import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import styles from './ItemTrail.module.css';

ItemTrail.defaultProps = {
    nr_crt: 0,
    coin: "",
    coin2: "",
    target_price: 0,
    sell_price: null,
    price_initial: 0,
    price_new: 0,
    coin2_price_usd: 0,

    buy_amount_arr: [],
    buy_price_arr: [],
    buy_count: 0,

    balance_usdt: 0,

    price_decimals: 0,

    strategy: "",
    sellType: "MARKET",
    slot: {},
    exchange_info: {},
    tickers: {},
};

ItemTrail.propTypes = {
    nr_crt: PropTypes.number,
    coin: PropTypes.string,
    coin2: PropTypes.string,
    target_price: PropTypes.number,
    sell_price: PropTypes.number,
    price_initial: PropTypes.number,
    price_new: PropTypes.number,
    coin2_price_usd: PropTypes.number,

    buy_amount_arr: PropTypes.array,
    buy_price_arr: PropTypes.array,
    buy_count: PropTypes.number,

    balance_usdt: PropTypes.number,

    price_decimals: PropTypes.number,

    strategy: PropTypes.string,
    sellType: PropTypes.string,
    slot: PropTypes.object,
    exchange_info: PropTypes.object,
    tickers: PropTypes.object,
}

function ItemTrail(props) {

    let priceNew = 0;
    let priceDecimals = 0;
    let quantityDecimals = 0;
    if(props.exchange_info[props.slot.pair]){
        priceDecimals = Util.getDecimalsCount(props.exchange_info[props.slot.pair].min_trade_price);
        quantityDecimals = Util.getDecimalsCount(props.exchange_info[props.slot.pair].min_trade_quantity);
    }

    if(props.tickers[props.slot.pair]){
        priceNew = props.tickers[props.slot.pair];
    }

    let nextBuyPercent = 0;
    if(priceNew > 0){
        nextBuyPercent = (props.slot.next_buy_price * 100 / priceNew)-100;
        // nextBuyPercent = Math.abs(nextBuyPercent);
        nextBuyPercent = nextBuyPercent.toFixed(2);
        if(parseFloat(nextBuyPercent)>0){
            nextBuyPercent = "+"+nextBuyPercent;
        }
    }

    let totalInvested = 0;
    let totalAmount = 0;
    let profitPercent = 0;
    let profitDiff = 0;
    let ttpOscPercent = 0;
    let ttpFinal = 0;
    let ttpActivated = false;
    for(let i = 0; i < props.slot.buy_arr.length; i++){
        let price = parseFloat(props.slot.buy_price_arr[i]);
        let amount = parseFloat(props.slot.buy_amount_arr[i]);

        totalInvested = totalInvested + (price*amount);
        totalAmount = totalAmount + amount;
    }

    // console.log("totalInvested: "+totalInvested);
    // console.log("totalAmount: "+totalAmount);
    // let breakEven = totalInvested / totalAmount;
    // console.log("breakEven: "+breakEven);
    // let tp = breakEven + (0 / 100 * breakEven);
    // console.log("tp: "+tp);




    let bgProfitOsc = "#2EBC85";
    if(priceNew !== 0 && totalInvested !== 0){

        let win = totalAmount*priceNew;

        profitDiff = win-totalInvested;
        if(profitDiff > 0){
            profitDiff = "+"+profitDiff.toFixed(2);
        }else{
            profitDiff = profitDiff.toFixed(2);
        }

        profitPercent = ((win*100)/totalInvested)-100;
        if(profitPercent > 0){
            profitPercent = "+"+profitPercent.toFixed(2);
        }else{
            profitPercent = profitPercent.toFixed(2);
            bgProfitOsc = "#F5465D";
        }

        if(props.slot.target_price != null){
            ttpFinal = props.slot.target_price;
        }
        
        if(props.slot.sell_price != null){
            ttpFinal = props.slot.sell_price;
            ttpActivated = true;
        }

        ttpOscPercent = (priceNew * 100 / ttpFinal)-100;
        if(ttpOscPercent > 0){
            ttpOscPercent = "+"+ttpOscPercent.toFixed(2);
        }else{
            ttpOscPercent = ttpOscPercent.toFixed(2);
        }
    }


    let trail_sell_price_percent = props.slot.trail_sell_price_percent;
    let trail_activation_percent = props.slot.trail_activation_percent;

    if(props.slot.trail_activation_type === "PROFIT_PERCENT" 
        && props.slot.buy_count > 1 
        && props.slot.vol_trend_percent 
        && props.slot.vol_trend_percent < -0.2){

        trail_sell_price_percent = 0;
        trail_activation_percent = 0;
    }


    let ttpElm = null;

    if(ttpActivated){
        if(props.sellType === "LIMIT"){
            ttpElm = (<div className={styles.ttp}>Sell Limit {trail_activation_percent}%</div>);
        }else{
            ttpElm = (<div className={styles.ttp} style={{color: "#EFBA0C"}}>Trailing SL {trail_sell_price_percent}%</div>);
        }
    }else{
        if(props.slot.trail_activation_type === "PROFIT_PERCENT"){
            ttpElm = (<div className={styles.ttp}><span>TTP</span> &nbsp;{trail_activation_percent}% &nbsp;<span>SL</span> &nbsp;{trail_sell_price_percent}%</div>);
        }else{
            ttpElm = (<div className={styles.ttp}><span>TTP</span> &nbsp;{props.slot.trail_activation_price} &nbsp;<span>SL</span> &nbsp;{trail_sell_price_percent}%</div>);
        }
    }

    let ttpSellPrice = 0;
    if(ttpActivated){
        ttpSellPrice = ttpFinal
    }else{
        ttpSellPrice = ttpFinal - (trail_sell_price_percent / 100 * ttpFinal);
    }

    let ttpSellValue = totalAmount*ttpSellPrice;
    let ttpSellDiff = ttpSellValue - totalInvested;
    



    let interval = props.strategy;
    if(props.strategy === "lo"){
        interval = "1m";
    }
    if(props.strategy === "cc"){
        interval = "1h";
    }
    if(props.strategy === "1m2"){
        interval = "1m";
    }
    if(props.strategy === "1mi"){
        interval = "1m";
    }
    if(props.strategy === "4h2"){
        interval = "4h";
    }
    

    let href = "/chart?pair="+props.coin+"_"+props.coin2+"&interval="+interval+"&strategy="+props.strategy

    return (
        <a target="_blank" href={href} rel="noreferrer" className={styles.tradesItem}>
            <div className={styles.content}>
                
                <div className={styles.col1}>
                    <div className={styles.row1}>
                        <div className={styles.nr}>{props.nr_crt}</div>
                    </div>
                </div>

                <div className={styles.col2}>
                    <div className={styles.row1}>
                        <div className={styles.symbol}>{props.slot.coin} <span>/{props.slot.coin2}</span></div>
                    </div>
                    <div className={styles.row2}>
                        <div className={styles.nextBuy}>
                            <span style={{opacity: 0.5}}>NB*{props.slot.buy_count+1} &nbsp;{nextBuyPercent}%</span> &nbsp;{Util.formatNumber(props.slot.next_buy_price, 0, priceDecimals)}
                        </div>
                    </div>
                    <div className={styles.row3}>
                        {ttpElm}
                    </div>
                    <div className={styles.row4}>
                        <div className={styles.saleValue}><span>Sell</span> &nbsp;{Util.formatNumber(ttpSellValue, 0, 2)} $</div>
                    </div>
                </div>

                <div className={styles.col4}>
                    <div className={styles.profitOsc} style={{backgroundColor: bgProfitOsc}}>{profitPercent}%</div>
                    <div className={styles.ttpOsc}>{ttpOscPercent}%</div>
                </div>

                <div className={styles.col3}>
                    <div className={styles.row1}>
                        <div className={styles.invested}>i &nbsp;{Util.formatNumber(totalInvested, 0, 2)} $</div>
                    </div>

                    <div className={styles.row2}>
                        <div className={styles.profit}>p &nbsp;{Util.formatNumber(profitDiff, 0, 2)} $</div>
                    </div>

                    <div className={styles.row3}>
                        <div className={styles.ttpPrice}>{ttpActivated ? "S":"T"} &nbsp;{Util.formatNumber(ttpFinal, 0, priceDecimals)}</div>
                    </div>
                    <div className={styles.row4}>
                        <div className={styles.ttpProfit}>p &nbsp;{Util.formatNumber(ttpSellDiff, 0, 2)} $</div>
                    </div>
                </div>

            </div>
        </a>

    );
    
}


export default ItemTrail;