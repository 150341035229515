import React from 'react';

import Util from "../../utils/Util";

import IcoBlock from '../../icons/IcoBlock';
import IcoMenu from '../../icons/IcoMenu';

import styles from './ItemIndicatorWatch.module.css';

const TRADE_CONDITIONS = {
    "1m" :  {tp: 3, wt1: -50, wt2: -47, rsi14: 25},
    "3m" :  {tp: 3, wt1: -50, wt2: -47, rsi14: 25},
    "5m" :  {tp: 5, wt1: -50, wt2: -47, rsi14: 25},
    "15m" : {tp: 7, wt1: -50, wt2: -47, rsi14: 25},
    "30m" : {tp: 7, wt1: -50, wt2: -47, rsi14: 25},
    "1h" :  {tp: 10, wt1: -50, wt2: -45, rsi14: 25},
    "2h" :  {tp: 12, wt1: -50, wt2: -45, rsi14: 25},
    "4h" :  {tp: 12, wt1: -50, wt2: -45, rsi14: 25},
    "6h" :  {tp: 12, wt1: -50, wt2: -45, rsi14: 25},
    "8h" :  {tp: 20, wt1: -49, wt2: -47, rsi14: 25},
    "12h" : {tp: 20, wt1: -48, wt2: -45, rsi14: 25},
    "1d" :  {tp: 25, wt1: -50, wt2: -45, rsi14: 25},
    "3d" :  {tp: 45, wt1: -50, wt2: -50, rsi14: 25},
    "1w" :  {tp: 45, wt1: -50, wt2: -50, rsi14: 25},
};


function ItemIndicatorWatch(props) {

    let now = Date.now()/1000;
    let nr = props.nr;
    let item = props.item;
    let gainers = props.gainers;

    let priceDecimalCount = Util.getDecimalsCount(item.price);

    let gainValid = false;
    let volValid = false;

    let ma_1mValid = false;
    let ma_3mValid = false;
    let ma_5mValid = false;
    let ma_15mValid = false;
    let ma_30mValid = false;
    let ma_1hValid = false;
    let ma_2hValid = false;
    let ma_4hValid = false;
    let ma_6hValid = false;
    let ma_8hValid = false;
    let ma_12hValid = false;
    let ma_1dValid = false;
    let ma_3dValid = false;
    let ma_1wValid = false;

    let wt_1mValid = false;
    let wt_3mValid = false;
    let wt_5mValid = false;
    let wt_15mValid = false;
    let wt_30mValid = false;
    let wt_1hValid = false;
    let wt_2hValid = false;
    let wt_4hValid = false;
    let wt_6hValid = false;
    let wt_8hValid = false;
    let wt_12hValid = false;
    let wt_1dValid = false;
    let wt_3dValid = false;
    let wt_1wValid = false;


    // let styleMaxGP={};
    // if(parseFloat(item.gain_percent_max_24h) >= 40){
    //     styleMaxGP.color = "#23D785";
    //     gainValid = true;
    // }

    // let max_gain_peak_volq_24h = 0;
    // if(gainers[item.symbol+"BUSD"]){
    //     max_gain_peak_volq_24h = gainers[item.symbol+"BUSD"].max_gain_peak_volq_24h;
    // }



    // let styleVol={};
    // if(parseFloat(item.volq_24h) >= parseFloat(item.gainer_init_volq_24h)){
    //     styleVol.color = "#23D785";
    //     volValid = true;
    // }
    // if(parseFloat(item.volq_24h) >= parseFloat(max_gain_peak_volq_24h)){
    //     styleVol.color = "#23D785";
    //     volValid = true;
    // }




    let styleSma1m={};
    if(parseFloat(item.price) > parseFloat(item.sma200_1m)){
        styleSma1m.color = "#23D785";
        ma_1mValid = true;
    }

    let styleSma3m={};
    if(parseFloat(item.price) > parseFloat(item.sma200_3m)){
        styleSma3m.color = "#23D785";
        ma_3mValid = true;
    }

    let styleSma5m={};
    if(parseFloat(item.price) > parseFloat(item.sma200_5m)){
        styleSma5m.color = "#23D785";
        ma_5mValid = true;
    }

    let styleSma15m={};
    if(parseFloat(item.price) > parseFloat(item.sma200_15m)){
        styleSma15m.color = "#23D785";
        ma_15mValid = true;
    }

    let styleSma30m={};
    if(parseFloat(item.price) > parseFloat(item.sma200_30m)){
        styleSma30m.color = "#23D785";
        ma_30mValid = true;
    }

    let styleSma1h={};
    if(parseFloat(item.price) > parseFloat(item.sma200_1h)){
        styleSma1h.color = "#23D785";
        ma_1hValid = true;
    }

    let styleSma2h={};
    if(parseFloat(item.price) > parseFloat(item.sma200_2h)){
        styleSma2h.color = "#23D785";
        ma_2hValid = true;
    }

    let styleSma4h={};
    if(parseFloat(item.price) > parseFloat(item.sma200_4h)){
        styleSma4h.color = "#23D785";
        ma_4hValid = true;
    }

    let styleSma6h={};
    if(parseFloat(item.price) > parseFloat(item.sma200_6h)){
        styleSma6h.color = "#23D785";
        ma_6hValid = true;
    }

    let styleSma8h={};
    if(parseFloat(item.price) > parseFloat(item.sma200_8h)){
        styleSma8h.color = "#23D785";
        ma_8hValid = true;
    }

    let styleSma12h={};
    if(parseFloat(item.price) > parseFloat(item.sma200_12h)){
        styleSma12h.color = "#23D785";
        ma_12hValid = true;
    }

    let styleSma1d={};
    if(parseFloat(item.price) > parseFloat(item.sma200_1d)){
        styleSma1d.color = "#23D785";
        ma_1dValid = true;
    }

    let styleSma3d={};
    if(parseFloat(item.price) > parseFloat(item.sma200_3d)){
        styleSma3d.color = "#23D785";
        ma_3dValid = true;
    }

    let styleSma1w={};
    if(parseFloat(item.price) > parseFloat(item.sma200_1w)){
        styleSma1w.color = "#23D785";
        ma_1wValid = true;
    }




    let style1m={};
    if( (item.wt1_1m <= TRADE_CONDITIONS["1m"].wt1) && (item.wt2_1m <= TRADE_CONDITIONS["1m"].wt2) ){
        style1m.color = "#23D785";
        wt_1mValid = true;
    }

    let style3m={};
    if( (item.wt1_3m <= TRADE_CONDITIONS["3m"].wt1) && (item.wt2_3m <= TRADE_CONDITIONS["3m"].wt2) ){
        style3m.color = "#23D785";
        wt_3mValid = true;
    }

    let style5m={};
    if( (item.wt1_5m <= TRADE_CONDITIONS["5m"].wt1) && (item.wt2_5m <= TRADE_CONDITIONS["5m"].wt2) ){
        style5m.color = "#23D785";
        wt_5mValid = true;
    }

    let style15m={};
    if( (item.wt1_15m <= TRADE_CONDITIONS["15m"].wt1) && (item.wt2_15m <= TRADE_CONDITIONS["15m"].wt2) ){
        style15m.color = "#23D785";
        wt_15mValid = true;
    }

    let style30m={};
    if( (item.wt1_30m <= TRADE_CONDITIONS["30m"].wt1) && (item.wt2_30m <= TRADE_CONDITIONS["30m"].wt2) ){
        style30m.color = "#23D785";
        wt_30mValid = true;
    }

    let style1h={};
    if( (item.wt1_1h <= TRADE_CONDITIONS["1h"].wt1) && (item.wt2_1h <= TRADE_CONDITIONS["1h"].wt2) ){
        style1h.color = "#23D785";
        wt_1hValid = true;
    }

    let style2h={};
    if( (item.wt1_2h <= TRADE_CONDITIONS["2h"].wt1) && (item.wt2_2h <= TRADE_CONDITIONS["2h"].wt2) ){
        style2h.color = "#23D785";
        wt_2hValid = true;
    }

    let style4h={};
    if( (item.wt1_4h <= TRADE_CONDITIONS["4h"].wt1) && (item.wt2_4h <= TRADE_CONDITIONS["4h"].wt2) ){
        style4h.color = "#23D785";
        wt_4hValid = true;
    }

    let style6h={};
    if( (item.wt1_6h <= TRADE_CONDITIONS["6h"].wt1) && (item.wt2_6h <= TRADE_CONDITIONS["6h"].wt2) ){
        style6h.color = "#23D785";
        wt_6hValid = true;
    }

    let style8h={};
    if( (item.wt1_8h <= TRADE_CONDITIONS["8h"].wt1) && (item.wt2_8h <= TRADE_CONDITIONS["8h"].wt2) ){
        style8h.color = "#23D785";
        wt_8hValid = true;
    }

    let style12h={};
    if( (item.wt1_12h <= TRADE_CONDITIONS["12h"].wt1) && (item.wt2_12h <= TRADE_CONDITIONS["12h"].wt2) ){
        style12h.color = "#23D785";
        wt_12hValid = true;
    }

    let style1d={};
    if( (item.wt1_1d <= TRADE_CONDITIONS["1d"].wt1) && (item.wt2_1d <= TRADE_CONDITIONS["1d"].wt2) ){
        style1d.color = "#23D785";
        wt_1dValid = true;
    }

    let style3d={};
    if( (item.wt1_3d <= TRADE_CONDITIONS["3d"].wt1) && (item.wt2_3d <= TRADE_CONDITIONS["3d"].wt2) ){
        style3d.color = "#23D785";
        wt_3dValid = true;
    }

    let style1w={};
    if( (item.wt1_1w <= TRADE_CONDITIONS["1w"].wt1) && (item.wt2_1w <= TRADE_CONDITIONS["1w"].wt2) ){
        style1w.color = "#23D785";
        wt_1wValid = true;
    }




    let styleRsi1m={};
    if(item.rsi14 <= TRADE_CONDITIONS["1m"].rsi14){
        styleRsi1m.color = "yellow";
    }

    let styleRsi3m={};
    if(item.rsi14 <= TRADE_CONDITIONS["3m"].rsi14){
        styleRsi3m.color = "yellow";
    }

    let styleRsi5m={};
    if(item.rsi14 <= TRADE_CONDITIONS["5m"].rsi14){
        styleRsi5m.color = "yellow";
    }

    let styleRsi15m={};
    if(item.rsi14 <= TRADE_CONDITIONS["15m"].rsi14){
        styleRsi15m.color = "yellow";
    }

    let styleRsi30m={};
    if(item.rsi14 <= TRADE_CONDITIONS["30m"].rsi14){
        styleRsi30m.color = "yellow";
    }

    let styleRsi1h={};
    if(item.rsi14 <= TRADE_CONDITIONS["1h"].rsi14){
        styleRsi1h.color = "yellow";
    }

    let styleRsi2h={};
    if(item.rsi14 <= TRADE_CONDITIONS["2h"].rsi14){
        styleRsi2h.color = "yellow";
    }

    let styleRsi4h={};
    if(item.rsi14 <= TRADE_CONDITIONS["4h"].rsi14){
        styleRsi4h.color = "yellow";
    }

    let styleRsi6h={};
    if(item.rsi14 <= TRADE_CONDITIONS["6h"].rsi14){
        styleRsi6h.color = "yellow";
    }

    let styleRsi8h={};
    if(item.rsi14 <= TRADE_CONDITIONS["8h"].rsi14){
        styleRsi8h.color = "yellow";
    }

    let styleRsi12h={};
    if(item.rsi14 <= TRADE_CONDITIONS["12h"].rsi14){
        styleRsi12h.color = "yellow";
    }

    let styleRsi1d={};
    if(item.rsi14 <= TRADE_CONDITIONS["1d"].rsi14){
        styleRsi1d.color = "yellow";
    }

    let styleRsi3d={};
    if(item.rsi14 <= TRADE_CONDITIONS["3d"].rsi14){
        styleRsi3d.color = "yellow";
    }

    let styleRsi1w={};
    if(item.rsi14 <= TRADE_CONDITIONS["1w"].rsi14){
        styleRsi1w.color = "yellow";
    }




    let buySignal = false;

    if(gainValid && ma_1mValid && wt_1mValid){
        buySignal = true;
    }

    if(gainValid && ma_3mValid && wt_3mValid){
        buySignal = true;
    }

    if(gainValid && ma_5mValid && wt_5mValid){
        buySignal = true;
    }

    if(gainValid && ma_15mValid && wt_15mValid){
        buySignal = true;
    }

    if(gainValid && ma_30mValid && wt_30mValid){
        buySignal = true;
    }

    if(gainValid && ma_1hValid && wt_1hValid){
        buySignal = true;
    }

    if(gainValid && ma_2hValid && wt_2hValid){
        buySignal = true;
    }

    if(gainValid && ma_4hValid && wt_4hValid){
        buySignal = true;
    }

    if(gainValid && ma_6hValid && wt_6hValid){
        buySignal = true;
    }

    if(gainValid && ma_8hValid && wt_8hValid){
        buySignal = true;
    }

    if(gainValid && ma_12hValid && wt_12hValid){
        buySignal = true;
    }

    if(gainValid && ma_1dValid && wt_1dValid){
        buySignal = true;
    }

    if(gainValid && ma_3dValid && wt_3dValid){
        buySignal = true;
    }

    if(gainValid && ma_1wValid && wt_1wValid){
        buySignal = true;
    }




    let styleCoin={};
    if(buySignal){
        styleCoin.color = "#23D785";
    }

    let favIcon = "";
    if(parseInt(item.gainer_init_ts) > (now - 60*60*24)){
        favIcon = "★";
    }
    

    let pricePercentFromGainMax = 0;
    let fib = 0;

    if(item.ex_h > 0){
        pricePercentFromGainMax = (parseFloat(item.price)*100/parseFloat(item.ex_h))-100;
        pricePercentFromGainMax = pricePercentFromGainMax.toFixed(2);
        if(pricePercentFromGainMax > 0){
            pricePercentFromGainMax = "+"+pricePercentFromGainMax;
        }
    }

    if(item.ex_h > 0 && item.ex_l > 0){
        fib = Util.getFibPercent(parseFloat(item.price), item.ex_h, item.ex_l)/100;
        fib = fib.toFixed(3);
    }
    


    let styleExLo={};
    if( (parseFloat(item.ex_l) == 0) ){
        styleExLo.color = "#F5465D";
    } 

    let styleExHi={};
    if( (parseFloat(item.ex_h) == 0) ){
        styleExHi.color = "#F5465D";
    } 

    let styleFib={};
    if( (fib > 1) ){
        styleFib.color = "#F5465D";
    } 

    let customStyle = {};
    if(item.blacklist == 1){
        customStyle["backgroundColor"] = "#F5465D";

        styleCoin.color = "#fff";
        styleExLo.color = "#fff";
        styleExHi.color = "#fff";
        styleFib.color = "#fff";

        style1m.color = "#fff";
        style3m.color = "#fff";
        style5m.color = "#fff";
        style15m.color = "#fff";
        style30m.color = "#fff";
        style1h.color = "#fff";
        style2h.color = "#fff";
        style4h.color = "#fff";
        style6h.color = "#fff";
        style8h.color = "#fff";
        style12h.color = "#fff";
        style1d.color = "#fff";
        style3d.color = "#fff";
        style1w.color = "#fff";

        styleSma1m.color = "#fff";
        styleSma3m.color = "#fff";
        styleSma5m.color = "#fff";
        styleSma15m.color = "#fff";
        styleSma30m.color = "#fff";
        styleSma1h.color = "#fff";
        styleSma2h.color = "#fff";
        styleSma4h.color = "#fff";
        styleSma6h.color = "#fff";
        styleSma8h.color = "#fff";
        styleSma12h.color = "#fff";
        styleSma1d.color = "#fff";
        styleSma3d.color = "#fff";
        styleSma1w.color = "#fff";

        styleRsi1m.color = "#fff";
        styleRsi3m.color = "#fff";
        styleRsi5m.color = "#fff";
        styleRsi15m.color = "#fff";
        styleRsi30m.color = "#fff";
        styleRsi1h.color = "#fff";
        styleRsi2h.color = "#fff";
        styleRsi4h.color = "#fff";
        styleRsi6h.color = "#fff";
        styleRsi8h.color = "#fff";
        styleRsi12h.color = "#fff";
        styleRsi1d.color = "#fff";
        styleRsi3d.color = "#fff";
        styleRsi1w.color = "#fff";
    }

    let watchDate = null;
    let watchDateAgo = null;
    let volq24hRef = null;

    if(item.monitor){
        watchDate = item.monitor.date_update;
        watchDate = watchDate.substring(0,watchDate.length-3);

        let seconds = (Date.now()/1000) - item.monitor.date_update_ts;
        watchDateAgo = parseInt(seconds / (60*60)) + 'h';

        let color = {};
        if(parseFloat(item.volq_24h) > parseFloat(item.monitor.gain_volq_24h)){
            color = {color: "#23D785"}
        }
        volq24hRef = <div style={color}>Ref {(parseFloat(item.monitor.gain_volq_24h)/1000000).toFixed(2)} m</div>
    }



    let categories = "";
    let categoriesArr = item.categories.split(",");
    for(let i = 0; i < categoriesArr.length; i++){
        let tag = categoriesArr[i];
        if(props.categories[tag]){
            if(i == categoriesArr.length-1){
                categories += props.categories[tag];
            }else{
                categories += props.categories[tag] + ", ";
            }
        }
    }


    let gainFromLowestPrice = (parseFloat(item.price)*100/parseFloat(item.price_l_24h))-100;
    


    return (
        <tr style={customStyle}>
            <td className={styles.col1}>{nr}</td>
            <td className={styles.col2} style={styleCoin}>{item.coin1} {favIcon}</td>

            <td className={styles.colInterval}>
                $ {parseFloat(item.price)}
            </td>

            {item.monitor ? 
                <td className={styles.colInterval} style={{textAlign: 'left', paddingLeft: 6}}>
                    <div>{watchDate}</div>
                    <div>{watchDateAgo}</div>
                </td>
            : null}

            <td className={styles.colInterval} style={{textAlign: 'left', paddingLeft: 6}}>
                <div style={styleExHi}>Hi: {parseFloat(item.ex_h)}</div>
                <div style={styleExLo}>Lo: {parseFloat(item.ex_l)}</div>
            </td>

            <td className={styles.colInterval} style={{textAlign: 'left', paddingLeft: 6}}>
                <div style={styleExHi}>Hi: {parseFloat(item.ex_h_15m)}</div>
                <div style={styleExLo}>Lo: {parseFloat(item.ex_l_15m)}</div>
            </td>

            <td className={styles.colInterval} style={{textAlign: 'left', paddingLeft: 6}}>
                <div style={styleExHi}>Hi: {parseFloat(item.ex_h_2h)}</div>
                <div style={styleExLo}>Lo: {parseFloat(item.ex_l_2h)}</div>
            </td>

            <td className={styles.colInterval} style={{textAlign: 'left', paddingLeft: 6}}>
                <div style={styleFib}>Fib: {fib}</div>
                <div style={null}>Hi: {pricePercentFromGainMax} %</div>
            </td>

            <td className={styles.colInterval} style={{textAlign: 'left', paddingLeft: 6}}>
                <div style={null}>Max 10h: {parseFloat(item.gain_percent_max_24h).toFixed(2)} %</div>
                <div style={null}>Now: {parseFloat(item.gain_percent).toFixed(2)} %</div>
                <div style={null}>Lo Gain: {gainFromLowestPrice.toFixed(2)} %</div>
            </td>

            {/* <td className={styles.colInterval}>
                <div style={null}>H {parseFloat(item.price_ath_last_year)}</div>
                <div style={null}>L {parseFloat(item.price_atl_last_year)}</div>
            </td>

            <td className={styles.colInterval}>
                <div style={null}>H {parseFloat(item.price_ath_last_month)}</div>
                <div style={null}>L {parseFloat(item.price_atl_last_month)}</div>
            </td> */}

            <td className={styles.colInterval}>
                $ {parseFloat(item.price_ath)}
            </td>

            <td className={styles.colInterval}>
                <div style={null}>{(parseFloat(item.volq_24h)/1000000).toFixed(2)} m</div>
                {volq24hRef}
            </td>

            <td className={styles.colInterval}>
                <div>MA 200</div>
                <div>WT 1/2</div>
                <div>RSI 14</div>
            </td>

            <td className={styles.colInterval}>
                <div style={styleSma1m}>{Util.toFixed(item.sma200_1m, priceDecimalCount)}</div>
                <div style={style1m}>{parseInt(item.wt1_1m)+" / "+parseInt(item.wt2_1m)}</div>
                <div style={styleRsi1m}>{parseInt(item.rsi14_1m)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma3m}>{Util.toFixed(item.sma200_3m, priceDecimalCount)}</div>
                <div style={style3m}>{parseInt(item.wt1_3m)+" / "+parseInt(item.wt2_3m)}</div>
                <div style={styleRsi3m}>{parseInt(item.rsi14_3m)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma5m}>{Util.toFixed(item.sma200_5m, priceDecimalCount)}</div>
                <div style={style5m}>{parseInt(item.wt1_5m)+" / "+parseInt(item.wt2_5m)}</div>
                <div style={styleRsi5m}>{parseInt(item.rsi14_5m)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma15m}>{Util.toFixed(item.sma200_15m, priceDecimalCount)}</div>
                <div style={style15m}>{parseInt(item.wt1_15m)+" / "+parseInt(item.wt2_15m)}</div>
                <div style={styleRsi15m}>{parseInt(item.rsi14_15m)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma30m}>{Util.toFixed(item.sma200_30m, priceDecimalCount)}</div>
                <div style={style30m}>{parseInt(item.wt1_30m)+" / "+parseInt(item.wt2_30m)}</div>
                <div style={styleRsi30m}>{parseInt(item.rsi14_30m)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma1h}>{Util.toFixed(item.sma200_1h, priceDecimalCount)}</div>
                <div style={style1h}>{parseInt(item.wt1_1h)+" / "+parseInt(item.wt2_1h)}</div>
                <div style={styleRsi1h}>{parseInt(item.rsi14_1h)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma2h}>{Util.toFixed(item.sma200_2h, priceDecimalCount)}</div>
                <div style={style2h}>{parseInt(item.wt1_2h)+" / "+parseInt(item.wt2_2h)}</div>
                <div style={styleRsi2h}>{parseInt(item.rsi14_2h)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma4h}>{Util.toFixed(item.sma200_4h, priceDecimalCount)}</div>
                <div style={style4h}>{parseInt(item.wt1_4h)+" / "+parseInt(item.wt2_4h)}</div>
                <div style={styleRsi4h}>{parseInt(item.rsi14_4h)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma6h}>{Util.toFixed(item.sma200_6h, priceDecimalCount)}</div>
                <div style={style6h}>{parseInt(item.wt1_6h)+" / "+parseInt(item.wt2_6h)}</div>
                <div style={styleRsi6h}>{parseInt(item.rsi14_6h)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma8h}>{Util.toFixed(item.sma200_8h, priceDecimalCount)}</div>
                <div style={style8h}>{parseInt(item.wt1_8h)+" / "+parseInt(item.wt2_8h)}</div>
                <div style={styleRsi8h}>{parseInt(item.rsi14_8h)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma12h}>{Util.toFixed(item.sma200_12h, priceDecimalCount)}</div>
                <div style={style12h}>{parseInt(item.wt1_12h)+" / "+parseInt(item.wt2_12h)}</div>
                <div style={styleRsi12h}>{parseInt(item.rsi14_12h)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma1d}>{Util.toFixed(item.sma200_1d, priceDecimalCount)}</div>
                <div style={style1d}>{parseInt(item.wt1_1d)+" / "+parseInt(item.wt2_1d)}</div>
                <div style={styleRsi1d}>{parseInt(item.rsi14_1d)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma3d}>{Util.toFixed(item.sma200_3d, priceDecimalCount)}</div>
                <div style={style3d}>{parseInt(item.wt1_3d)+" / "+parseInt(item.wt2_3d)}</div>
                <div style={styleRsi3d}>{parseInt(item.rsi14_3d)}</div>
            </td>
            <td className={styles.colInterval}>
                <div style={styleSma1w}>{Util.toFixed(item.sma200_1w, priceDecimalCount)}</div>
                <div style={style1w}>{parseInt(item.wt1_1w)+" / "+parseInt(item.wt2_1w)}</div>
                <div style={styleRsi1w}>{parseInt(item.rsi14_1w)}</div>
            </td>


            {/* <td className={styles.col11}>{Util.timeAgo(props.last_update)} ago</td> */}

            
            {!item.monitor ? <td className={styles.colInterval} style={{position:"relative"}}>
                <div className={styles.menu}><IcoMenu/></div>
                {props.children}
            </td> : null}

            <td className={styles.colInterval} style={{textAlign:"left", paddingLeft: 8}}>
                {categories}
            </td>

        </tr>
    );
    
}


export default ItemIndicatorWatch;