import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Constants from "../../Constants";
import Fetch from "../../utils/Fetch";

import IcoArrDown from "../../icons/IcoArrDown"

import ToggleInput from "../ToggleInput"
import ConfirmDialog from "../ConfirmDialog";

import styles from './SpecsInput.module.css';

SpecsInput.defaultProps = {
    strategy: "",
    specs: {},
    readOnly: false,
    tickers: {},
};

SpecsInput.propTypes = {
    strategy: PropTypes.string,
    specs: PropTypes.object,
    readOnly: PropTypes.bool,
    tickers: PropTypes.object,
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SpecsInput(props) {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState("");
    const [confirmSave, setConfirmSave] = useState(false);

    const [specs, setSpecs] = useState({
        initial_balance: 0,
        initial_invest: 0,
        symbol: "",
        wt1: 0,
        wt2: 0,
        ttp: 0,
        sl: 0,
        vol_usdt: 0,
        vol_usdt_min_percent: 0,
        buy_price1: 0,
        buy_price2: 0,
        buy_price3: 0,
        buy_price4: 0,
        buy_price5: 0,
        lp: false,
        lp_candles: "0",
        sma99: false,
    });

    useEffect(()=>{
        setSpecs(props.specs);
    }, [props.specs]);

    function onChangeSpec(spec, value){
        let newSpecs = {...specs};
        newSpecs[spec] = value;
        setSpecs(newSpecs);
    }

    function update(){
        let newSpecs = JSON.stringify(specs);

        let url = Constants.API_URL+"/api/specsUpdate.php?strategy="+props.strategy+"&specs="+newSpecs;
  
        Fetch.request(url)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setStatusUpdate("success");
                    setSnackbarOpen(true);
                    return;
                } 

                setStatusUpdate("error");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                setStatusUpdate("error");
                setSnackbarOpen(true);
            });
    }

    function handleSnackbarClose(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }

    function onConfirmSaveProcess(){
        let buy_price = parseFloat(specs.buy_price1);
        let pair = (specs.symbol+"USDT").toUpperCase();
        let pairPrice = 0;

        if(props.tickers[pair]){
            pairPrice = props.tickers[pair];
        }


        let deviationPercent = 100;
        if(pairPrice !== 0){
            deviationPercent = (buy_price * 100 / pairPrice)-100;
            deviationPercent = Math.abs(deviationPercent);
        }

        // console.log(deviationPercent);

        if(deviationPercent >= 25){
            setConfirmSave(true)
        }else{
            update();
        }

    }

    function onConfirmSave(){
        setConfirmSave(false)
        update();
    }

    let btnOkElm = null;
    if(props.readOnly){
        btnOkElm = (<div className={styles.btnOk}>Update specs</div>);
    }else{
        btnOkElm = (<div className={styles.btnOk+" "+styles.active} onClick={onConfirmSaveProcess}>Update specs</div>);
    }

    // console.log(props.tickers);

    return (
        <div className={styles.specs}>

            <div className={styles.textInput} style={{marginTop: 0}}>
                <div className={styles.leftLabel}></div>
                <div className={styles.rightLabel}>$ to Invest</div>
                <input type="number" placeholder="0" value={specs.initial_invest} onChange={(e)=>{onChangeSpec("initial_invest", e.target.value)}} />
            </div>

            <div className={styles.textInput} style={{marginTop: 20}}>
                <div className={styles.leftLabel}></div>
                <div className={styles.rightLabel}>Symbol /USDT</div>
                <input type="text" placeholder="Symbol" value={specs.symbol} onChange={(e)=>{onChangeSpec("symbol", e.target.value)}} />
            </div>



            <div className={styles.textInput} style={{marginTop: 20, float: "left", width: "49%"}}>
                <div className={styles.leftLabel}>-</div>
                <div className={styles.rightLabel}>WT1/15m</div>
                <input type="number" placeholder="0" value={specs.wt1} onChange={(e)=>{onChangeSpec("wt1", e.target.value)}} />
            </div>
            <div className={styles.textInput} style={{marginTop: 20, float: "right", width: "49%"}}>
                <div className={styles.leftLabel}>-</div>
                <div className={styles.rightLabel}>WT2/15m</div>
                <input type="number" placeholder="0" value={specs.wt2} onChange={(e)=>{onChangeSpec("wt2", e.target.value)}} />
            </div>



            <div className={styles.textInput} style={{marginTop: 10, float: "left", width: "49%"}}>
                <div className={styles.leftLabel}>%</div>
                <div className={styles.rightLabel}>TTP</div>
                <input type="number" placeholder="0.0" value={specs.ttp} onChange={(e)=>{onChangeSpec("ttp", e.target.value)}} />
            </div>
            <div className={styles.textInput} style={{marginTop: 10, float: "right", width: "49%"}}>
                <div className={styles.leftLabel}>%</div>
                <div className={styles.rightLabel}>SL</div>
                <input type="number" placeholder="0.0" value={specs.sl} onChange={(e)=>{onChangeSpec("sl", e.target.value)}} />
            </div>
            <div className={styles.textInput} style={{marginTop: 10}}>
                <div className={styles.leftLabel}>&gt;</div>
                <div className={styles.rightLabel}>Volume 24h $ (mil)</div>
                <input type="number" placeholder="0.00" value={specs.vol_usdt} onChange={(e)=>{onChangeSpec("vol_usdt", e.target.value)}} />
            </div>      
            <div className={styles.textInput} style={{marginTop: 10}}>
                <div className={styles.leftLabel}>-</div>
                <div className={styles.rightLabel}>Volume 24h % (min)</div>
                <input type="number" placeholder="0.00" value={specs.vol_usdt_min_percent} onChange={(e)=>{onChangeSpec("vol_usdt_min_percent", e.target.value)}} />
            </div>      



            <div className={styles.textInput} style={{marginTop: 20}}>
                <div className={styles.leftLabel}>$</div>
                <div className={styles.rightLabel}>1st Buy max price</div>
                <input type="number" placeholder="0.00" value={specs.buy_price1} onChange={(e)=>{onChangeSpec("buy_price1", e.target.value)}} />
            </div>  
            <div className={styles.textInput} style={{marginTop: 10, float: "left", width: "49%"}}>
                <div className={styles.leftLabel}>-</div>
                <div className={styles.rightLabel}>2nd Buy %</div>
                <input type="number" placeholder="0.0" value={specs.buy_price2} onChange={(e)=>{onChangeSpec("buy_price2", e.target.value)}} />
            </div>
            <div className={styles.textInput} style={{marginTop: 10, float: "right", width: "49%"}}>
                <div className={styles.leftLabel}>-</div>
                <div className={styles.rightLabel}>3rd Buy %</div>
                <input type="number" placeholder="0.0" value={specs.buy_price3} onChange={(e)=>{onChangeSpec("buy_price3", e.target.value)}} />
            </div>

            <div className={styles.textInput} style={{marginTop: 10, float: "left", width: "49%"}}>
                <div className={styles.leftLabel}>-</div>
                <div className={styles.rightLabel}>4th Buy %</div>
                <input type="number" placeholder="0.0" value={specs.buy_price4} onChange={(e)=>{onChangeSpec("buy_price4", e.target.value)}} />
            </div>
            <div className={styles.textInput} style={{marginTop: 10, float: "right", width: "49%"}}>
                <div className={styles.leftLabel}>-</div>
                <div className={styles.rightLabel}>5th Buy %</div>
                <input type="number" placeholder="0.0" value={specs.buy_price5} onChange={(e)=>{onChangeSpec("buy_price5", e.target.value)}} />
            </div>

            <div style={{display: "inline-block", width:"100%", marginTop: "20px"}}>
                <div style={{float: "left", marginTop: "7px"}}>Price above MA 99</div>
                <div style={{float: "right"}}>
                    <ToggleInput bgOff="#434C5A" bgOn="#2EBC85" onChange={(value)=>{onChangeSpec("sma99", value)}} checked={specs.sma99} />
                </div>
            </div>

            <div style={{display: "inline-block", width:"100%", marginTop: "20px"}}>
                <div style={{float: "left", marginTop: "18px"}}>Lowest price in the last</div>
                <div className={styles.selectInput} style={{width:"49%", float:"right"}}>
                    <select 
                        onChange={(e)=>{onChangeSpec("lp_candles", e.target.value)}}
                        value={specs.lp_candles}>
                        
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>

                    </select>

                    <div className={styles.displayValue}>
                        {specs.lp_candles} candles
                        <IcoArrDown className={styles.displayValueArrow} fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
            </div>


            <div style={{textAlign: "center"}}>
                {btnOkElm}
            </div>


            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={statusUpdate} sx={{ width: '100%' }}>
                    {statusUpdate === "success" ? "Specifications updated !" : "Specifications update failed !"}
                </Alert>
            </Snackbar>

            <ConfirmDialog 
                open={confirmSave} 
                title="Update specs" 
                text={"1st buy max price has more than 25% deviation from current price ! Are you sure ?"} 
                okLabel="Yes" 
                cancelLabel="No" 
                onOk={onConfirmSave} 
                onCancel={()=>{setConfirmSave(false)}} /> 
            

        </div>
    );
    
}


export default SpecsInput;