import PropTypes from 'prop-types';

IcoArrow.defaultProps = {
    fill: "#FFFFFF",
    direction: "up",
};

IcoArrow.propTypes = {
    fill: PropTypes.string,
    direction: PropTypes.string,
}

function IcoArrow(props){
    if(props.direction === "up"){
        return(
            <svg {...props} width="10px" height="5px" viewBox="0 0 10 5">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Artboard" transform="translate(-104.000000, -89.000000)" fill={props.fill}>
                        <polygon id="Shape-Copy" transform="translate(109.000000, 91.500000) scale(1, -1) translate(-109.000000, -91.500000) " points="104 89 109 94 114 89"></polygon>
                    </g>
                </g>
            </svg>
        );
    }

    if(props.direction === "down"){
        return(
            <svg {...props} width="10px" height="5px" viewBox="0 0 10 5">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Artboard" transform="translate(-119.000000, -89.000000)" fill={props.fill}>
                        <polygon id="Shape" points="119 89 124 94 129 89"></polygon>
                    </g>
                </g>
            </svg>
        );
    }

    return null;
}

export default IcoArrow;