import PropTypes from 'prop-types';

IcoTTP.defaultProps = {
    fill: "#FFFFFF",
};

IcoTTP.propTypes = {
    fill: PropTypes.string,
}

function IcoTTP(props){
    return(
        <svg {...props} width="26px" height="17px" viewBox="0 0 26 17">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-198.000000, -177.000000)" fill={props.fill} fillRule="nonzero">
                    <path d="M224,193 L224,194 L222.5,194 L222.5,193 L224,193 Z M200,193 L200,194 L198,194 L198,193 L200,193 Z M207,193 L207,194 L205,194 L205,193 L207,193 Z M203.5,193 L203.5,194 L201.5,194 L201.5,193 L203.5,193 Z M214,193 L214,194 L212,194 L212,193 L214,193 Z M210.5,193 L210.5,194 L208.5,194 L208.5,193 L210.5,193 Z M221,193 L221,194 L219,194 L219,193 L221,193 Z M217.5,193 L217.5,194 L215.5,194 L215.5,193 L217.5,193 Z M224,187 L224,188 L198,188 L198,187 L224,187 Z M214,177 L211,186 L208,177 L214,177 Z" id="ico-TTP"></path>
                </g>
            </g>
        </svg>
    );
}

export default IcoTTP;