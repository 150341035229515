import React, {useEffect, useState, useRef} from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Loader from "../../components/Loader";
import ItemIndicator from "../../components/ItemIndicator";
import ItemIndicatorG from "../../components/ItemIndicatorG";
import ConfirmDialog from '../../components/ConfirmDialog';
import SymbolAddDialog from '../../components/SymbolAddDialog';

// import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Wt.module.css';
import Util from '../../utils/Util';


//new
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import IcoBlock from '../../icons/IcoBlock';
import IcoRemove from '../../icons/IcoRemove';


const WebSocketClient = require('websocket').w3cwebsocket;
const WS_CONN_RESTART_DELAY = 1000;

// const puDayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
}));

function Wt() {

    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const openMenu = Boolean(anchorElMenu);

    const [loading, setLoading] = useState(true);
    const [symbols, setSymbols] = useState([]);
    const [symbols_g, setSymbolsG] = useState([]);
    // const [tickers, setTickers] = useState([]);
    const tickers = useRef({});

    const allSymbols = useRef({});

    const [specs, setSpecs] = useState({});

    const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
    const [confirmBlacklistDialog, setConfirmBlacklistDialog] = useState(false);
    const [selectedSymbol, setSelectedSymbol] = useState("");
    const [selectedSymbolType, setSelectedSymbolType] = useState("");

    const [symbolAddDialog, setSymbolAddDialog] = useState(false);
    const [snackbarAddSymbol, setSnackbarAddSymbol] = useState(false);
    const [snackbarRemoveSymbol, setSnackbarRemoveSymbol] = useState(false);
    const [snackbarBlacklistSymbol, setSnackbarBlacklistSymbol] = useState(false);
    const [snackbarStatus, setSnackbarStatus] = useState({
        status: "",
        symbol: ""
    });


    const [filters, setFilters] = useState({
        "symbol": "",
        "15m_min": "",
        "15m_max": "",
        "1h_min": "",
        "1h_max": "",
        "4h_min": "",
        "4h_max": "",
        "12h_min": "",
        "12h_max": "",
        "vol_$": "",
        "vol_%": "",
    });

    useEffect(()=>{

        document.title = "WT Table";

        setLoading(true); 
        
        getData(()=>{
            // getTickers();
            startSymbolBookTicker();
            startSymbolStats24h();
        });
        
        let timer = setInterval(()=>{
            getData();
        }, 5000)
        

        return ()=>{
            clearInterval(timer);
            //console.log("unmount");
        }
    }, []);


    function getData(cb){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/wt.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    
            
                if(json.status === "ok"){
                    setSymbols(json.symbols);
                    setSymbolsG(json.symbols_g);

                    let specs = {};
                    for(let i = 0;  i < json.specs.length; i++){
                        let item = json.specs[i];
                        specs[item.name] = JSON.parse(item.value);
                    }

                    setSpecs(specs);

                    if(cb){
                        cb();
                    }
                }

            })
            .catch((error) => {
                // console.log(error);
            });
    }

    // function getTickers(){

    //     let options = {
    //         method: 'GET',
    //     }

    //     let url = Constants.API_URL+"/api/getTickers.php";
  
    //     Fetch.request(url, options)
    //         .then((response) => response.json())
    //         .then((json) => {    
            
    //             if(json.tickers !== null){
    //                 let tickers = {};
    //                 for(let i = 0; i < json.tickers.length; i++){
    //                     tickers[json.tickers[i].symbol.toLowerCase()] = parseFloat(json.tickers[i].price);
    //                 }

    //                 setTickers(tickers);
    //                 setLoading(false); 
    //             }
    //         })
    //         .catch((error) => {
    //             // console.log(error);
    //         });
    // }

    function startSymbolStats24h(){
    
        var webSocketClient = new WebSocketClient("wss://stream.binance.com:9443/ws/!ticker@arr");

        webSocketClient.onerror = function() {
            console.log('SymbolStats24h WebSocketClient Connection Error');
            try {
                webSocketClient.close();
            } catch(e) {
                console.log(e);
            }
        };

        webSocketClient.onopen = function() {
            console.log('SymbolStats24h WebSocketClient Connected');
        };

        webSocketClient.onclose = function() {
            console.log('SymbolStats24h WebSocketClient Closed');
            setTimeout(startSymbolStats24h, WS_CONN_RESTART_DELAY);
        };

        webSocketClient.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);

                    if(!Array.isArray(data)){
                        return;
                    }

                    for(let i = 0; i < data.length; i++){
                        if(data[i].s){
                            if(!allSymbols.current[data[i].s]){
                                allSymbols.current[data[i].s] = {};
                            }
                            allSymbols.current[data[i].s]["vol_24h"] = parseFloat(data[i].v);
                            allSymbols.current[data[i].s]["volq_24h"] = parseFloat(data[i].q);

                            // if(data[i].s === "MASKUSDT"){
                            //     console.log("volume current 24h: "+parseFloat(data[i].q)+" ots: "+data[i].O+" cts: "+data[i].C);
                            // }
                        }
                    }
                }
            } catch(e) {
                console.log("SymbolStats24h WebSocketClient Message Error");
            }
        };
    }

    function startSymbolBookTicker(){
    
        var webSocketClient = new WebSocketClient("wss://stream.binance.com:9443/ws/!bookTicker");

        webSocketClient.onerror = function() {
            console.log('Ticker WebSocketClient Connection Error');
            try {
                webSocketClient.close();
            } catch(e) {
                console.log(e);
            }
        };

        webSocketClient.onopen = function() {
            console.log('Ticker WebSocketClient Connected');
            setLoading(false);
        };

        webSocketClient.onclose = function() {
            console.log('Ticker WebSocketClient Closed');
            setTimeout(startSymbolBookTicker, WS_CONN_RESTART_DELAY);
        };

        webSocketClient.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);

                    if(data.s){
                        tickers.current[data.s] = parseFloat(data.a);                                 
                    }
                }
            } catch(e) {
                console.log("Ticker WebSocketClient Message Error");
            }
        };
    }

    function handleFiltersChange(e, filter){
        let newFilters = {...filters};
        newFilters[filter] = e.target.value.trim();
        setFilters(newFilters);
    }

    function clickOnSpec(strategy){
        if(specs["specs_"+strategy]){
            let wt1 = specs["specs_"+strategy].wt1;
            let wt2 = specs["specs_"+strategy].wt2;
            let vol_usdt = specs["specs_"+strategy].vol_usdt;

            let newFilters = {...filters};

            let interval = strategy;
            if(strategy === "4h2"){
                interval = "4h";
            }

            if(strategy === "15m"){
                interval = "1h";
                newFilters["symbol"] = "*";
            }

            
            newFilters[interval+"_min"] = wt1;
            newFilters[interval+"_max"] = wt2;
            newFilters["vol_$"] = vol_usdt;
            setFilters(newFilters);
        }
    }

    function clearFilters(){
        let newFilters = {...filters};

        newFilters["symbol"] = "";
        newFilters["15m_min"] = "";
        newFilters["15m_max"] = "";
        newFilters["1h_min"] = "";
        newFilters["1h_max"] = "";
        newFilters["4h_min"] = "";
        newFilters["4h_max"] = "";
        newFilters["12h_min"] = "";
        newFilters["12h_max"] = "";
        newFilters["vol_$"] = "";
        newFilters["vol_%"] = "";
        
        setFilters(newFilters);
    }

    // function filterSymbols(){
    //     let filteredSymbols = [];

    //     for(let i = 0; i < symbols.length; i++){
    //         if(filters["symbol"] !== ""){
    //             if(Util.search(filters["symbol"], symbols[i]["symbol"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["15m_min"] !== ""){
    //             if( parseInt(filters["15m_min"]) <= parseInt(symbols[i]["wt1_15m"]) 
    //                 && parseInt(filters["15m_min"]) <= parseInt(symbols[i]["wt2_15m"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["15m_max"] !== ""){
    //             if( parseInt(filters["15m_max"]) >= parseInt(symbols[i]["wt1_15m"]) 
    //                 && parseInt(filters["15m_max"]) >= parseInt(symbols[i]["wt2_15m"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["1h_min"] !== ""){
    //             if( parseInt(filters["1h_min"]) <= parseInt(symbols[i]["wt1_1h"]) 
    //                 && parseInt(filters["1h_min"]) <= parseInt(symbols[i]["wt2_1h"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["1h_max"] !== ""){
    //             if( parseInt(filters["1h_max"]) >= parseInt(symbols[i]["wt1_1h"]) 
    //                 && parseInt(filters["1h_max"]) >= parseInt(symbols[i]["wt2_1h"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["4h_min"] !== ""){
    //             if( parseInt(filters["4h_min"]) <= parseInt(symbols[i]["wt1_4h"]) 
    //                 && parseInt(filters["4h_min"]) <= parseInt(symbols[i]["wt2_4h"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["4h_max"] !== ""){
    //             if( parseInt(filters["4h_max"]) >= parseInt(symbols[i]["wt1_4h"]) 
    //                 && parseInt(filters["4h_max"]) >= parseInt(symbols[i]["wt2_4h"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["12h_min"] !== ""){
    //             if( parseInt(filters["12h_min"]) <= parseInt(symbols[i]["wt1_12h"]) 
    //                 && parseInt(filters["12h_min"]) <= parseInt(symbols[i]["wt2_12h"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["12h_max"] !== ""){
    //             if( parseInt(filters["12h_max"]) >= parseInt(symbols[i]["wt1_12h"]) 
    //                 && parseInt(filters["12h_max"]) >= parseInt(symbols[i]["wt2_12h"])){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["vol_$"] !== ""){
    //             let ticker = tickers[symbols[i]["symbol"]+"usdt"] ? tickers[symbols[i]["symbol"]+"usdt"] : 0;

    //             if( parseInt(filters["vol_$"]) <= (parseInt(symbols[i]["v_24h"]) * ticker / 1000000)){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         if(filters["vol_%"] !== ""){
    //             let vol = 0;
    //             if(parseFloat(symbols[i]["v_24h"]) !== 0){
    //                 vol = ( (parseFloat(symbols[i]["v_24h"]) - parseFloat(symbols[i]["vh_24h"]))*100 ) / parseFloat(symbols[i]["v_24h"]);
    //             }

    //             if( parseInt(filters["vol_%"]) <= vol){
    //                 //ok
    //             }else{
    //                 continue;
    //             }
    //         }

    //         filteredSymbols.push(symbols[i]);
    //     }

    //     return filteredSymbols;
    // }

    function filterSymbols(){
        let filteredSymbols = [];

        for(let i = 0; i < symbols.length; i++){
            if(filters["symbol"] !== ""){
                if(filters["symbol"].toLowerCase() === "*" && symbols[i]["fav_coin"] === "1"){
                    //ok
                }else if(Util.search(filters["symbol"], symbols[i]["symbol"])){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["15m_min"] !== ""){
                if( -parseInt(filters["15m_min"]) >= parseInt(symbols[i]["wt1_15m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["15m_max"] !== ""){
                if( -parseInt(filters["15m_max"]) >= parseInt(symbols[i]["wt2_15m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["1h_min"] !== ""){
                if( -parseInt(filters["1h_min"]) >= parseInt(symbols[i]["wt1_1h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["1h_max"] !== ""){
                if( -parseInt(filters["1h_max"]) >= parseInt(symbols[i]["wt2_1h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["4h_min"] !== ""){
                if( -parseInt(filters["4h_min"]) >= parseInt(symbols[i]["wt1_4h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["4h_max"] !== ""){
                if( -parseInt(filters["4h_max"]) >= parseInt(symbols[i]["wt2_4h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["12h_min"] !== ""){
                if( -parseInt(filters["12h_min"]) >= parseInt(symbols[i]["wt1_12h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["12h_max"] !== ""){
                if( -parseInt(filters["12h_max"]) >= parseInt(symbols[i]["wt2_12h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            


            if(filters["vol_$"] !== ""){
                let volq_24h = allSymbols.current[(symbols[i]["symbol"]+"usdt").toUpperCase()] ? allSymbols.current[(symbols[i]["symbol"]+"usdt").toUpperCase()].volq_24h : 0;

                if( parseInt(filters["vol_$"]) <= (volq_24h / 1000000)){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["vol_%"] !== ""){
                let vol_24h = allSymbols.current[(symbols[i]["symbol"]+"usdt").toUpperCase()] ? allSymbols.current[(symbols[i]["symbol"]+"usdt").toUpperCase()].vol_24h : 0;
                let vol = 0;
                if(parseFloat(symbols[i]["vh_24h"]) !== 0){
                    vol = (parseFloat(vol_24h)*100/parseFloat(symbols[i]["vh_24h"]))-100;
                }

                if( parseInt(filters["vol_%"]) <= vol){
                    //ok
                }else{
                    continue;
                }
            }

            filteredSymbols.push(symbols[i]);
        }

        return filteredSymbols;
    }

    const handleMenuClick = (event, symbol) => {
        setAnchorElMenu(event.currentTarget);
        setSelectedSymbol(symbol);
        setSelectedSymbolType("a");
    };
    const handleMenuClose = () => {
        setAnchorElMenu(null);
    };

    function onRemoveSymbol(){
        setConfirmRemoveDialog(true);
        handleMenuClose();
    }

    function removeSymbol(){
        setConfirmRemoveDialog(false);

        let lcSymbol = selectedSymbol.toLowerCase();

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/symbolsRemove.php?symbol="+lcSymbol;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    setSnackbarRemoveSymbol(true);
                    setSnackbarStatus({
                        symbol: selectedSymbol.toUpperCase(),
                        status: "success"
                    });
                    getData();
                    return;
                }

                setSnackbarRemoveSymbol(true);
                setSnackbarStatus({
                    symbol: selectedSymbol.toUpperCase(),
                    status: "error"
                });
                
            })
            .catch((error) => {
                console.log(error);
                setSnackbarRemoveSymbol(true);
                setSnackbarStatus({
                    symbol: selectedSymbol.toUpperCase(),
                    status: "error"
                });
            });
    }

    //blacklist
    function onBlacklistSymbol(){
        setConfirmBlacklistDialog(true);
        handleMenuClose();
    }

    function onBlacklistSymbolG(e, symbol){
        setSelectedSymbol(symbol);
        setSelectedSymbolType("g");
        setConfirmBlacklistDialog(true);
    }

    function blacklistSymbol(){
        setConfirmBlacklistDialog(false);

        let selectedSymbolBlacklist = false;

        let symbolsArr = [];
        if(selectedSymbolType === "a"){
            symbolsArr = symbols;
        }
        if(selectedSymbolType === "g"){
            symbolsArr = symbols_g;
        }

        for(let i = 0; i < symbolsArr.length; i++){
            let item = symbolsArr[i];
            if(item.symbol.toLowerCase() == selectedSymbol.toLowerCase()){
                if(item["blacklist"] == 1){
                    selectedSymbolBlacklist = true;
                }
            }
        }

        let lcNewBlacklist = 0;
        if(selectedSymbolBlacklist){
            lcNewBlacklist = 0;
        }else{
            lcNewBlacklist = 1;
        }
        let lcSymbol = selectedSymbol.toLowerCase();

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/symbolsBlacklistSet.php?symbol="+lcSymbol+"&blacklist="+lcNewBlacklist+"&list="+selectedSymbolType;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    getData();
                    return;
                }                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function onAddSymbolStatus(result){
        setSnackbarAddSymbol(true);
        setSnackbarStatus(result);

        if(result.status === "success"){
            getData();
        }
    }

    function handleSnackbarAddSymbol(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarAddSymbol(false);
    }

    function handleSnackbarRemoveSymbol(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarRemoveSymbol(false);
    }





    if(loading){
        return (<Loader />);
    }
    
    let filteredSymbols = filterSymbols();    

    let selectedSymbolBlacklist = false;
    let symbolsArr = [];
    if(selectedSymbolType === "a"){
        symbolsArr = filteredSymbols;
    }
    if(selectedSymbolType === "g"){
        symbolsArr = symbols_g;
    }
    for(let i = 0; i < symbolsArr.length; i++){
        let item = symbolsArr[i];
        if(item.symbol.toLowerCase() == selectedSymbol.toLowerCase()){
            if(item["blacklist"] == 1){
                selectedSymbolBlacklist = true;
            }
        }
    }

    return (
        <div className={styles.root}>

                <table style={{width: "1040px"}}>
                    <caption style={{marginBottom: 32}}>
                        Indicator: WaveTrend Oscillator [WT] for Gainers
                    </caption>

                    <div className={styles.specsGainers}>S1m: {specs["specs_1m"].wt1}/{specs["specs_1m"].wt2} Vol &gt;{specs["specs_1m"].vol_usdt}m, S1m 1.3%: {specs["specs_1m2"].wt1}/{specs["specs_1m2"].wt2} Vol &gt;{specs["specs_1m2"].vol_usdt}m</div>

                    <thead>
                        <tr>
                            <th style={{width:50}}>Nr.</th>
                            <th>Symbol</th>
                            <th>1m</th>
                            <th>15m</th>
                            <th>1h</th>
                            <th>Gain %</th>
                            <th>Price Lo 6L</th>
                            {/* <th>&nbsp;</th> */}
                            <th>Vol 24h $</th>
                            <th>Vol 24h %</th>

                            <th>Vol% 5m</th>
                            <th>Vol% 15m</th>

                            <th>Hi Fib 0</th>
                            <th>Last Update</th>
                            <th style={{width:17}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {symbols_g.map((row, index) => {
                            // if(row.symbol === "mask"){
                            //     console.log("extremum volume 24h: " + row.ex_volq_24h);
                            // }
                            return (
                                <ItemIndicatorG 
                                    key={row.symbol}  
                                    nr={index+1} 
                                    symbol={row.symbol} 
                                    gain_percent={row.gain_percent} 
                                    wt1_1m={row.wt1_1m} 
                                    wt2_1m={row.wt2_1m} 
                                    wt1_15m={row.wt1_15m} 
                                    wt2_15m={row.wt2_15m} 
                                    wt1_1h={row.wt1_1h} 
                                    wt2_1h={row.wt2_1h}
                                    v_1h={row.v_1h} 
                                    vh_1h={row.vh_1h} 
                                    lp_5m={row.lp_5m} 
                                    source={row.source} 
                                    last_update={row.last_update}
                                    ticker={tickers.current[(row.symbol+"usdt").toUpperCase()] ? tickers.current[(row.symbol+"usdt").toUpperCase()] : 0}
                                    fav_coin={row.fav_coin} 
                                    blacklist={row.blacklist}
                                    ex_vol_24h={parseFloat(row.ex_vol_24h)}
                                    ex_volq_24h={parseFloat(row.ex_volq_24h)}
                                    ex_h={parseFloat(row.ex_h)}

                                    vol_m_5m={parseFloat(row.vol_m_5m)}
                                    volq_m_5m={parseFloat(row.volq_m_5m)}
                                    vol_m_15m={parseFloat(row.vol_m_15m)}
                                    volq_m_15m={parseFloat(row.volq_m_15m)}

                                    current_vol_24h={allSymbols.current[(row.symbol+"usdt").toUpperCase()] ? allSymbols.current[(row.symbol+"usdt").toUpperCase()].vol_24h : 0}
                                    current_volq_24h={allSymbols.current[(row.symbol+"usdt").toUpperCase()] ? allSymbols.current[(row.symbol+"usdt").toUpperCase()].volq_24h : 0}>
                                    <div onClick={(e)=>{onBlacklistSymbolG(e, row.symbol)}} className={styles.menu}></div>
                                </ItemIndicatorG>
                            )
                        })}
                    </tbody>
                </table>

                <table style={{width: "1040px"}}>
                    <caption style={{marginBottom: 100}}>
                        Indicator: WaveTrend Oscillator [WT]
                    </caption>

                    <div className={styles.filtersBtns}>
                        <div className={styles.actionBtn} style={{marginLeft: 46, marginRight: 6}} onClick={()=>{clickOnSpec("15m")}}>15m</div>
                        <div className={styles.actionBtn} style={{marginLeft: 6, marginRight: 6}} onClick={()=>{clickOnSpec("1h")}}>1h</div>
                        <div className={styles.actionBtn} style={{marginLeft: 6, marginRight: 6}} onClick={()=>{clickOnSpec("4h")}}>4h</div>
                        <div className={styles.actionBtn} style={{marginLeft: 6, marginRight: 6}} onClick={()=>{clickOnSpec("4h2")}}>4h Dump</div>

                        <div className={styles.actionBtn} style={{marginLeft: 6, marginRight: 6}} onClick={clearFilters}>Clear</div>
                    </div>

                    <div className={styles.filters}>
                        <div className={styles.textInput} style={{width:76, marginLeft:46}}>
                            <input type="text" placeholder="Symbol" value={filters["symbol"]} onChange={(e)=>{handleFiltersChange(e, "symbol")}} />
                        </div>
                        <div className={styles.textInput+" "+styles.dual} style={{width:62, marginLeft:12}}>
                            {/* 15m */}
                            <input type="number" placeholder="0" value={filters["15m_min"]} onChange={(e)=>{handleFiltersChange(e, "15m_min")}} />
                            <input type="number" placeholder="0" value={filters["15m_max"]} onChange={(e)=>{handleFiltersChange(e, "15m_max")}} />
                        </div>
                        <div className={styles.textInput+" "+styles.dual} style={{width:62, marginLeft:12}}>
                            {/* 1h */}
                            <input type="number" placeholder="0" value={filters["1h_min"]} onChange={(e)=>{handleFiltersChange(e, "1h_min")}} />
                            <input type="number" placeholder="0" value={filters["1h_max"]} onChange={(e)=>{handleFiltersChange(e, "1h_max")}} />
                        </div>
                        <div className={styles.textInput+" "+styles.dual} style={{width:62, marginLeft:12}}>
                            {/* 4h */}
                            <input type="number" placeholder="0" value={filters["4h_min"]} onChange={(e)=>{handleFiltersChange(e, "4h_min")}} />
                            <input type="number" placeholder="0" value={filters["4h_max"]} onChange={(e)=>{handleFiltersChange(e, "4h_max")}} />
                        </div>
                        <div className={styles.textInput+" "+styles.dual} style={{width:62, marginLeft:12}}>
                            {/* 12h */}
                            <input type="number" placeholder="0" value={filters["12h_min"]} onChange={(e)=>{handleFiltersChange(e, "12h_min")}} />
                            <input type="number" placeholder="0" value={filters["12h_max"]} onChange={(e)=>{handleFiltersChange(e, "12h_max")}} />
                        </div>
                        <div className={styles.textInput} style={{width:76, marginLeft:6}}>
                            <input type="number" placeholder="0" value={filters["vol_$"]} onChange={(e)=>{handleFiltersChange(e, "vol_$")}} style={{textAlign:"right"}} />
                        </div>
                        <div className={styles.textInput} style={{width:62, marginLeft:6}}>
                            <input type="number" placeholder="0" value={filters["vol_%"]} onChange={(e)=>{handleFiltersChange(e, "vol_%")}} style={{textAlign:"right"}} />
                        </div>

                        <div className={styles.addSymbol} onClick={()=>{setSymbolAddDialog(true)}}>Add symbol</div>
                    </div>

                    <thead>
                        <tr>
                            <th style={{width:50}}>Nr.</th>
                            <th>Symbol</th>
                            <th>15m</th>
                            <th>1h</th>
                            {/* <th>2h</th> */}
                            <th>4h</th>
                            <th>12h</th>
                            <th>Vol 24h $</th>
                            <th>Vol 24h %</th>

                            <th>Vol% 5m</th>
                            <th>Vol% 15m</th>

                            <th>Lo Abs</th>

                            <th>★ NT WT</th>
                            <th>★ G Hi</th>

                            <th>Last Update</th>
                            <th style={{width:17}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSymbols.map((row, index) => {
                            return (
                                <ItemIndicator 
                                    key={row.symbol}  
                                    nr={index+1} 
                                    symbol={row.symbol} 
                                    wt1_15m={row.wt1_15m} 
                                    wt2_15m={row.wt2_15m} 
                                    wt1_1h={row.wt1_1h} 
                                    wt2_1h={row.wt2_1h} 
                                    wt1_2h={row.wt1_2h} 
                                    wt2_2h={row.wt2_2h} 
                                    wt1_4h={row.wt1_4h} 
                                    wt2_4h={row.wt2_4h} 
                                    wt1_12h={row.wt1_12h} 
                                    wt2_12h={row.wt2_12h} 
                                    wt1_1d={row.wt1_1d} 
                                    wt2_1d={row.wt2_1d}
                                    v_24h={row.v_24h} 
                                    vh_24h={row.vh_24h} 
                                    lp_48h={row.lp_48h} 
                                    lp_abs={row.lp_abs} 
                                    source={row.source} 

                                    s15m_wt_use={row.s15m_wt_use}
                                    s15m_gainer_hi={row.s15m_gainer_hi}

                                    vol_m_5m={parseFloat(row.vol_m_5m)}
                                    volq_m_5m={parseFloat(row.volq_m_5m)}
                                    vol_m_15m={parseFloat(row.vol_m_15m)}
                                    volq_m_15m={parseFloat(row.volq_m_15m)}

                                    last_update={row.last_update}
                                    ticker={tickers.current[(row.symbol+"usdt").toUpperCase()] ? tickers.current[(row.symbol+"usdt").toUpperCase()] : 0}
                                    current_vol_24h={allSymbols.current[(row.symbol+"usdt").toUpperCase()] ? allSymbols.current[(row.symbol+"usdt").toUpperCase()].vol_24h : 0}
                                    current_volq_24h={allSymbols.current[(row.symbol+"usdt").toUpperCase()] ? allSymbols.current[(row.symbol+"usdt").toUpperCase()].volq_24h : 0}
                                    blacklist={row.blacklist}
                                    fav_coin={row.fav_coin}>
                                        <div onClick={(e)=>{handleMenuClick(e, row.symbol)}} className={styles.menu}></div>
                                </ItemIndicator>
                            )
                        })}
                    </tbody>
                </table>

                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorElMenu}
                    open={openMenu}
                    onClose={handleMenuClose}>
                    <MenuItem onClick={onBlacklistSymbol} disableRipple>
                        <IcoBlock style={{marginRight: 12}}/> 
                        {selectedSymbolBlacklist ? "Remove from Black list" : "Add to Black list"}
                    </MenuItem>
                    <MenuItem onClick={onRemoveSymbol} disableRipple>
                        <IcoRemove style={{marginRight: 12}}/> 
                        Delete
                    </MenuItem>
                </StyledMenu>

                <ConfirmDialog 
                    open={confirmRemoveDialog} 
                    title="Remove" 
                    text={"Remove "+selectedSymbol.toUpperCase()+" from WT table ?"} 
                    okLabel="Remove" 
                    cancelLabel="Cancel" 
                    onOk={removeSymbol} 
                    onCancel={()=>{setConfirmRemoveDialog(false)}} /> 

                <ConfirmDialog 
                    open={confirmBlacklistDialog} 
                    title="Blacklist" 
                    text={(selectedSymbolBlacklist ? "Remove "+selectedSymbol.toUpperCase()+" from Blacklist ?" : "Add "+selectedSymbol.toUpperCase()+" to Blacklist ?")} 
                    okLabel={selectedSymbolBlacklist ? "Remove":"Add"}
                    cancelLabel="Cancel" 
                    onOk={blacklistSymbol} 
                    onCancel={()=>{setConfirmBlacklistDialog(false)}} /> 

                <SymbolAddDialog 
                    open={symbolAddDialog} 
                    onStatus={onAddSymbolStatus} 
                    onCancel={()=>{setSymbolAddDialog(false)}} 
                    onOk={()=>{setSymbolAddDialog(false)}} /> 

                <Snackbar open={snackbarAddSymbol} autoHideDuration={6000} onClose={handleSnackbarAddSymbol}>
                    <Alert onClose={handleSnackbarAddSymbol} severity={snackbarStatus.status} sx={{ width: '100%' }}>
                        <span>{snackbarStatus.symbol}</span> {snackbarStatus.status === "success" ? "added successfully." : "adding failed."}
                    </Alert>
                </Snackbar>

                <Snackbar open={snackbarRemoveSymbol} autoHideDuration={6000} onClose={handleSnackbarRemoveSymbol}>
                    <Alert onClose={handleSnackbarRemoveSymbol} severity={snackbarStatus.status} sx={{ width: '100%' }}>
                        <span>{snackbarStatus.symbol}</span> {snackbarStatus.status === "success" ? "removed successfully." : "remove failed."}
                    </Alert>
                </Snackbar>
            
        </div>
    );
}

export default Wt;


