import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import IcoX from '../../icons/IcoX';
import IcoArrDown from '../../icons/IcoArrDown';

import Loader from "../../components/Loader";
import Util, { capitalizeFirstLetter } from '../../utils/Util';
import Fetch from '../../utils/Fetch';
import Constants from '../../Constants';

import ConfirmDialog from '../ConfirmDialog';

import styles from './TradeDialog.module.css';

const WebSocketClient = require('websocket').w3cwebsocket;

TradeDialog.defaultProps = {
    open: false,
    strategy: "0",
    side: "buy",
    coin1: "COIN1",
    coin2: "COIN2",

    dynamic: false,

    onOk: null,
    onCancel: null,
    onStatus: null,
};

TradeDialog.propTypes = {
    open: PropTypes.bool,
    strategy: PropTypes.string,
    side: PropTypes.string,
    coin1: PropTypes.string,
    coin2: PropTypes.string,

    dynamic: PropTypes.bool,

    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    onStatus: PropTypes.func,
}

const TradeDialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: "transparent",
    }
}));

function TradeDialog(props) {

    const [loading, setLoading] = useState(true);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const [coin1Select, setCoin1Select] = useState(props.coin1);
    const [coin2Select, setCoin2Select] = useState(props.coin2);

    const [type, setType] = useState("market");
    const [price, setPrice] = useState(0);

    const [exchangeCoin, setExchangeCoin] = useState("coin2");
    const [quantity, setQuantity] = useState(0);


    const [exchangeInfo, setExchangeInfo] = useState({});
    const tickers = useRef({});
    const [balances, setBalances] = useState({});

    const [validPairings, setValidPairings] = useState({});
    const [validBalances, setValidBalances] = useState([]);

    const [forceUpdate, setForceUpdate] = useState(0);
    const updateTimer = useRef(null);

    const wsSymbolBookTicker = useRef({
        ws: null,
        reconnect: false,
    });

    const puWsConnLocal = useRef(null);

    
    const priceElm = useRef(null);
    const quantityElm = useRef(null);
    const quantityLast = useRef({
        coin1: 0,
        coin2: 0,
    });



    useEffect(()=>{
        startLocalWsConnection();

        return ()=>{
            //console.log("unmount");
        }
    }, []);
    

    useEffect(()=>{
        if(props.open){
            setCoin1Select(props.coin1);
            setCoin2Select(props.coin2);
            setType("market");
            setPrice(0);
            setExchangeCoin("coin2");
            setQuantity(0);
            quantityLast.current.coin1 = 0;
            quantityLast.current.coin2 = 0;

            getData();
            wsSymbolBookTicker.current.reconnect = true;
            startSymbolBookTicker();

            updateTimer.current = setInterval(()=>{
                setForceUpdate(Date.now());
            }, 5000);

        }else{
            if(wsSymbolBookTicker.current != null){
                wsSymbolBookTicker.current.reconnect = false;
                stopSymbolBookTicker();
            }

            if(updateTimer.current !== null){
                clearInterval(updateTimer.current);
                updateTimer.current = null;
            }
        }
    }, [props.open]);

    function startLocalWsConnection(){
    
        puWsConnLocal.current = new WebSocketClient("wss://at.ptx.ro/ws/");

        puWsConnLocal.current.onerror = function() {
            console.log('Local WebSocketClient Connection Error');
            try {
                puWsConnLocal.current.close();
            } catch(e) {
                console.log(e);
            }
        };

        puWsConnLocal.current.onopen = function() {
            console.log('Local WebSocketClient Connected');
        };

        puWsConnLocal.current.onclose = function() {
            console.log('Local WebSocketClient Closed');
            setTimeout(startLocalWsConnection, 1000);
        };

        puWsConnLocal.current.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);
                }
            } catch(e) {
                console.log("Local WebSocketClient Message Error");
            }
        };
    }

    function getData(){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/tradeInit.php?strategy="+props.strategy+"&side="+props.side;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {   
                
                //tickers
                var lcTickers = {};
                if(json.tickers != null){
                    for(let i = 0; i < json.tickers.length; i++){
                        var ticker = json.tickers[i];
                        lcTickers[ticker.symbol] = parseFloat(ticker.price)
                    }
                }
                tickers.current = lcTickers;

                //balances
                var balances = {};
                if(json.balances != null){
                    for(let i = 0; i < json.balances.balances.length; i++){
                        var balance = json.balances.balances[i];
                        balances[balance.asset] = {
                            free: parseFloat(balance.free),
                            locked: parseFloat(balance.locked)
                        }
                    }
                }
                setBalances(balances);

                //user balances
                let userBalances = [];
                let validBalances = [];
                let coins = Object.keys(balances);
                for(let i = 0; i < coins.length; i++){
                    let coin = coins[i];

                    let symbol = coin+"USDT";
                    let balanceFree = 0;
                    let balanceLocked = 0;

                    if(symbol === "USDTUSDT"){
                        balanceFree = balances[coin].free;
                        balanceLocked = balances[coin].locked;
                    }else if(tickers.current[symbol]){
                        balanceFree = tickers.current[symbol] * balances[coin].free;
                        balanceLocked = tickers.current[symbol] * balances[coin].locked;
                    }else{
                        continue;
                    }

                    let balanceTotal = balanceFree+balanceLocked;

                    if(balanceTotal < 10){ //dolars
                        continue;
                    }

                    let balanceObj = {
                        coin: coin,

                        free: balances[coin].free,
                        locked: balances[coin].locked,
                        total: balances[coin].free+balances[coin].locked,

                        free_usdt: balanceFree,
                        locked_usdt: balanceLocked,
                        total_usdt: balanceTotal,
                    }

                    userBalances.push(balanceObj);
                    validBalances.push(coin);
                }

                setValidBalances(validBalances);

                //exchange info
                let exchange_info_obj = {};
                let validPairings = {};
                if(json.exchange_info != null && json.exchange_info.symbols && Array.isArray(json.exchange_info.symbols)){    
                    let symbols = json.exchange_info.symbols;
                    for(let i = 0; i < symbols.length; i++){
                        let symbol = symbols[i].symbol;
                        let filters = symbols[i].filters;
    
                        exchange_info_obj[symbol] = {};
                        exchange_info_obj[symbol].can_trade = false;
    
                        for(let j = 0; j < filters.length; j++){
                            let filter = filters[j];
    
                            if(filter.filterType === "PRICE_FILTER"){
                                exchange_info_obj[symbol].min_trade_price = parseFloat(filter.minPrice);
                            }
    
                            if(filter.filterType === "LOT_SIZE"){
                                exchange_info_obj[symbol].min_trade_quantity = parseFloat(filter.minQty);
                            }
    
                            if(filter.filterType === "MIN_NOTIONAL"){
                                exchange_info_obj[symbol].min_trade_value = parseFloat(filter.minNotional);
                            }
                        }

                        if(symbols[i].status === "TRADING" && symbols[i].isSpotTradingAllowed === true){
                            if(symbols[i].orderTypes.indexOf('LIMIT') !== -1 && symbols[i].orderTypes.indexOf('MARKET') !== -1){
                                exchange_info_obj[symbol].can_trade = true;

                                if(props.side === "buy"){
                                    if(exchange_info_obj[symbol].min_trade_value && balances[symbols[i].quoteAsset]){
                                        if(exchange_info_obj[symbol].min_trade_value <= balances[symbols[i].quoteAsset].free){
    
                                            if(!validPairings[symbols[i].baseAsset]){
                                                validPairings[symbols[i].baseAsset] = [];
                                            }
            
                                            validPairings[symbols[i].baseAsset].push(symbols[i].quoteAsset);
    
                                        }
                                    }
                                }

                                if(props.side === "sell"){
                                    if(!validPairings[symbols[i].baseAsset]){
                                        validPairings[symbols[i].baseAsset] = [];
                                    }
    
                                    validPairings[symbols[i].baseAsset].push(symbols[i].quoteAsset);
                                }
                                

                            }
                        }


                    } 
                }

                setValidPairings(validPairings);
                setExchangeInfo(exchange_info_obj);


                if(props.side === "sell"){
                    let balance2 = 0;
                    if(balances[coin1Select]){
                        balance2 = balances[coin1Select].free;
                    }

                    setExchangeCoin("coin1");
                    quantityLast.current["coin1"] = balance2;
                    setQuantity(balance2);
                    quantityElm.current.focus();
                }

                if(props.side === "buy"){
                    quantityElm.current.focus();
                }

                setLoading(false); 
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function startSymbolBookTicker(){

        if(!wsSymbolBookTicker.current.reconnect){
            return;
        }
    
        var webSocketClient = new WebSocketClient("wss://stream.binance.com:9443/ws/!bookTicker");
        wsSymbolBookTicker.current.ws = webSocketClient;

        webSocketClient.onerror = function() {
            console.log('Ticker WebSocketClient Connection Error');
            try {
                webSocketClient.close();
            } catch(e) {
                console.log(e);
            }
        };

        webSocketClient.onopen = function() {
            console.log('Ticker WebSocketClient Connected');
        };

        webSocketClient.onclose = function() {
            console.log('Ticker WebSocketClient Closed');
            setTimeout(startSymbolBookTicker, 1000);
        };

        webSocketClient.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);

                    if(data.s){
                        if(tickers.current[data.s]){
                            tickers.current[data.s] = parseFloat(data.a);
                        }               
                    }
                }
            } catch(e) {
                console.log("Ticker WebSocketClient Message Error");
            }
        };
    }

    function stopSymbolBookTicker(){
        try {
            if(wsSymbolBookTicker.current.ws != null){
                wsSymbolBookTicker.current.ws.close();
            }
        } catch(e) {
            console.log(e);
        }
    }

    function onOk(){
        setConfirmDialog(true);
    }

    function onCancel(){
        if(props.onCancel){
            props.onCancel();
        }
    }

    function onStatus(status){
        let cbObject = {
            side: props.side,
            status: status,
        }
        if(props.onStatus){
            props.onStatus(cbObject);
        }
    }

    function onChangeType(type){
        setType(type);
        if(type === "limit"){
            setTimeout(()=>{
                priceElm.current.focus();
            }, 300);
        }
    }

    function onChangePrice(e){
        let value = parseFloat(e.target.value);
        let newValue = 0;

        if(isNaN(value)){
            newValue = e.target.value;
        }else if(value < 0){
            newValue = 0;
        }else{
            newValue = e.target.value;
        }

        setPrice(newValue);
    }

    function onExchangeCoin(value){
        setExchangeCoin(value);
        setQuantity(quantityLast.current[value]);
        quantityElm.current.focus();
    }

    function onChangeQuantity(e){
        let value = parseFloat(e.target.value);
        let newValue = 0;

        if(isNaN(value)){
            newValue = e.target.value;
        }else if(value < 0){
            newValue = 0;
        }else{
            newValue = e.target.value;
        }

        quantityLast.current[exchangeCoin] = newValue;
        setQuantity(newValue);
    }

    function onChangeCoin1(e){
        setCoin1Select(e.target.value);
    }

    function onChangeCoin2(e){
        setCoin2Select(e.target.value);
    }

    function onMaxBalance(){

        let balance = 0;
        if(balances[coin1Select]){
            balance = balances[coin1Select].free;
            if(props.side === "buy"){
                balance = balances[coin2Select].free / tickers.current[coin1Select+""+coin2Select];
            }
        }

        if(exchangeCoin === "coin2"){
            balance = balance*tickers.current[coin1Select+""+coin2Select];
            if(props.side === "buy"){
                balance = balances[coin2Select].free;
            }
        }

        quantityLast.current[exchangeCoin] = balance;
        setQuantity(balance)
    }

    function getTradeInfo(){
        let pair = coin1Select+coin2Select;

        let lcPrice = parseFloat(price);
        if(isNaN(lcPrice)){
            lcPrice = 0;
        }

        let lcQuantity = parseFloat(quantity);
        if(isNaN(lcQuantity)){
            lcQuantity = 0;
        }

        let quotation = 0;
        if(tickers.current[pair] && exchangeInfo[pair]){
            quotation = tickers.current[pair];
        }

        let totalEstimated = 0;
        let totalEstimatedCoin = 0;
        let finalPrice = 0;
        let finalQuantity = 0;
        let totalValue = 0;
        if(quotation > 0){
            let priceDecimals = Util.getDecimalsCount(exchangeInfo[pair].min_trade_price);
            let quantityDecimals = Util.getDecimalsCount(exchangeInfo[pair].min_trade_quantity);

            if(exchangeCoin === "coin1"){
                //quantity
                finalPrice = quotation;
                finalQuantity = lcQuantity;
                if(type === "limit"){
                    finalPrice = lcPrice;
                }

                totalEstimated = finalQuantity*finalPrice;
                totalEstimated = Util.toFixed(totalEstimated, priceDecimals);
                totalEstimatedCoin = coin2Select.toUpperCase();
                
            }else{
                //value
                finalPrice = quotation;
                
                if(type === "limit"){
                    finalPrice = lcPrice;
                }

                if(finalPrice === 0){
                    finalQuantity = 0;
                    totalEstimated = 0;
                }else{
                    finalQuantity = lcQuantity/finalPrice;
                    totalEstimated = lcQuantity/finalPrice;
                }
                
                totalEstimated = Util.toFixed(totalEstimated, quantityDecimals);
                totalEstimatedCoin = coin1Select.toUpperCase();
            }

            finalPrice = Util.toFixed(finalPrice, priceDecimals);
            finalQuantity = Util.toFixed(finalQuantity, quantityDecimals);

            totalValue = parseFloat(finalQuantity)*parseFloat(finalPrice);
            totalValue = Util.toFixed(totalValue, priceDecimals);
        }

        finalPrice = parseFloat(finalPrice);
        finalQuantity = parseFloat(finalQuantity);
        totalValue = parseFloat(totalValue);

        

        return {
            coin1: coin1Select.toUpperCase(),
            coin2: coin2Select.toUpperCase(),
            symbol: pair.toUpperCase(),
            side: props.side.toUpperCase(),
            type: type.toUpperCase(),
            quantity: finalQuantity,
            price: finalPrice,
            client_order_id: "",

            totalValue: totalValue,

            totalEstimated: totalEstimated,
            totalEstimatedCoin: totalEstimatedCoin,
        }
    }

    function newTrade(){

        setConfirmDialog(false);
        setTimeout(()=>{
            if(props.onOk){
                props.onOk();
            }
        }, 300)


        let tradeInfo = getTradeInfo();     

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/trade2.php?strategy="+props.strategy+"&coin1="+tradeInfo.coin1+"&coin2="+tradeInfo.coin2+"&side="+tradeInfo.side+"&type="+tradeInfo.type+"&quantity="+tradeInfo.quantity+"&price="+tradeInfo.price+"&client_order_id=";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    if(json.trade_result != null && json.trade_result.orderId){
                        
                        // try {
                        //     if(puWsConnLocal.current != null){
                        //         let json = {
                        //             source: "WEB_APP",
                        //             key: "WEB_APP",
                        //             data:{
                        //                 event: "NEW_TRADE",
                        //                 strategy: props.strategy,
                        //                 coin1: coin1Select,
                        //                 coin2: coin2Select,
                        //                 side: tradeInfo.side,
                        //                 type: tradeInfo.type,
                        //             }
                        //         }
                        //         puWsConnLocal.current.send(JSON.stringify(json));
                        //     }
                        // } catch(e) {
                        //     console.log(e);
                        // }

                        onStatus("success");
                        return;
                    }
                }

                onStatus("error");
                
            })
            .catch((error) => {
                console.log(error);
                onStatus("error");
            });
    }

    let title = capitalizeFirstLetter(props.side.toLowerCase());

    let typeCss = {
        market: "",
        limit: "",
    }
    typeCss[type] = styles.active;

    let priceCss = "";
    if(type === "market"){
        priceCss = styles.disabled;
    }

    let exchangeCoinCss = {
        coin1: "",
        coin2: "",
    }
    exchangeCoinCss[exchangeCoin] = styles.active;


    let selectCoin1OptionsElm = [];
    let selectCoin1DisplayValue = coin1Select;
    let coins1Arr = validBalances;
    if(props.side === "buy"){
        coins1Arr = Object.keys(validPairings);
    }

    coins1Arr.sort(function(a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
      
        return 0;
    });

    selectCoin1OptionsElm.push((<option key={-1} value={""}>Select coin</option>));
    for(let i = 0; i < coins1Arr.length; i++){
        selectCoin1OptionsElm.push((<option key={coins1Arr[i]} value={coins1Arr[i]}>{coins1Arr[i]}</option>));
    }


    let selectCoin2OptionsElm = [];
    let selectCoin2DisplayValue = coin2Select;
    if(validPairings[coin1Select]){
        let coins2Arr = validPairings[coin1Select];

        coins2Arr.sort(function(a, b) {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
          
            return 0;
        });
        
        selectCoin2OptionsElm.push((<option key={-1} value={""}>Select coin</option>));
        for(let i = 0; i < coins2Arr.length; i++){
            selectCoin2OptionsElm.push((<option key={coins2Arr[i]} value={coins2Arr[i]}>{coins2Arr[i]}</option>));
        }
    }




    let pairElm = null;

    if(props.dynamic){

        pairElm = (
            <div className={styles.pairInput}>            
                <div className={styles.selectInput} style={{width:"48%", float:"left"}}>
                    <select 
                        onChange={onChangeCoin1}
                        value={selectCoin1DisplayValue}>
                        {selectCoin1OptionsElm}
                    </select>

                    <div className={styles.displayValue}>
                        {selectCoin1DisplayValue}
                        <IcoArrDown className={styles.displayValueArrow} fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>

                <div className={styles.selectInput} style={{width:"48%", float:"right"}}>
                    <select 
                        onChange={onChangeCoin2}
                        value={selectCoin2DisplayValue}>
                        {selectCoin2OptionsElm}
                    </select>

                    <div className={styles.displayValue}>
                        {selectCoin2DisplayValue}
                        <IcoArrDown className={styles.displayValueArrow} fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
            </div>
        )

    }else{
        if(props.side === "buy"){
            pairElm = (
                <div className={styles.pairInput}>
                    <div className={styles.leftLabel}>
                        Buy w {coin2Select}  -{'>'}
                    </div>
                
                    <div className={styles.selectInput}>
                        <select 
                            onChange={onChangeCoin1}
                            value={selectCoin1DisplayValue}>
                            {selectCoin1OptionsElm}
                        </select>

                        <div className={styles.displayValue}>
                            {selectCoin1DisplayValue}
                            <IcoArrDown className={styles.displayValueArrow} fill="rgba(255,255,255,0.4)" />
                        </div>
                    </div>
                </div>
            )
        }

        if(props.side === "sell"){
            pairElm = (
                <div className={styles.pairInput}>
                    <div className={styles.leftLabel}>
                        Sell {coin1Select}  -{'>'}
                    </div>
                
                    <div className={styles.selectInput}>
                        <select 
                            onChange={onChangeCoin2}
                            value={selectCoin2DisplayValue}>
                            {selectCoin2OptionsElm}
                        </select>

                        <div className={styles.displayValue}>
                            {selectCoin2DisplayValue}
                            <IcoArrDown className={styles.displayValueArrow} fill="rgba(255,255,255,0.4)" />
                        </div>
                    </div>
                </div>
            )
        }
    }

    let tradeInfo = getTradeInfo();
    let totalEstimated = tradeInfo.totalEstimated;
    let textConfirm = tradeInfo.side+" "+tradeInfo.type+" "+tradeInfo.quantity+" "+coin1Select+" -> "+tradeInfo.totalValue+" "+coin2Select+" ?";

    let loader = null;
    if(loading){
        loader = (<div className={styles.loading}><Loader /></div>);
    }

    return (

        <TradeDialogStyled
            open={props.open}
            onClose={onCancel}>

            <div className={styles.content}>
                <div className={styles.head}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.close} onClick={onCancel}>
                        <IcoX fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyCenter}>

                        {pairElm}
                        
                        <div className={styles.switchInput} style={{marginTop: 30}}>
                            <div className={styles.left+" "+typeCss["market"]} onClick={()=>{onChangeType("market")}}>Market</div>
                            <div className={styles.right+" "+typeCss["limit"]} onClick={()=>{onChangeType("limit")}}>Limit</div>
                        </div>

                        <div className={styles.textInput+" "+priceCss} style={{marginTop: 12}}>
                            <div className={styles.leftLabel}>Price</div>
                            <div className={styles.rightLabel}>{type === "limit" ? coin2Select : "Market"}</div>
                            <input ref={priceElm} style={{visibility: type === "limit" ? "visible" : "hidden"}} type="number" value={price} onChange={onChangePrice} />
                        </div>

                        <div className={styles.switchInput} style={{marginTop: 30}}>
                            <div className={styles.left+" "+exchangeCoinCss["coin1"]} onClick={()=>{onExchangeCoin("coin1")}}>Qty {coin1Select}</div>
                            <div className={styles.right+" "+exchangeCoinCss["coin2"]} onClick={()=>{onExchangeCoin("coin2")}}>Total {coin2Select}</div>
                        </div>

                        <div className={styles.textInput} style={{marginTop: 12}}>
                            <div className={styles.leftLabel}>{exchangeCoin === "coin1" ? "Quantity" : "Total"}</div>
                            <div className={styles.rightLabel}>{exchangeCoin === "coin1" ? coin1Select : coin2Select}</div>
                            <input ref={quantityElm} type="number" value={quantity} onChange={onChangeQuantity} />
                            <span className={styles.maxBalance} onClick={onMaxBalance}>Max</span>
                        </div>

                        <div className={styles.exchangeInfo} style={{marginTop: 12}}>
                            <div className={styles.left}>{exchangeCoin === "coin1" ? "Total "+coin2Select+" est:" : "Total Qty est:"}</div>
                            <div className={styles.right}>{totalEstimated}</div>
                        </div>

                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.btnCancel} onClick={onCancel}>Cancel</div>
                    <div className={styles.btnOk} onClick={onOk}>{title+" "+coin1Select}</div>
                </div>

                {loader}
            </div>

            <ConfirmDialog open={confirmDialog} title={title+" "+coin1Select} text={textConfirm} okLabel={title} cancelLabel="Cancel" onOk={newTrade} onCancel={()=>{setConfirmDialog(false)}} />  

        </TradeDialogStyled>

    );
}


export default TradeDialog;