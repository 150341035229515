import React, {useEffect, useState, useRef} from 'react';

import {Link, useParams, useHistory} from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';

import Loader from "../../components/Loader";
import ConfirmDialog from '../../components/ConfirmDialog';
import ItemIndicatorWatch from "../../components/ItemIndicatorWatch";
import ToggleInput from "../../components/ToggleInput";
import SelectBox from '../../components/SelectBox/SelectBox';

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './StrategySettings.module.css';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});




export default function StrategySettings(props) {
    const params = useParams();
    const history = useHistory()

    const [loading, setLoading] = useState(true);
    const [strategies, setStrategies] = useState([]);
    const [categories, setCategories] = useState({});
    const [symbols, setSymbols] = useState({});

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState("");

    const [strategy, setStrategy] = useState(null);

    useEffect(()=>{

        if(Constants.STRATEGIES["id"+params.strategy_id]){
            setStrategy(Constants.STRATEGIES["id"+params.strategy_id]);
        }else{
            history.replace("/");
        }

        return ()=>{
            //console.log("unmount");
        }
    }, [params.strategy_id]);

    useEffect(()=>{

        if(strategy != null){

            document.title = "Settings: " + strategy.name;        

            setLoading(true); 
            
            getData();

        }

        return ()=>{

        }
    }, [strategy]);

    function getData(cb){

        let body = new FormData();
        body.append("strategy_id", strategy.id);

        let options = {
            method: 'POST',
            body: body
        }

        let url = Constants.API_URL+"/api/strategySettings.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    
            
                if(json.status === "ok"){

                    let categoriesObj = {};
                    for(let i = 0; i < json.categories.length; i++){
                        categoriesObj[json.categories[i].tag] = json.categories[i].display;
                    }

                    let symbolsObj = {};
                    for(let i = 0; i < json.symbols.length; i++){
                        symbolsObj[json.symbols[i].symbol] = json.symbols[i].coin1;
                    }

                    setStrategies(json.strategies);
                    setCategories(categoriesObj);
                    setSymbols(symbolsObj);
                    setLoading(false); 

                    if(cb){
                        cb();
                    }
                }

            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function updateSettings(strategy_id, valuesObj, cb){

        let body = new FormData();
        body.append("strategy_id", strategy_id);
        body.append("trade_conditions", valuesObj.trade_conditions);
        body.append("category_conditions", valuesObj.category_conditions);
        body.append("symbol_conditions", valuesObj.symbol_conditions);

        let options = {
            method: 'POST',
            body: body
        }

        let url = Constants.API_URL+"/api/strategySettingsUpdate.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    
            
                if(json.status === "ok"){
                    setStatusUpdate("success");
                }else{
                    setStatusUpdate("error");
                }

                setSnackbarOpen(true);

                if(cb){
                    cb();
                }

            })
            .catch((error) => {
                // console.log(error);
                setStatusUpdate("error");
                setSnackbarOpen(true);

                if(cb){
                    cb();
                }
            });
    }

    function handleSnackbarClose(event, reason){
        // if (reason === 'clickaway') {
        //     return;
        // }
        setSnackbarOpen(false);
    }

    if(loading){
        return (<Loader />);
    }

    // let filteredStrategies = [];
    // let startegiesSimple = [];
    // let startegiesPrior = [];

    // for(let i = 0; i < strategies.length; i++){
    //     let item = strategies[i];
    //     if(item.id % 2 == 0){
    //         startegiesPrior.push(item);
    //     }else{
    //         startegiesSimple.push(item);
    //     }
    // }

    // filteredStrategies = [...startegiesPrior, ...startegiesSimple]

    return (
        <div className={styles.root}>

            <div className={styles.head}>
                Strategy Settings
            </div>

            <div className={styles.cardContainer}>
                {strategies.map((row, index) => {
                    return (
                        <Item 
                            key={row.id}
                            item={row} 
                            categories={categories}
                            symbols={symbols}
                            onUpdate={updateSettings}
                        />
                    )
                })}
            </div>

            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={statusUpdate} sx={{ width: '100%' }}>
                    <span style={{textTransform: "capitalize"}}>Update</span> {statusUpdate === "success" ? "completed successfully." : "failed."}
                </Alert>
            </Snackbar>
            
        </div>
    );
}

function Item(props){

    // console.log(props.item);

    const btcDumpPresets = {
        "_0-4":{
            "btc_dump_cond": true,
            "btc_dump_limit": 4,
            "btc_dump_buy": "before",
            "btc_dump_cooldown_wait_min": 60,
            "btc_dump_cooldown_exit_limit": 8,
            "btc_dump_buy_after_cooldown_if_dump_lower": 0,
        },

        "_4-5":{
            "btc_dump_cond": true,
            "btc_dump_limit": 4,
            "btc_dump_buy": "after",
            "btc_dump_cooldown_wait_min": 60,
            "btc_dump_cooldown_exit_limit": 5,
            "btc_dump_buy_after_cooldown_if_dump_lower": 4,
        },

        "_5-8":{
            "btc_dump_cond": true,
            "btc_dump_limit": 5,
            "btc_dump_buy": "after",
            "btc_dump_cooldown_wait_min": 60,
            "btc_dump_cooldown_exit_limit": 8,
            "btc_dump_buy_after_cooldown_if_dump_lower": 5,
        },
    }
    const [tradeConditions, setTradeConditions] = useState({

        "master_switch_buy": false,

        "btc_dump_signal": "off",
        "btc_dump_signal_start_p1": 0,
        "btc_dump_signal_start_p2": 0,
        "btc_dump_signal_stop_p1": 0,
        "btc_dump_signal_stop_p2": 0,

        //new btc dump

        "btc_dump_cond": false,
        "btc_dump_limit": 5,
        "btc_dump_buy": "before",
        "btc_dump_cooldown_wait_min": 60,
        "btc_dump_cooldown_exit_limit": 8,
        "btc_dump_buy_after_cooldown_if_dump_lower": 5,

        //new btc dump


        "diff_ex_hl_lt100_fib_percent" : "",
        "diff_ex_hl_gt100_fib_percent" : "",
        "price_ath_fib_percent" : "",

        "price_lt_sma200_cond": true,


        "gain_percent" : "",
        "price_drop_percent_lt20h" : "",
        "price_drop_percent_gt20h" : "",
        "price_drop_percent_gt72h" : "",

        "1m_wt1" : "",
        "1m_wt2" : "",
        "1m_rsi14" : "",
        "1m_tp" : "",
        "1m_tp_trailing" : "",
        "1m_price_diff_ex_h_lt100" : "",
        "1m_price_diff_ex_h_gt100" : "",

        "3m_wt1" : "",
        "3m_wt2" : "",
        "3m_rsi14" : "",
        "3m_tp" : "",
        "3m_tp_trailing" : "",
        "3m_price_diff_ex_h_lt100" : "",
        "3m_price_diff_ex_h_gt100" : "",

        "5m_wt1" : "",
        "5m_wt2" : "",
        "5m_rsi14" : "",
        "5m_tp" : "",
        "5m_tp_trailing" : "",
        "5m_price_diff_ex_h_lt100" : "",
        "5m_price_diff_ex_h_gt100" : "",

        "15m_wt1" : "",
        "15m_wt2" : "",
        "15m_rsi14" : "",
        "15m_tp" : "",
        "15m_tp_trailing" : "",
        "15m_price_diff_ex_h_lt100" : "",
        "15m_price_diff_ex_h_gt100" : "",

        "30m_wt1" : "",
        "30m_wt2" : "",
        "30m_rsi14" : "",
        "30m_tp" : "",
        "30m_tp_trailing" : "",
        "30m_price_diff_ex_h_lt100" : "",
        "30m_price_diff_ex_h_gt100" : "",

        "1h_wt1" : "",
        "1h_wt2" : "",
        "1h_rsi14" : "",
        "1h_tp" : "",
        "1h_tp_trailing" : "",
        "1h_price_diff_ex_h_lt100" : "",
        "1h_price_diff_ex_h_gt100" : "",

        "2h_wt1" : "",
        "2h_wt2" : "",
        "2h_rsi14" : "",
        "2h_tp" : "",
        "2h_tp_trailing" : "",
        "2h_price_diff_ex_h_lt100" : "",
        "2h_price_diff_ex_h_gt100" : "",

        "4h_wt1" : "",
        "4h_wt2" : "",
        "4h_rsi14" : "",
        "4h_tp" : "",
        "4h_tp_trailing" : "",
        "4h_price_diff_ex_h_lt100" : "",
        "4h_price_diff_ex_h_gt100" : "",

        "6h_wt1" : "",
        "6h_wt2" : "",
        "6h_rsi14" : "",
        "6h_tp" : "",
        "6h_tp_trailing" : "",
        "6h_price_diff_ex_h_lt100" : "",
        "6h_price_diff_ex_h_gt100" : "",

        "8h_wt1" : "",
        "8h_wt2" : "",
        "8h_rsi14" : "",
        "8h_tp" : "",
        "8h_tp_trailing" : "",
        "8h_price_diff_ex_h_lt100" : "",
        "8h_price_diff_ex_h_gt100" : "",

        "12h_wt1" : "",
        "12h_wt2" : "",
        "12h_rsi14" : "",
        "12h_tp" : "",
        "12h_tp_trailing" : "",
        "12h_price_diff_ex_h_lt100" : "",
        "12h_price_diff_ex_h_gt100" : "",

        "1d_wt1" : "",
        "1d_wt2" : "",
        "1d_rsi14" : "",
        "1d_tp" : "",
        "1d_tp_trailing" : "",
        "1d_price_diff_ex_h_lt100" : "",
        "1d_price_diff_ex_h_gt100" : "",

        "3d_wt1" : "",
        "3d_wt2" : "",
        "3d_rsi14" : "",
        "3d_tp" : "",
        "3d_tp_trailing" : "",
        "3d_price_diff_ex_h_lt100" : "",
        "3d_price_diff_ex_h_gt100" : "",

        "1w_wt1" : "",
        "1w_wt2" : "",
        "1w_rsi14" : "",
        "1w_tp" : "",
        "1w_tp_trailing" : "",
        "1w_price_diff_ex_h_lt100" : "",
        "1w_price_diff_ex_h_gt100" : "",
    });
    const [categoryConditions, setCategoryConditions] = useState({});
    const [symbolConditions, setSymbolConditions] = useState({});


    const [symbolFilter, setSymbolFilter] = useState("");

    useEffect(()=>{

        let trade_conditions = JSON.parse(props.item.trade_conditions);
        let newTradeConditions = {...tradeConditions, ...trade_conditions}

        setTradeConditions(newTradeConditions);

        return ()=>{}

    }, [props.item.trade_conditions]);

    useEffect(()=>{

        let category_conditions = JSON.parse(props.item.category_conditions);
        let newCategoryConditions = {...categoryConditions, ...category_conditions}

        //check for new category
        let categoriesArr = Object.keys(props.categories);
        for(let i = 0; i < categoriesArr.length; i++){
            let catName = categoriesArr[i];
            let catDisplay = props.categories[catName];

            if(newCategoryConditions[catName]){
                newCategoryConditions[catName].display = catDisplay;
            }else{
                if(props.item.id == 3){
                    newCategoryConditions[catName] = {
                        name: catName,
                        display: catDisplay,
                        active: 0,
                        price_diff_ex_h_lt100 : "",
                        price_diff_ex_h_gt100 : "",
                    };
                }

                if(props.item.id == 9){
                    newCategoryConditions[catName] = {
                        name: catName,
                        display: catDisplay,
                        active: 0,
                    };
                }
                
            }
        }

        //delete old category
        let newCategoriesArr = Object.keys(newCategoryConditions);
        for(let i = 0; i < newCategoriesArr.length; i++){
            let catName = newCategoriesArr[i];
            if(props.categories[catName]){
                //all good
            }else{
                delete newCategoryConditions[catName];
            }
        }

        setCategoryConditions(newCategoryConditions);

        return ()=>{}

    }, [props.item.category_conditions]);

    useEffect(()=>{

        let symbol_conditions = JSON.parse(props.item.symbol_conditions);
        let newSymbolConditions = {...symbolConditions, ...symbol_conditions}

        //check for new symbol
        let symbolsArr = Object.keys(props.symbols);
        for(let i = 0; i < symbolsArr.length; i++){
            let symbolName = symbolsArr[i];
            let symbolDisplay = props.symbols[symbolName];

            if(newSymbolConditions[symbolName]){
                newSymbolConditions[symbolName].display = symbolDisplay;
            }else{
                if(props.item.id == 3){
                    newSymbolConditions[symbolName] = {
                        name: symbolName,
                        display: symbolDisplay,
                        pd0: 0,
                        pd1 : 0,
                        pd2 : 0,
                    };
                }

                if(props.item.id == 9){
                    // newSymbolConditions[symbolName] = {
                    //     name: symbolName,
                    //     display: symbolDisplay,
                    //     rsi14: 0,
                    // };
                }
            }
        }

        //delete old symbol
        let newSymbolsArr = Object.keys(newSymbolConditions);
        for(let i = 0; i < newSymbolsArr.length; i++){
            let symbolName = newSymbolsArr[i];
            if(props.symbols[symbolName]){
                //all good
            }else{
                delete newSymbolConditions[symbolName];
            }
        }

        setSymbolConditions(newSymbolConditions);

        return ()=>{}

    }, [props.item.symbol_conditions]);

    function onChangeTradeCondition(condition, value){
        // if(isNaN(value) || !isFinite(value)){
        //     return;
        // }

        if(parseFloat(value) < 0 || parseFloat(value) > 100){
            return;
        }

        let newTradeConditions = {...tradeConditions};
        newTradeConditions[condition] = value;

        setTradeConditions(newTradeConditions);
    }

    function onChangeCategoryConditions(name, condition, value){
        // if(isNaN(value) || !isFinite(value)){
        //     return;
        // }

        if(parseFloat(value) < 0 || parseFloat(value) > 100){
            return;
        }

        let newCategoryConditions = {...categoryConditions};
        newCategoryConditions[name][condition] = value;

        setCategoryConditions(newCategoryConditions);
    }

    function onChangeSymbolConditions(name, condition, value){
        // if(isNaN(value) || !isFinite(value)){
        //     return;
        // }

        if(parseFloat(value) < 0 || parseFloat(value) > 100){
            return;
        }

        let newSymbolConditions = {...symbolConditions};
        newSymbolConditions[name][condition] = value;

        setSymbolConditions(newSymbolConditions);
    }

    function update(){
        if(props.onUpdate){
            props.onUpdate(item.id, {
                trade_conditions: JSON.stringify(tradeConditions),
                category_conditions: JSON.stringify(categoryConditions),
                symbol_conditions: JSON.stringify(symbolConditions),
            });
        }
    }

    function onClickBtcDumpPreset(label){
        if(btcDumpPresets[label]){
            let newTradeConditions = {...tradeConditions, ...btcDumpPresets[label]};
            setTradeConditions(newTradeConditions);
        }
    }


    let item = props.item;

    let btcDumpElm = null;
    if(item.id == 3){

        let btcDumpOpacity = tradeConditions["btc_dump_cond"] ? 1 : 0.5;

        btcDumpElm = (
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, width: 386}}>
                <div style={{marginTop: 0, width: '100%'}}>
                    <span style={{width: 250, display:"inline-block"}}>BTC dump condition</span>
                    <span>Off</span>
                    <Switch
                        checked={tradeConditions["btc_dump_cond"]}
                        onChange={(e)=>{onChangeTradeCondition("btc_dump_cond", e.target.checked)}}
                        sx={{
                            "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                color: "#FF4A63"
                            },
                          
                            "&.MuiSwitch-root .Mui-checked": {
                                color: "#2EBC85"
                            },

                            "&.MuiSwitch-root .MuiSwitch-track":{
                                background: tradeConditions["btc_dump_cond"] ? "#2EBC85" : "#FF4A63"
                            },
                        }}
                    />
                    <span>On</span>
                </div>

                <div className={styles.custom} style={{marginTop: 0, opacity: btcDumpOpacity}}>
                    <span style={{width: 250}}>BTC dump limit (%)</span>
                    <input className={styles.input} style={{width: 96}} 
                        type="number" 
                        placeholder="" 
                        value={tradeConditions["btc_dump_limit"]} 
                        onChange={(e)=>{onChangeTradeCondition("btc_dump_limit", e.target.value)}}/>
                </div>

                <div style={{marginTop: 0, width: '100%', opacity: btcDumpOpacity}}>
                    <span style={{width: 250, display:"inline-block",paddingLeft:20}}>Buy before BTC dump</span>
                    <span>Off</span>
                    <Switch
                        checked={tradeConditions["btc_dump_buy"] === "before"}
                        // onChange={onBtcDumpBuyBeforeChange}
                        onChange={(e)=>{onChangeTradeCondition("btc_dump_buy", (e.target.checked ? "before" : "after"))}}
                        sx={{
                            "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                color: "#FF4A63"
                            },
                          
                            "&.MuiSwitch-root .Mui-checked": {
                                color: "#2EBC85"
                            },

                            "&.MuiSwitch-root .MuiSwitch-track":{
                                background: tradeConditions["btc_dump_buy"] === "before" ? "#2EBC85" : "#FF4A63"
                            },
                        }}
                    />
                    <span>On</span>
                </div>

                <div style={{marginTop: 0, width: '100%', opacity: btcDumpOpacity}}>
                    <span style={{width: 250, display:"inline-block",paddingLeft:20}}>Buy after BTC dump</span>
                    <span>Off</span>
                    <Switch
                        checked={tradeConditions["btc_dump_buy"] === "after"}
                        // onChange={onBtcDumpBuyAfterChange}
                        onChange={(e)=>{onChangeTradeCondition("btc_dump_buy", (e.target.checked ? "after" : "before"))}}
                        sx={{
                            "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                color: "#FF4A63"
                            },
                          
                            "&.MuiSwitch-root .Mui-checked": {
                                color: "#2EBC85"
                            },

                            "&.MuiSwitch-root .MuiSwitch-track":{
                                background: tradeConditions["btc_dump_buy"] === "after" ? "#2EBC85" : "#FF4A63"
                            },
                        }}
                    />
                    <span>On</span>
                </div>

                <div className={styles.custom} style={{marginTop: 20, opacity: btcDumpOpacity}}>
                    <span style={{width: 250}}>Cooldown</span>
                </div>

                <div className={styles.custom} style={{marginTop: 0, opacity: btcDumpOpacity}}>
                    <span style={{width: 250, paddingLeft:20,}}>Wait Time (min)</span>
                    <input className={styles.input} style={{width: 96}} 
                        type="number" 
                        placeholder="" 
                        value={tradeConditions["btc_dump_cooldown_wait_min"]} 
                        onChange={(e)=>{onChangeTradeCondition("btc_dump_cooldown_wait_min", e.target.value)}}/>
                </div>

                <div className={styles.custom} style={{marginTop: 0, opacity: btcDumpOpacity}}>
                    <span style={{width: 250,paddingLeft:20,}}>Exit if dump lower than (%)</span>
                    <input className={styles.input} style={{width: 96}} 
                        type="number" 
                        placeholder="" 
                        value={tradeConditions["btc_dump_cooldown_exit_limit"]} 
                        onChange={(e)=>{onChangeTradeCondition("btc_dump_cooldown_exit_limit", e.target.value)}}/>
                </div>




                <div className={styles.custom} style={{marginTop: 20, opacity: btcDumpOpacity}}>
                    <span style={{width: 250}}>After cooldown</span>
                </div>
                <div className={styles.custom} style={{marginTop: 0, opacity: btcDumpOpacity}}>
                    <span style={{width: 250, paddingLeft:20}}>Buy if dump lower than (%)</span>
                    <input className={styles.input} style={{width: 96}} 
                        type="number" 
                        placeholder="" 
                        value={tradeConditions["btc_dump_buy_after_cooldown_if_dump_lower"]} 
                        onChange={(e)=>{onChangeTradeCondition("btc_dump_buy_after_cooldown_if_dump_lower", e.target.value)}}/>
                </div>



                {/* <div className={styles.custom} style={{marginTop: 20, opacity: btcDumpOpacity}}>
                    <span style={{width: 250}}>Presets</span>
                </div> */}

                <div style={{marginTop: 20, width:"100%"}}>
                    <span style={{display:"inline-block",width: 90}}>Presets</span>
                    <div className={styles.actionBtn} onClick={()=>{onClickBtcDumpPreset("_0-4")}}>0-4%</div>
                    <div className={styles.actionBtn} style={{marginLeft: 10, marginRight: 10}} onClick={()=>{onClickBtcDumpPreset("_4-5")}}>4-5%</div>
                    <div className={styles.actionBtn} onClick={()=>{onClickBtcDumpPreset("_5-8")}}>5-8%</div>
                </div>
            

                {/* <SelectBox 
                    style={{width: "100%"}}
                    design="input"
                    options={[
                        {label:"Btc Dump Start", value: "start"},
                        {label:"Btc Dump Stop", value: "stop"},
                        {label:"No Btc Dump Condition", value: "off"},
                    ]}
                    selected={tradeConditions["btc_dump_signal"]}
                    onChange={(value)=>{onChangeTradeCondition("btc_dump_signal", value)}}
                /> */}

            </div>
        )
    }

    // let priorElm = null;
    // if(item.id == 2 || item.id == 4 || item.id == 5 || item.id == 6 || item.id == 7 || item.id == 8){
    //     priorElm = (
    //         <>
    //             <div className={styles.custom+" "+styles.first}>
    //                 <span>Gain %</span>
    //                 {item.id == 2 || item.id == 4 ? <span style={{width: 160}}>Price drop 0-20h</span> : null}
    //                 {item.id == 5 || item.id == 7 ? <span style={{width: 160}}>Price drop 20-72h</span> : null}
    //                 {item.id == 6 || item.id == 8 ? <span style={{width: 160}}>Price drop 72-240h</span> : null}
    //             </div>

    //             <div className={styles.custom}>
    //                 <input type="number" placeholder="" value={tradeConditions["gain_percent"]} onChange={(e)=>{onChangeTradeCondition("gain_percent", e.target.value)}}/>
    //                 {item.id == 2 || item.id == 4 ? <input style={{width: 160}} type="number" placeholder="" value={tradeConditions["price_drop_percent_lt20h"]} onChange={(e)=>{onChangeTradeCondition("price_drop_percent_lt20h", e.target.value)}}/> : null}
    //                 {item.id == 5 || item.id == 7 ? <input style={{width: 160}} type="number" placeholder="" value={tradeConditions["price_drop_percent_gt20h"]} onChange={(e)=>{onChangeTradeCondition("price_drop_percent_gt20h", e.target.value)}}/> : null}
    //                 {item.id == 6 || item.id == 8 ? <input style={{width: 160}} type="number" placeholder="" value={tradeConditions["price_drop_percent_gt72h"]} onChange={(e)=>{onChangeTradeCondition("price_drop_percent_gt72h", e.target.value)}}/> : null}
    //             </div>
    //         </>
    //     )
    // }



    let gainPercentElm = null;
    if(item.id == 9){

        gainPercentElm = 
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, width: 386}}>

                <div className={styles.custom} style={{marginTop: 20, opacity: 1}}>
                    <span style={{width: 250}}>Gain 24h</span>
                </div>

                <div className={styles.custom} style={{marginTop: 0, opacity: 1}}>
                    <span style={{width: 250, paddingLeft:20,}}>Minimum Gain %</span>
                    <input className={styles.input} style={{width: 96}} 
                        type="number" 
                        placeholder="" 
                        value={tradeConditions["gain_percent"]} 
                        onChange={(e)=>{onChangeTradeCondition("gain_percent", e.target.value)}}/>
                </div>

            </div>;

    }




    let bfHiLoDiffElm = null;
    if(item.id == 3){
        bfHiLoDiffElm = 
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, width: 386}}>

                <div className={styles.custom} style={{marginTop: 20, opacity: 1}}>
                    <span style={{width: 250}}>Coin price - Fibo Daily</span>
                </div>

                <div className={styles.custom} style={{marginTop: 0, opacity: 1}}>
                    <span style={{width: 250, paddingLeft:20,}}>Price pump &lt;100%</span>
                    <input className={styles.input} style={{width: 96}} 
                        type="number" 
                        placeholder="" 
                        value={tradeConditions["diff_ex_hl_lt100_fib_percent"]} 
                        onChange={(e)=>{onChangeTradeCondition("diff_ex_hl_lt100_fib_percent", e.target.value)}}/>
                </div>

                <div className={styles.custom} style={{marginTop: 0, opacity: 1}}>
                    <span style={{width: 250,paddingLeft:20,}}>Price pump &gt;100%</span>
                    <input className={styles.input} style={{width: 96}} 
                        type="number" 
                        placeholder="" 
                        value={tradeConditions["diff_ex_hl_gt100_fib_percent"]} 
                        onChange={(e)=>{onChangeTradeCondition("diff_ex_hl_gt100_fib_percent", e.target.value)}}/>
                </div>

                <div className={styles.custom} style={{marginTop: 0, opacity: 1}}>
                    <span style={{width: 250,paddingLeft:20,}}>Price in ATH</span>
                    <input className={styles.input} style={{width: 96}} 
                        type="number" 
                        placeholder="" 
                        value={tradeConditions["price_ath_fib_percent"]} 
                        onChange={(e)=>{onChangeTradeCondition("price_ath_fib_percent", e.target.value)}}/>
                </div>

            </div>;
    }

    let priceMa200Elm = null;
    if(item.id == 3){
        priceMa200Elm = (
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, width: 386}}>
                <div style={{marginTop: 0, width: '100%'}}>
                    <span style={{width: 250, display:"inline-block"}}>Price &lt; MA200 (read only)</span>
                    <span>Off</span>
                    <Switch
                        checked={tradeConditions["price_lt_sma200_cond"]}
                        // onChange={(e)=>{onChangeTradeCondition("price_lt_sma200_cond", e.target.checked)}}
                        sx={{
                            "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                color: "#FF4A63"
                            },
                          
                            "&.MuiSwitch-root .Mui-checked": {
                                color: "#2EBC85"
                            },

                            "&.MuiSwitch-root .MuiSwitch-track":{
                                background: tradeConditions["price_lt_sma200_cond"] ? "#2EBC85" : "#FF4A63"
                            },
                        }}
                    />
                    <span>On</span>
                </div>
            </div>
        );
    }






    let wtRsiElm = null

    // if(item.id == 1 || item.id == 2 || item.id == 5 || item.id == 6){
    //     wtRsiElm = (
    //         <>
    //             <div className={styles.tf+" "+styles.first} style={{marginTop: 20}}>
    //                 <span>&nbsp;</span>
    //                 <span>WT1</span>
    //                 <span>WT2</span>
    //                 <span>TP</span>
    //                 <span>Trailing</span>
    //             </div>

    //             <div className={styles.tf}>
    //                 <span>1m</span>
    //                 <input type="number" placeholder="" value={tradeConditions["1m_wt1"]} onChange={(e)=>{onChangeTradeCondition("1m_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1m_wt2"]} onChange={(e)=>{onChangeTradeCondition("1m_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1m_tp"]} onChange={(e)=>{onChangeTradeCondition("1m_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1m_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("1m_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>3m</span>
    //                 <input type="number" placeholder="" value={tradeConditions["3m_wt1"]} onChange={(e)=>{onChangeTradeCondition("3m_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["3m_wt2"]} onChange={(e)=>{onChangeTradeCondition("3m_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["3m_tp"]} onChange={(e)=>{onChangeTradeCondition("3m_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["3m_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("3m_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>5m</span>
    //                 <input type="number" placeholder="" value={tradeConditions["5m_wt1"]} onChange={(e)=>{onChangeTradeCondition("5m_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["5m_wt2"]} onChange={(e)=>{onChangeTradeCondition("5m_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["5m_tp"]} onChange={(e)=>{onChangeTradeCondition("5m_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["5m_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("5m_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>15m</span>
    //                 <input type="number" placeholder="" value={tradeConditions["15m_wt1"]} onChange={(e)=>{onChangeTradeCondition("15m_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["15m_wt2"]} onChange={(e)=>{onChangeTradeCondition("15m_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["15m_tp"]} onChange={(e)=>{onChangeTradeCondition("15m_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["15m_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("15m_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>30m</span>
    //                 <input type="number" placeholder="" value={tradeConditions["30m_wt1"]} onChange={(e)=>{onChangeTradeCondition("30m_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["30m_wt2"]} onChange={(e)=>{onChangeTradeCondition("30m_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["30m_tp"]} onChange={(e)=>{onChangeTradeCondition("30m_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["30m_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("30m_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>1h</span>
    //                 <input type="number" placeholder="" value={tradeConditions["1h_wt1"]} onChange={(e)=>{onChangeTradeCondition("1h_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1h_wt2"]} onChange={(e)=>{onChangeTradeCondition("1h_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1h_tp"]} onChange={(e)=>{onChangeTradeCondition("1h_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1h_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("1h_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>2h</span>
    //                 <input type="number" placeholder="" value={tradeConditions["2h_wt1"]} onChange={(e)=>{onChangeTradeCondition("2h_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["2h_wt2"]} onChange={(e)=>{onChangeTradeCondition("2h_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["2h_tp"]} onChange={(e)=>{onChangeTradeCondition("2h_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["2h_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("2h_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>4h</span>
    //                 <input type="number" placeholder="" value={tradeConditions["4h_wt1"]} onChange={(e)=>{onChangeTradeCondition("4h_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["4h_wt2"]} onChange={(e)=>{onChangeTradeCondition("4h_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["4h_tp"]} onChange={(e)=>{onChangeTradeCondition("4h_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["4h_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("4h_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>6h</span>
    //                 <input type="number" placeholder="" value={tradeConditions["6h_wt1"]} onChange={(e)=>{onChangeTradeCondition("6h_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["6h_wt2"]} onChange={(e)=>{onChangeTradeCondition("6h_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["6h_tp"]} onChange={(e)=>{onChangeTradeCondition("6h_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["6h_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("6h_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>8h</span>
    //                 <input type="number" placeholder="" value={tradeConditions["8h_wt1"]} onChange={(e)=>{onChangeTradeCondition("8h_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["8h_wt2"]} onChange={(e)=>{onChangeTradeCondition("8h_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["8h_tp"]} onChange={(e)=>{onChangeTradeCondition("8h_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["8h_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("8h_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>12h</span>
    //                 <input type="number" placeholder="" value={tradeConditions["12h_wt1"]} onChange={(e)=>{onChangeTradeCondition("12h_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["12h_wt2"]} onChange={(e)=>{onChangeTradeCondition("12h_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["12h_tp"]} onChange={(e)=>{onChangeTradeCondition("12h_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["12h_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("12h_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>1d</span>
    //                 <input type="number" placeholder="" value={tradeConditions["1d_wt1"]} onChange={(e)=>{onChangeTradeCondition("1d_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1d_wt2"]} onChange={(e)=>{onChangeTradeCondition("1d_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1d_tp"]} onChange={(e)=>{onChangeTradeCondition("1d_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1d_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("1d_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>3d</span>
    //                 <input type="number" placeholder="" value={tradeConditions["3d_wt1"]} onChange={(e)=>{onChangeTradeCondition("3d_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["3d_wt2"]} onChange={(e)=>{onChangeTradeCondition("3d_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["3d_tp"]} onChange={(e)=>{onChangeTradeCondition("3d_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["3d_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("3d_tp_trailing", e.target.value)}}/>
    //             </div>
    //             <div className={styles.tf}>
    //                 <span>1w</span>
    //                 <input type="number" placeholder="" value={tradeConditions["1w_wt1"]} onChange={(e)=>{onChangeTradeCondition("1w_wt1", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1w_wt2"]} onChange={(e)=>{onChangeTradeCondition("1w_wt2", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1w_tp"]} onChange={(e)=>{onChangeTradeCondition("1w_tp", e.target.value)}}/>
    //                 <input type="number" placeholder="" value={tradeConditions["1w_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition("1w_tp_trailing", e.target.value)}}/>
    //             </div>
    //         </>
    //     );
    // }

    if(item.id == 3 || item.id == 9){
        wtRsiElm = (
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, alignItems:"start"}}>
                <div className={styles.tf+" "+styles.first} style={{marginTop: 0}}>
                    <span>&nbsp;</span>
                    <span>RSI</span>
                    <span>TP</span>
                    <span>Trailing</span>
                </div>

                {Constants.TIMEFRAMES.map((row, index) => {
                    let tf = row;
                    return (
                        <div className={styles.tf}>
                            <span>{tf}</span>
                            <input className={styles.input} type="number" placeholder="" value={tradeConditions[tf+"_rsi14"]} onChange={(e)=>{onChangeTradeCondition(tf+"_rsi14", e.target.value)}}/>
                            <input className={styles.input} type="number" placeholder="" value={tradeConditions[tf+"_tp"]} onChange={(e)=>{onChangeTradeCondition(tf+"_tp", e.target.value)}}/>
                            <input className={styles.input} type="number" placeholder="" value={tradeConditions[tf+"_tp_trailing"]} onChange={(e)=>{onChangeTradeCondition(tf+"_tp_trailing", e.target.value)}}/>
                        </div>
                    )
                })}

            </div>
        );
    }

    // categories conditions

    let categoriesElm = null;
    let categoryConditionsArr = Object.keys(categoryConditions);

    if(item.id == 3){
        categoriesElm = (
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, alignItems:"start"}}>
                <div className={styles.tf+" "+styles.first} style={{marginTop: 0}}>
                    <span style={{width: 140, textAlign:"center", paddingRight:40}}>Price Drop<br/>BF Hi D</span>
                    <span>Price ⬆︎ &lt;100%</span>
                    <span>Price ⬆︎ &gt;100%</span>
                    <span>Active</span>
                </div>

                {categoryConditionsArr.map((row, index) => {
                    let item = categoryConditions[row];
                    return (
                        <div className={styles.tf} key={item.name}>
                            <span style={{width: 140}}>{item.display}</span>
                            <input className={styles.input} type="number" placeholder="" value={item.price_diff_ex_h_lt100} onChange={(e)=>{onChangeCategoryConditions(item.name, "price_diff_ex_h_lt100", e.target.value)}}/>
                            <input className={styles.input} type="number" placeholder="" value={item.price_diff_ex_h_gt100} onChange={(e)=>{onChangeCategoryConditions(item.name, "price_diff_ex_h_gt100", e.target.value)}}/>
                            <input className={styles.input} type="number" placeholder="" value={item.active} onChange={(e)=>{onChangeCategoryConditions(item.name, "active", e.target.value)}}/>
                        </div>
                    )
                })}
            </div>
        )
    }

    if(item.id == 9){
        categoriesElm = (
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, alignItems:"start"}}>
                <div className={styles.tf+" "+styles.first} style={{marginTop: 0}}>
                    <span style={{width: 140, textAlign:"center", paddingRight:40}}></span>
                    <span>Active</span>
                </div>

                {categoryConditionsArr.map((row, index) => {
                    let item = categoryConditions[row];
                    return (
                        <div className={styles.tf} key={item.name}>
                            <span style={{width: 140}}>{item.display}</span>
                            <input className={styles.input} type="number" placeholder="" value={item.active} onChange={(e)=>{onChangeCategoryConditions(item.name, "active", e.target.value)}}/>
                        </div>
                    )
                })}
            </div>
        )
    }



    // symbols conditions
    let symbolsElm = null;
    let symbolConditionsArr = Object.keys(symbolConditions);

    for(let i = 0; i < symbolConditionsArr.length; i++){
        if(symbolConditionsArr[i].toLowerCase().indexOf(symbolFilter.toLowerCase()) === -1){
            symbolConditionsArr.splice(i, 1);
            i--;
        }
    }

    if(item.id == 3){
        symbolsElm = (
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, alignItems:"start"}}>
                <div className={styles.tf+" "+styles.first} style={{marginTop: 0}}>
                    <span style={{width: 140}}><input className={styles.input} type="text" placeholder="eg. BTC" value={symbolFilter} onChange={(e)=>{setSymbolFilter(e.target.value)}} /></span>
                    <span>Price ⬇︎<br/>BF Hi D</span>
                    <span>Ref 1</span>
                    <span>Ref 2</span>
                </div>

                {symbolConditionsArr.map((row, index) => {
                    let item = symbolConditions[row];
                    return (
                        <div className={styles.tf} key={item.name}>
                            <span style={{width: 140}}>{item.display}</span>
                            <input className={styles.input} type="number" placeholder="" value={item.pd0} onChange={(e)=>{onChangeSymbolConditions(item.name, "pd0", e.target.value)}}/>
                            <input className={styles.input} type="number" placeholder="" value={item.pd1} onChange={(e)=>{onChangeSymbolConditions(item.name, "pd1", e.target.value)}}/>
                            <input className={styles.input} type="number" placeholder="" value={item.pd2} onChange={(e)=>{onChangeSymbolConditions(item.name, "pd2", e.target.value)}}/>
                        </div>
                    )
                })}
            </div>
        )
    }

    if(item.id == 9){
        // symbolsElm = (
        //     <div className={styles.card} style={{minHeight: "auto", marginTop:20, alignItems:"start"}}>
        //         <div className={styles.tf+" "+styles.first} style={{marginTop: 0}}>
        //             <span style={{width: 140}}><input className={styles.input} type="text" placeholder="eg. BTC" value={symbolFilter} onChange={(e)=>{setSymbolFilter(e.target.value)}} /></span>
        //             <span>RSI</span>
        //         </div>

        //         {symbolConditionsArr.map((row, index) => {
        //             let item = symbolConditions[row];
        //             return (
        //                 <div className={styles.tf} key={item.name}>
        //                     <span style={{width: 140}}>{item.display}</span>
        //                     <input className={styles.input} type="number" placeholder="" value={item.rsi14} onChange={(e)=>{onChangeSymbolConditions(item.name, "rsi14", e.target.value)}}/>
        //                 </div>
        //             )
        //         })}
        //     </div>
        // )
    }

    


    return(
        <div className={styles.strategy}>

            <div className={styles.card} style={{minHeight: "auto", marginTop:20, width: 386}}>
                <div className={styles.name} style={{margin: 0}}>{item.name}</div>

                <div style={{marginTop: 0, width: '100%'}}>
                    <span style={{width: 250, display:"inline-block"}}>Active (*Master Switch)</span>
                    <span>Off</span>
                    <Switch
                        checked={tradeConditions["master_switch_buy"]}
                        onChange={(e)=>{onChangeTradeCondition("master_switch_buy", e.target.checked)}}
                        sx={{
                            "&.MuiSwitch-root .MuiSwitch-switchBase": {
                                color: "#FF4A63"
                            },
                          
                            "&.MuiSwitch-root .Mui-checked": {
                                color: "#2EBC85"
                            },

                            "&.MuiSwitch-root .MuiSwitch-track":{
                                background: tradeConditions["master_switch_buy"] ? "#2EBC85" : "#FF4A63"
                            },
                        }}
                    />
                    <span>On</span>
                </div>
            </div>

            {gainPercentElm}

            {btcDumpElm}

            {bfHiLoDiffElm}

            {priceMa200Elm}

            {wtRsiElm}

            {categoriesElm}

            {symbolsElm}
            
            <div className={styles.card} style={{minHeight: "auto", marginTop:20, width: 386}}>
                <div className={styles.actionBtn} style={{marginTop:0}} onClick={update}>Update</div>
            </div>

        </div>
    )
}
