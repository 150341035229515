import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import IcoMenu from '../../icons/IcoMenu';

import styles from './ItemIndicator.module.css';

ItemIndicator.defaultProps = {
    nr: 0,

    symbol: "",

    wt1_15m: "0",
    wt2_15m: "0",

    wt1_1h: "0",
    wt2_1h: "0",

    wt1_2h: "0",
    wt2_2h: "0",

    wt1_4h: "0",
    wt2_4h: "0",

    wt1_12h: "0",
    wt2_12h: "0",

    wt1_1d: "0",
    wt2_1d: "0",

    v_24h: "0",
    vh_24h: "0",

    lp_48h: "0",
    lp_abs: "0",

    s15m_wt_use: "0",
    s15m_gainer_hi: "0",

    last_update: "0",

    ticker: 0,
    current_vol_24h: 0,
    current_volq_24h: 0,

    fav_coin: "0",
    blacklist: "0",
    source: "manual",

    vol_m_5m: 0,
    volq_m_5m: 0,
    vol_m_15m: 0,
    volq_m_15m: 0,
};

ItemIndicator.propTypes = {
    nr: PropTypes.number,

    symbol: PropTypes.string,

    wt1_15m: PropTypes.string,
    wt2_15m: PropTypes.string,

    wt1_1h: PropTypes.string,
    wt2_1h: PropTypes.string,

    wt1_2h: PropTypes.string,
    wt2_2h: PropTypes.string,

    wt1_4h: PropTypes.string,
    wt2_4h: PropTypes.string,

    wt1_12h: PropTypes.string,
    wt2_12h: PropTypes.string,

    wt1_1d: PropTypes.string,
    wt2_1d: PropTypes.string,

    v_24h: PropTypes.string,
    vh_24h: PropTypes.string,

    lp_48h: PropTypes.string,
    lp_abs: PropTypes.string,

    s15m_wt_use: PropTypes.string,
    s15m_gainer_hi: PropTypes.string,

    last_update: PropTypes.string,

    ticker: PropTypes.number,
    current_vol_24h: PropTypes.number,
    current_volq_24h: PropTypes.number,

    fav_coin: PropTypes.string,
    blacklist: PropTypes.string,
    source: PropTypes.string,
}

function ItemIndicator(props) {

    // let colorRevenue = "#FFFFFF";
    // let revenueTxt = props.revenue;

    // if(props.revenue > 0){
    //     revenueTxt = "+"+props.revenue;
    //     colorRevenue = "#23D785";
    // }

    // if(props.revenue < 0){
    //     colorRevenue = "#E36746";
    // }
    let wt1_15m = parseInt(props.wt1_15m);
    let wt2_15m = parseInt(props.wt2_15m);

    let wt1_1h = parseInt(props.wt1_1h);
    let wt2_1h = parseInt(props.wt2_1h);

    let wt1_2h = parseInt(props.wt1_2h);
    let wt2_2h = parseInt(props.wt2_2h);

    let wt1_4h = parseInt(props.wt1_4h);
    let wt2_4h = parseInt(props.wt2_4h);

    let wt1_12h = parseInt(props.wt1_12h);
    let wt2_12h = parseInt(props.wt2_12h);

    let wt1_1d = parseInt(props.wt1_1d);
    let wt2_1d = parseInt(props.wt2_1d);

    let style15m={};
    if( (wt1_15m < -37) && (wt2_15m < -37) ){
        style15m.color = "#23D785";
    }

    let style1h={};
    if( (wt1_1h < -37) && (wt2_1h < -37) ){
        style1h.color = "#23D785";
    }

    let style2h={};
    if( (wt1_2h < -37) && (wt2_2h < -37) ){
        style2h.color = "#23D785";
    }

    let style4h={};
    if( (wt1_4h < -37) && (wt2_4h < -37) ){
        style4h.color = "#23D785";
    }

    let style12h={};
    if( (wt1_12h < -37) && (wt2_12h < -37) ){
        style12h.color = "#23D785";
    }

    let style1d={};
    if( (wt1_1d < -37) && (wt2_1d < -37) ){
        style1d.color = "#23D785";
    }

    let vol = 0;
    if(parseFloat(props.v_24h) !== 0){
        // vol = ( (parseFloat(props.v_24h) - parseFloat(props.vh_24h))*100 ) / parseFloat(props.v_24h);
        // vol = (parseFloat(props.v_24h)*100/parseFloat(props.vh_24h))-100;
        if(props.vh_24h != 0){
            vol = (parseFloat(props.current_vol_24h)*100/parseFloat(props.vh_24h))-100;
        }
        
    }
    
    vol = vol.toFixed(2);

    if(parseFloat(vol) > 0){
        vol = "+"+vol;
    }

    //for last 5m vol %
    let volM5m = 0;
    if(props.current_volq_24h !== 0 && props.volq_m_5m !== 0){
        volM5m = (props.current_volq_24h * 100 / props.volq_m_5m)-100;
        volM5m = volM5m.toFixed(2);
        if(parseFloat(volM5m) > 0){
            volM5m = "+"+volM5m;
        }
    }

    //for last 15m vol %
    let volM15m = 0;
    if(props.current_volq_24h !== 0 && props.volq_m_15m !== 0){
        volM15m = (props.current_volq_24h * 100 / props.volq_m_15m)-100;
        volM15m = volM15m.toFixed(2);
        if(parseFloat(volM15m) > 0){
            volM15m = "+"+volM15m;
        }
    }

    let favIcon = "";
    if(props.fav_coin === "1"){
        favIcon = "★";
    }

    let customStyle = {};
    if(props.blacklist == 1){
        customStyle["backgroundColor"] = "#F5465D";
    }

    let symbolStyle = {};
    if(props.source === "gainer"){
        symbolStyle["color"] = "#EFBA0C";
    }

    let lastUpdateStyle = {};
    if(props.last_update > 90){
        lastUpdateStyle["color"] = "#F5465D";
    }

    return (
        <tr style={customStyle}>
            <td className={styles.col1}>{props.nr}</td>
            <td className={styles.col2} style={symbolStyle}>{props.symbol} {favIcon}</td>
            <td className={styles.col3} style={style15m}>{wt1_15m} / {wt2_15m}</td>
            <td className={styles.col4} style={style1h}>{wt1_1h} / {wt2_1h}</td>
            {/* <td className={styles.col5} style={style2h}>{wt1_2h} / {wt2_2h}</td> */}
            <td className={styles.col6} style={style4h}>{wt1_4h} / {wt2_4h}</td>
            <td className={styles.col7} style={style12h}>{wt1_12h} / {wt2_12h}</td>
            <td className={styles.col8}>{Util.formatNumber(parseFloat(props.current_volq_24h / 1000000),2,2)} m</td>
            <td className={styles.col9}>{vol}</td>

            <td className={styles.col9}>{volM5m}</td>
            <td className={styles.col9}>{volM15m}</td>

            <td className={styles.col10}>{parseFloat(props.lp_abs)}</td>

            <td className={styles.col10}>{props.s15m_wt_use}</td>
            <td className={styles.col10}>{parseFloat(props.s15m_gainer_hi)}</td>

            <td className={styles.col11} style={lastUpdateStyle}>{Util.timeAgo(props.last_update)} ago</td>
            <td className={styles.col12}>
                <div className={styles.menu}><IcoMenu/></div>
                {props.children}
            </td>
        </tr>
    );
    
}


export default ItemIndicator;