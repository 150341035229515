import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import styles from './ItemBalanceHistory.module.css';

ItemBalanceHistory.defaultProps = {
    view_type: "table", //list
    nr_crt: 0,

    day: "",
    date: "",
    symbol: "",
    amount: 0,
    revenue: 0,
    profit: 0,
};

ItemBalanceHistory.propTypes = {
    view_type: PropTypes.string,
    nr_crt: PropTypes.number,

    day: PropTypes.string,
    date: PropTypes.string,
    symbol: PropTypes.string,
    amount: PropTypes.number,
    revenue: PropTypes.number,
    profit: PropTypes.number,
}

function ItemBalanceHistory(props) {

    let colorRevenue = "#FFFFFF";
    let revenueTxt = props.revenue;

    if(props.revenue > 0){
        revenueTxt = "+"+props.revenue;
        colorRevenue = "#23D785";
    }

    if(props.revenue < 0){
        colorRevenue = "#E36746";
    }


    let colorProfit = "#FFFFFF";
    let profitTxt = props.profit;

    if(props.profit > 0){
        profitTxt = "+"+props.profit;
        colorProfit = "#23D785";
    }

    if(props.profit < 0){
        colorProfit = "#E36746";
    }

    let date = props.date.substring(5, props.date.length-3)
    

    return (
        <div className={styles.balanceHistoryItem}>
            <div className={styles.col1}>{props.day} &nbsp; {date}</div>
            <div className={styles.col2}>{Util.formatNumber(props.amount, 0, 0)}</div>
            <div className={styles.col3} style={{color: colorRevenue}}>{revenueTxt}</div>
            <div className={styles.col4} style={{color: colorProfit}}>{profitTxt}</div>
        </div>
    );
    
    
}


export default ItemBalanceHistory;