import React, {useEffect, useState, useRef} from 'react';

import Loader from "../../components/Loader";
import ConfirmDialog from '../../components/ConfirmDialog';
import ItemIndicatorWatch from "../../components/ItemIndicatorWatch";

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Indicators.module.css';


//new
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import IcoBlock from '../../icons/IcoBlock';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
}));

function Indicators(props) {

    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const openMenu = Boolean(anchorElMenu);

    const [loading, setLoading] = useState(true);

    const [confirmBlacklistDialog, setConfirmBlacklistDialog] = useState(false);
    const [selectedSymbol, setSelectedSymbol] = useState("");

    const [watch_list, setWatchList] = useState([]);
    const [categories, setCategories] = useState({});

    const [filters, setFilters] = useState({
        "symbol": "",
        "wt1_1m": "",
        "wt2_1m": "",
        "wt1_3m": "",
        "wt2_3m": "",
        "wt1_5m": "",
        "wt2_5m": "",
        "wt1_15m": "",
        "wt2_15m": "",
        "wt1_30m": "",
        "wt2_30m": "",
        "wt1_1h": "",
        "wt2_1h": "",
        "wt1_2h": "",
        "wt2_2h": "",
        "wt1_4h": "",
        "wt2_4h": "",
        "wt1_6h": "",
        "wt2_6h": "",
        "wt1_8h": "",
        "wt2_8h": "",
        "wt1_12h": "",
        "wt2_12h": "",
        "wt1_1d": "",
        "wt2_1d": "",
        "wt1_3d": "",
        "wt2_3d": "",
        "wt1_1w": "",
        "wt2_1w": "",
        "volq_24h": "",

        "rsi14_1m": "",
        "rsi14_3m": "",
        "rsi14_5m": "",
        "rsi14_15m": "",
        "rsi14_30m": "",
        "rsi14_1h": "",
        "rsi14_2h": "",
        "rsi14_4h": "",
        "rsi14_6h": "",
        "rsi14_8h": "",
        "rsi14_12h": "",
        "rsi14_1d": "",
        "rsi14_3d": "",
        "rsi14_1w": "",

        "categories": "",
    });

    useEffect(()=>{

        document.title = "Strategy MA200";

        setLoading(true); 
        
        getData();
        
        let timer = setInterval(()=>{
            getData();
        }, 5000)
        

        return ()=>{
            clearInterval(timer);
            //console.log("unmount");
        }
    }, []);


    function getData(cb){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/indicators.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    
            
                if(json.status === "ok"){

                    let watch_list = [];

                    if(props.type === "monitor"){
                        if(json.monitor_list){
                            
                            for(let i = 0; i < json.watch_list.length; i++){
                                let symbol = json.watch_list[i].symbol;
                                if(json.monitor_list[symbol]){
                                    json.watch_list[i].monitor = json.monitor_list[symbol];
                                    watch_list.push(json.watch_list[i]);
                                }
                            }

                        }
                    }else{
                        watch_list = json.watch_list;
                    }

                    let title = props.type === "monitor" ? "Priority" : "All Coins";
                    document.title = title;

                    let categoriesObj = {};
                    for(let i = 0; i < json.categories.length; i++){
                        categoriesObj[json.categories[i].tag] = json.categories[i].display;
                    }

                    setWatchList(watch_list);
                    setCategories(categoriesObj);
                    setLoading(false); 

                    if(cb){
                        cb();
                    }
                }

            })
            .catch((error) => {
                // console.log(error);
            });
    }


    function handleFiltersChange(e, filter){
        let newFilters = {...filters};
        newFilters[filter] = e.target.value.trim();
        setFilters(newFilters);
    }


    function clearFilters(){
        let newFilters = {...filters};

        newFilters["symbol"] = "";
        newFilters["wt1_1m"] = "";
        newFilters["wt2_1m"] = "";
        newFilters["wt1_3m"] = "";
        newFilters["wt2_3m"] = "";
        newFilters["wt1_5m"] = "";
        newFilters["wt2_5m"] = "";
        newFilters["wt1_15m"] = "";
        newFilters["wt2_15m"] = "";
        newFilters["wt1_30m"] = "";
        newFilters["wt2_30m"] = "";
        newFilters["wt1_1h"] = "";
        newFilters["wt2_1h"] = "";
        newFilters["wt1_2h"] = "";
        newFilters["wt2_2h"] = "";
        newFilters["wt1_4h"] = "";
        newFilters["wt2_4h"] = "";
        newFilters["wt1_6h"] = "";
        newFilters["wt2_6h"] = "";
        newFilters["wt1_8h"] = "";
        newFilters["wt2_8h"] = "";
        newFilters["wt1_12h"] = "";
        newFilters["wt2_12h"] = "";
        newFilters["wt1_1d"] = "";
        newFilters["wt2_1d"] = "";
        newFilters["wt1_3d"] = "";
        newFilters["wt2_3d"] = "";
        newFilters["wt1_1w"] = "";
        newFilters["wt2_1w"] = "";
        newFilters["volq_24h"] = "";

        newFilters["rsi14_1m"] = "";
        newFilters["rsi14_3m"] = "";
        newFilters["rsi14_5m"] = "";
        newFilters["rsi14_15m"] = "";
        newFilters["rsi14_30m"] = "";
        newFilters["rsi14_1h"] = "";
        newFilters["rsi14_2h"] = "";
        newFilters["rsi14_4h"] = "";
        newFilters["rsi14_6h"] = "";
        newFilters["rsi14_8h"] = "";
        newFilters["rsi14_12h"] = "";
        newFilters["rsi14_1d"] = "";
        newFilters["rsi14_3d"] = "";
        newFilters["rsi14_1w"] = "";

        newFilters["categories"] = "";
        
        setFilters(newFilters);
    }

    function filterSymbols(){
        let filteredSymbols = [];

        for(let i = 0; i < watch_list.length; i++){
            if(filters["symbol"] !== ""){
                if(filters["symbol"].toLowerCase() === "*" && watch_list[i]["fav_coin"] === "1"){
                    //ok
                }else if(Util.search(filters["symbol"], watch_list[i]["symbol"])){
                    //ok
                }else{
                    continue;
                }
            }

            // 1m
            if(filters["wt1_1m"] !== ""){
                if( -parseInt(filters["wt1_1m"]) >= parseInt(watch_list[i]["wt1_1m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_1m"] !== ""){
                if( -parseInt(filters["wt2_1m"]) >= parseInt(watch_list[i]["wt2_1m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_1m"] !== ""){
                if( parseInt(filters["rsi14_1m"]) >= parseInt(watch_list[i]["rsi14_1m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 3m
            if(filters["wt1_3m"] !== ""){
                if( -parseInt(filters["wt1_3m"]) >= parseInt(watch_list[i]["wt1_3m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_3m"] !== ""){
                if( -parseInt(filters["wt2_3m"]) >= parseInt(watch_list[i]["wt2_3m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_3m"] !== ""){
                if( parseInt(filters["rsi14_3m"]) >= parseInt(watch_list[i]["rsi14_3m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

             // 5m
             if(filters["wt1_5m"] !== ""){
                if( -parseInt(filters["wt1_5m"]) >= parseInt(watch_list[i]["wt1_5m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_5m"] !== ""){
                if( -parseInt(filters["wt2_5m"]) >= parseInt(watch_list[i]["wt2_5m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_5m"] !== ""){
                if( parseInt(filters["rsi14_5m"]) >= parseInt(watch_list[i]["rsi14_5m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 15m
            if(filters["wt1_15m"] !== ""){
                if( -parseInt(filters["wt1_15m"]) >= parseInt(watch_list[i]["wt1_15m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_15m"] !== ""){
                if( -parseInt(filters["wt2_15m"]) >= parseInt(watch_list[i]["wt2_15m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_15m"] !== ""){
                if( parseInt(filters["rsi14_15m"]) >= parseInt(watch_list[i]["rsi14_15m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 30m
            if(filters["wt1_30m"] !== ""){
                if( -parseInt(filters["wt1_30m"]) >= parseInt(watch_list[i]["wt1_30m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_30m"] !== ""){
                if( -parseInt(filters["wt2_30m"]) >= parseInt(watch_list[i]["wt2_30m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_30m"] !== ""){
                if( parseInt(filters["rsi14_30m"]) >= parseInt(watch_list[i]["rsi14_30m"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 1h
            if(filters["wt1_1h"] !== ""){
                if( -parseInt(filters["wt1_1h"]) >= parseInt(watch_list[i]["wt1_1h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_1h"] !== ""){
                if( -parseInt(filters["wt2_1h"]) >= parseInt(watch_list[i]["wt2_1h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_1h"] !== ""){
                if( parseInt(filters["rsi14_1h"]) >= parseInt(watch_list[i]["rsi14_1h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 2h
            if(filters["wt1_2h"] !== ""){
                if( -parseInt(filters["wt1_2h"]) >= parseInt(watch_list[i]["wt1_2h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_2h"] !== ""){
                if( -parseInt(filters["wt2_2h"]) >= parseInt(watch_list[i]["wt2_2h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_2h"] !== ""){
                if( parseInt(filters["rsi14_2h"]) >= parseInt(watch_list[i]["rsi14_2h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 4h
            if(filters["wt1_4h"] !== ""){
                if( -parseInt(filters["wt1_4h"]) >= parseInt(watch_list[i]["wt1_4h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_4h"] !== ""){
                if( -parseInt(filters["wt2_4h"]) >= parseInt(watch_list[i]["wt2_4h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_4h"] !== ""){
                if( parseInt(filters["rsi14_4h"]) >= parseInt(watch_list[i]["rsi14_4h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 6h
            if(filters["wt1_6h"] !== ""){
                if( -parseInt(filters["wt1_6h"]) >= parseInt(watch_list[i]["wt1_6h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_6h"] !== ""){
                if( -parseInt(filters["wt2_6h"]) >= parseInt(watch_list[i]["wt2_6h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_6h"] !== ""){
                if( parseInt(filters["rsi14_6h"]) >= parseInt(watch_list[i]["rsi14_6h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 8h
            if(filters["wt1_8h"] !== ""){
                if( -parseInt(filters["wt1_8h"]) >= parseInt(watch_list[i]["wt1_8h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_8h"] !== ""){
                if( -parseInt(filters["wt2_8h"]) >= parseInt(watch_list[i]["wt2_8h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_8h"] !== ""){
                if( parseInt(filters["rsi14_8h"]) >= parseInt(watch_list[i]["rsi14_8h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 12h
            if(filters["wt1_12h"] !== ""){
                if( -parseInt(filters["wt1_12h"]) >= parseInt(watch_list[i]["wt1_12h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_12h"] !== ""){
                if( -parseInt(filters["wt2_12h"]) >= parseInt(watch_list[i]["wt2_12h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_12h"] !== ""){
                if( parseInt(filters["rsi14_12h"]) >= parseInt(watch_list[i]["rsi14_12h"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 1d
            if(filters["wt1_1d"] !== ""){
                if( -parseInt(filters["wt1_1d"]) >= parseInt(watch_list[i]["wt1_1d"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_1d"] !== ""){
                if( -parseInt(filters["wt2_1d"]) >= parseInt(watch_list[i]["wt2_1d"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_1d"] !== ""){
                if( parseInt(filters["rsi14_1d"]) >= parseInt(watch_list[i]["rsi14_1d"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 3d
            if(filters["wt1_3d"] !== ""){
                if( -parseInt(filters["wt1_3d"]) >= parseInt(watch_list[i]["wt1_3d"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_3d"] !== ""){
                if( -parseInt(filters["wt2_3d"]) >= parseInt(watch_list[i]["wt2_3d"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_3d"] !== ""){
                if( parseInt(filters["rsi14_3d"]) >= parseInt(watch_list[i]["rsi14_3d"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            // 1w
            if(filters["wt1_1w"] !== ""){
                if( -parseInt(filters["wt1_1w"]) >= parseInt(watch_list[i]["wt1_1w"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["wt2_1w"] !== ""){
                if( -parseInt(filters["wt2_1w"]) >= parseInt(watch_list[i]["wt2_1w"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["rsi14_1w"] !== ""){
                if( parseInt(filters["rsi14_1w"]) >= parseInt(watch_list[i]["rsi14_1w"]) ){
                    //ok
                }else{
                    continue;
                }
            }

            


            if(filters["volq_24h"] !== ""){
                if( parseFloat(filters["volq_24h"]) <= (parseFloat(watch_list[i]["volq_24h"])/1000000)){
                    //ok
                }else{
                    continue;
                }
            }

            if(filters["categories"] !== ""){
                if(Util.search(filters["categories"], watch_list[i]["categories"])){
                    //ok
                }else{
                    continue;
                }
            }

            filteredSymbols.push(watch_list[i]);
        }

        return filteredSymbols;
    }

    const handleMenuClick = (event, symbol) => {
        setAnchorElMenu(event.currentTarget);
        setSelectedSymbol(symbol);
    };
    const handleMenuClose = () => {
        setAnchorElMenu(null);
    };


    //blacklist
    function onBlacklistSymbol(){
        setConfirmBlacklistDialog(true);
        handleMenuClose();
    }

    function blacklistSymbol(){
        setConfirmBlacklistDialog(false);

        let selectedSymbolBlacklist = false;

        let symbolsArr = watch_list;

        for(let i = 0; i < symbolsArr.length; i++){
            let item = symbolsArr[i];
            if(item.symbol.toLowerCase() == selectedSymbol.toLowerCase()){
                if(item["blacklist"] == 1){
                    selectedSymbolBlacklist = true;
                }
            }
        }

        let lcNewBlacklist = 0;
        if(selectedSymbolBlacklist){
            lcNewBlacklist = 0;
        }else{
            lcNewBlacklist = 1;
        }
        let lcSymbol = selectedSymbol.toLowerCase();

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/symbolsBlacklistSet.php?symbol="+lcSymbol+"&blacklist="+lcNewBlacklist+"&list=a";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    getData();
                    return;
                }                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    if(loading){
        return (<Loader />);
    }
    
    let filteredSymbols = filterSymbols();    

    let selectedSymbolBlacklist = false;
    let symbolsArr = filteredSymbols;
    for(let i = 0; i < symbolsArr.length; i++){
        let item = symbolsArr[i];
        if(item.symbol.toLowerCase() == selectedSymbol.toLowerCase()){
            if(item["blacklist"] == 1){
                selectedSymbolBlacklist = true;
            }
        }
    }

    return (
        <div className={styles.root}>

                <table style={{width: "2800px"}}>
                    <caption style={{marginBottom: 32}}>
                        {props.type === "monitor" ? "Priority" : "All Coins"}
                    </caption>

                    <thead>
                        <tr className={styles.filters}>
                            <th style={{width:66}}>
                                <div className={styles.actionBtn} onClick={clearFilters}>Clear</div>
                            </th>
                            <th>
                                <div className={styles.textInput}>
                                    <input type="text" placeholder="Coin" value={filters["symbol"]} onChange={(e)=>{handleFiltersChange(e, "symbol")}} />
                                </div>
                            </th>
                            <th></th>

                            {props.type === "monitor" ? <th style={{width:120}}></th> : null}

                            <th></th>
                            <th style={{width:120}}></th>
                            <th style={{width:120}}></th>
                            <th style={{width:120}}></th>
                            {/* <th style={{width:120}}></th>
                            <th style={{width:120}}></th> */}
                            <th style={{width:120}}></th>
                            <th style={{width:120}}></th>

                            <th style={{width:80}}>
                                <div className={styles.textInput}>
                                    <input type="number" placeholder="0" value={filters["volq_24h"]} onChange={(e)=>{handleFiltersChange(e, "volq_24h")}} style={{textAlign:"right"}} />
                                </div>
                            </th>

                            <th></th>

                            {Constants.TIMEFRAMES.map((row, index) => {
                                let tf = row;
                                return (
                                    <th>
                                        <div className={styles.textInput+" "+styles.dual}>
                                            {/* <input type="number" placeholder="0" value={filters["wt1_"+tf]} onChange={(e)=>{handleFiltersChange(e, "wt1_"+tf)}} />
                                            <input type="number" placeholder="0" value={filters["wt2_"+tf]} onChange={(e)=>{handleFiltersChange(e, "wt2_"+tf)}} /> */}

                                            <input type="number" placeholder="0" value={filters["rsi14_"+tf]} onChange={(e)=>{handleFiltersChange(e, "rsi14_"+tf)}} />
                                        </div>
                                    </th>
                                )
                            })}

                            <th style={{width:21}}></th>
                            <th style={{width:80}}>
                                <div className={styles.textInput}>
                                    <input type="text" placeholder="eg. defi" value={filters["categories"]} onChange={(e)=>{handleFiltersChange(e, "categories")}} style={{textAlign:"right"}} />
                                </div>
                            </th>
                        </tr>

                        <tr>
                            <th style={{width:66}}>Nr.</th>
                            <th>Coin</th>
                            <th>Price</th>

                            {props.type === "monitor" ? <th style={{width:120}}>Date Watch (UTC)</th> : null}

                            <th>Bf 1d</th>
                            <th>Bf 15m</th>
                            <th>Bf 2h</th>
                            <th style={{width:120}}>Fib 1d</th>
                            <th style={{width:120}}>Gain</th>

                            {/* <th style={{width:120}}>Y ATH/Lo</th>
                            <th style={{width:120}}>M ATH/Lo</th> */}
                            <th style={{width:120}}>ATH</th>

                            <th style={{width:80}}>Vol 24h</th>

                            <th>i</th>

                            {Constants.TIMEFRAMES.map((row, index) => {
                                return (
                                    <th>{row}</th>
                                )
                            })}

                            <th style={{width:21}}></th>
                            <th>Tags</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSymbols.map((row, index) => {
                            // if(row.symbol === "mask"){
                            //     console.log("extremum volume 24h: " + row.ex_volq_24h);
                            // }
                            return (
                                <ItemIndicatorWatch 
                                    key={row.symbol}  
                                    nr={index+1} 
                                    categories={categories}
                                    item={row}>
                                        <div onClick={(e)=>{handleMenuClick(e, row.symbol)}} className={styles.menu}></div>
                                </ItemIndicatorWatch>
                            )
                        })}
                    </tbody>
                </table>

                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorElMenu}
                    open={openMenu}
                    onClose={handleMenuClose}>
                    <MenuItem onClick={onBlacklistSymbol} disableRipple>
                        <IcoBlock style={{marginRight: 12}}/> 
                        {selectedSymbolBlacklist ? "Remove from Black list" : "Add to Black list"}
                    </MenuItem>
                </StyledMenu>

                <ConfirmDialog 
                    open={confirmBlacklistDialog} 
                    title="Blacklist" 
                    text={(selectedSymbolBlacklist ? "Remove "+selectedSymbol.toUpperCase()+" from Blacklist ?" : "Add "+selectedSymbol.toUpperCase()+" to Blacklist ?")} 
                    okLabel={selectedSymbolBlacklist ? "Remove":"Add"}
                    cancelLabel="Cancel" 
                    onOk={blacklistSymbol} 
                    onCancel={()=>{setConfirmBlacklistDialog(false)}} /> 
            
        </div>
    );
}

export default Indicators;


