module.exports = {
    PAGE_NAME: "Automated Trading",
    // API_URL: "https://at.ptx.ro",
    API_URL: "",

    STRATEGIES: {
        // id1: { id: 1, name: "MA200+WT" },
        // id2: { id: 2, name: "MA200+WT+PRI+20h" },
        // id5: { id: 5, name: "MA200+WT+PRI+72h" },
        // id6: { id: 6, name: "MA200+WT+PRI+240h" },

        id3: { id: 3, name: "MA200+RSI (Long)" },
        // id4: { id: 4, name: "MA200+RSI+PRI+20h" },
        // id7: { id: 7, name: "MA200+RSI+PRI+72h" },
        // id8: { id: 8, name: "MA200+RSI+PRI+240h" },

        id9: { id: 9, name: "RSI (Short)" },
    },

    TIMEFRAMES: ["1m", "3m", "5m", "15m", "30m", "1h", "2h", "4h", "6h", "8h", "12h", "1d", "3d", "1w"],
}