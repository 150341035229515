import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import IcoX from '../../icons/IcoX';

import Fetch from '../../utils/Fetch';
import Constants from '../../Constants';

import styles from './SpecsInputDialog.module.css';

SpecsInputDialog.defaultProps = {
    open: false,
    onOk: null,
    onCancel: null,
    onStatus: null,

    strategy: "",
    specs: {},
    readOnly: false,
};

SpecsInputDialog.propTypes = {
    open: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    onStatus: PropTypes.func,

    strategy: PropTypes.string,
    specs: PropTypes.object,
    readOnly: PropTypes.bool,
}


const SpecsInputDialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: "transparent",
    }
}));

function SpecsInputDialog(props) {

    const [specs, setSpecs] = useState({
        initial_balance: 0,
        initial_invest: 0,
        ttp: 0,
        sl: 0,
        vol_usdt: 0,
        wt1: 0,
        wt2: 0,

        vol_usdt_2: 0,
        wt1_2: 0,
        wt2_2: 0,
    });

    useEffect(()=>{
        if(props.open){
            setSpecs(props.specs);
        }
    }, [props.specs, props.open]);


    function onChangeSpec(spec, value){
        let newSpecs = {...specs};
        newSpecs[spec] = value;
        setSpecs(newSpecs);
    }

    function onUpdate(){
        onOk();

        let newSpecs = JSON.stringify(specs);

        let url = Constants.API_URL+"/api/specsUpdate.php?strategy="+props.strategy+"&specs="+newSpecs;
  
        Fetch.request(url)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    onStatus("success");
                    return;
                } 

                onStatus("error");
            })
            .catch((error) => {
                onStatus("error");
            });
    }

    function onOk(){
        if(props.onOk){
            props.onOk();
        }
    }

    function onCancel(){
        if(props.onCancel){
            props.onCancel();
        }
    }

    function onStatus(status){
        let cbObject = {
            status: status,
            specs: specs
        }
        if(props.onStatus){
            props.onStatus(cbObject);
        }
    }

    let additionalVolWt = null;
    if(props.strategy === "4h2"){
        additionalVolWt = (
            <div>
                <div className={styles.textInput} style={{marginTop: 20}}>
                    <div className={styles.leftLabel}>&gt;</div>
                    <div className={styles.rightLabel}>Volume 24h $ (mil)</div>
                    <input type="number" placeholder="0.00" value={specs.vol_usdt_2} onChange={(e)=>{onChangeSpec("vol_usdt_2", e.target.value)}} />
                </div> 
                <div className={styles.textInput} style={{marginTop: 10, float: "left", width: "48.5%"}}>
                    <div className={styles.leftLabel}>-</div>
                    <div className={styles.rightLabel}>WT1</div>
                    <input type="number" placeholder="0" value={specs.wt1_2} onChange={(e)=>{onChangeSpec("wt1_2", e.target.value)}} />
                </div>
                <div className={styles.textInput} style={{marginTop: 10, float: "right", width: "48.5%"}}>
                    <div className={styles.leftLabel}>-</div>
                    <div className={styles.rightLabel}>WT2</div>
                    <input type="number" placeholder="0" value={specs.wt2_2} onChange={(e)=>{onChangeSpec("wt2_2", e.target.value)}} />
                </div> 
            </div>
        );
    }


    return (

        <SpecsInputDialogStyled
            open={props.open}
            onClose={onCancel}>

            <div className={styles.content}>
                <div className={styles.head}>
                    <div className={styles.title}>Bot Settings</div>
                    <div className={styles.close} onClick={onCancel}>
                        <IcoX fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyCenter}>
                        
                        <div className={styles.specs}>
                            <div className={styles.textInput} style={{marginTop: 0}}>
                                <div className={styles.leftLabel}></div>
                                <div className={styles.rightLabel}>$ to Invest</div>
                                <input type="number" placeholder="0" value={specs.initial_invest} onChange={(e)=>{onChangeSpec("initial_invest", e.target.value)}} />
                            </div>
                            <div className={styles.textInput} style={{marginTop: 10, float: "left", width: "48.5%"}}>
                                <div className={styles.leftLabel}>%</div>
                                <div className={styles.rightLabel}>TTP</div>
                                <input type="number" placeholder="0.0" value={specs.ttp} onChange={(e)=>{onChangeSpec("ttp", e.target.value)}} />
                            </div>
                            <div className={styles.textInput} style={{marginTop: 10, float: "right", width: "48.5%"}}>
                                <div className={styles.leftLabel}>%</div>
                                <div className={styles.rightLabel}>SL</div>
                                <input type="number" placeholder="0.0" value={specs.sl} onChange={(e)=>{onChangeSpec("sl", e.target.value)}} />
                            </div>



                            <div className={styles.textInput} style={{marginTop: 20}}>
                                <div className={styles.leftLabel}>&gt;</div>
                                <div className={styles.rightLabel}>Volume 24h $ (mil)</div>
                                <input type="number" placeholder="0.00" value={specs.vol_usdt} onChange={(e)=>{onChangeSpec("vol_usdt", e.target.value)}} />
                            </div> 
                            <div className={styles.textInput} style={{marginTop: 10, float: "left", width: "48.5%"}}>
                                <div className={styles.leftLabel}>-</div>
                                <div className={styles.rightLabel}>WT1</div>
                                <input type="number" placeholder="0" value={specs.wt1} onChange={(e)=>{onChangeSpec("wt1", e.target.value)}} />
                            </div>
                            <div className={styles.textInput} style={{marginTop: 10, float: "right", width: "48.5%"}}>
                                <div className={styles.leftLabel}>-</div>
                                <div className={styles.rightLabel}>WT2</div>
                                <input type="number" placeholder="0" value={specs.wt2} onChange={(e)=>{onChangeSpec("wt2", e.target.value)}} />
                            </div> 

                            {additionalVolWt}
                        </div>

                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.btnCancel} onClick={onCancel}>Cancel</div>
                    <div className={styles.btnOk} onClick={onUpdate}>Update</div>
                </div>
            </div>

        </SpecsInputDialogStyled>

    );
}

export default SpecsInputDialog;