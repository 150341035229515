import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import IcoX from '../../icons/IcoX';
import IcoArrow from '../../icons/IcoArrow';

import Loader from "../../components/Loader";
import Util, { capitalizeFirstLetter } from '../../utils/Util';
import Fetch from '../../utils/Fetch';
import Constants from '../../Constants';

import ConfirmDialog from '../ConfirmDialog';

import styles from './TTPDialog.module.css';

const PROFIT_PERCENT = "PROFIT_PERCENT";
const EXACT_PRICE = "EXACT_PRICE";

TTPDialog.defaultProps = {
    open: false,
    strategy: "0",
    coin1: "COIN1",
    coin2: "COIN2",

    buy_steps: [],
    buy_count: 0,
    price_precision: 2,
    price_move: 0.01,

    buy_price_arr: [],
    buy_amount_arr: [],

    ttp_type: PROFIT_PERCENT,
    exact_price: 0,
    profit_percent: 0,
    stop_loss: 0,

    onOk: null,
    onCancel: null,

    sellType: "MARKET",
};

TTPDialog.propTypes = {
    open: PropTypes.bool,
    strategy: PropTypes.string,
    coin1: PropTypes.string,
    coin2: PropTypes.string,

    buy_steps: PropTypes.array,
    buy_count: PropTypes.number,
    price_precision: PropTypes.number,
    price_move: PropTypes.number,

    buy_price_arr: PropTypes.array,
    buy_amount_arr: PropTypes.array,

    ttp_type: PropTypes.string,
    exact_price: PropTypes.number,
    profit_percent: PropTypes.number,
    stop_loss: PropTypes.number,

    onOk: PropTypes.func,
    onCancel: PropTypes.func,

    sellType: PropTypes.string,
}

const TradeDialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: "transparent",
    }
}));

function TTPDialog(props) {

    const [loading, setLoading] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const [type, setType] = useState(PROFIT_PERCENT);

    const profitElm = useRef(null);
    const profitLast = useRef({
        PROFIT_PERCENT: 0,
        EXACT_PRICE: 0,
    });
    const [profit, setProfit] = useState(0);
    const [stopLoss, setStopLoss] = useState(0);
    const [errorText, setErrorText] = useState("");


    useEffect(()=>{
        return ()=>{
            //console.log("unmount");
        }
    }, []);
    

    useEffect(()=>{
        if(props.open){
            setType(props.ttp_type);

            if(props.ttp_type === PROFIT_PERCENT){
                setProfit(props.profit_percent);
            }

            if(props.ttp_type === EXACT_PRICE){
                setProfit(props.exact_price);
            }

            profitLast.current.PROFIT_PERCENT = props.profit_percent;
            profitLast.current.EXACT_PRICE = props.exact_price;

            setStopLoss(props.stop_loss);
            
        }
    }, [props.open]);

    function onOk(){
        setConfirmDialog(false);
        setTimeout(()=>{
            if(props.onOk){
                props.onOk({
                    ttp_type: type,
                    profit: parseFloat(profit),
                    stop_loss: parseFloat(stopLoss),
                });
            }
        }, 300)
    }

    function onCancel(){
        if(props.onCancel){
            props.onCancel();
        }
    }

    function onSave(){

        //clear error
        setErrorText("");

        //check error
        let hasError = false;

        if(isNaN(parseFloat(profit))){
            hasError = true;
        }

        if(isNaN(parseFloat(stopLoss))){
            hasError = true;
        }

        //show error
        if(hasError){
            setErrorText("Error");
            return;
        }

        setConfirmDialog(true);
    }

    function onChangeType(type){
        setType(type);
        setProfit(profitLast.current[type]);
        profitElm.current.focus();
    }

    //profit
    function onChangeProfit(e){
        let value = parseFloat(e.target.value);
        let newValue = 0;

        if(isNaN(value)){
            newValue = e.target.value;
        }else if(value < 0){
            newValue = 0;
        }else{
            newValue = e.target.value;
        }

        profitLast.current[type] = newValue;
        setProfit(newValue);
    }

    function onChangeProfitPlus(){
        let value = parseFloat(profit);
        if(isNaN(value)){
            value = 0;
        }

        if(type === EXACT_PRICE){
            value += parseFloat(props.price_move);
            value = Util.round(value, props.price_precision);
        }else{        
            value += 0.1;
            value = Util.round(value, 2);
        }


        profitLast.current[type] = value;
        setProfit(value);
    }

    function onChangeProfitMinus(){
        let value = parseFloat(profit);
        if(isNaN(value)){
            value = 0;
        }

        if(type === EXACT_PRICE){
            value -= parseFloat(props.price_move);
            value = Util.round(value, props.price_precision);
        }else{        
            value -= 0.1;
            value = Util.round(value, 2);
        }
        // if(value < 0){
        //     value = 0;
        // }
        profitLast.current[type] = value;
        setProfit(value);
    }

    //stop loss
    function onChangeStopLoss(e){
        let value = parseFloat(e.target.value);
        let newValue = 0;

        if(isNaN(value)){
            newValue = e.target.value;
        }else if(value < 0){
            newValue = 0;
        }else{
            newValue = e.target.value;
        }

        setStopLoss(newValue);
    }

    function onChangeStopLossPlus(){
        let value = parseFloat(stopLoss);
        if(isNaN(value)){
            value = 0;
        }

        value += 0.1;
        value = Util.round(value, 2);
        setStopLoss(value);
    }

    function onChangeStopLossMinus(){
        let value = parseFloat(stopLoss);
        if(isNaN(value)){
            value = 0;
        }

        value -= 0.1;
        value = Util.round(value, 2);
        // if(value < 0){
        //     value = 0;
        // }
        setStopLoss(value);
    }

    function getProfitInfo(){
        let result = {
            profit_info: 0,
            stop_loss_info: 0,
        }

        let totalInvested = 0;
        let totalAmount = 0;
        for(let i = 0; i < props.buy_price_arr.length; i++){
            let price = parseFloat(props.buy_price_arr[i]);
            let amount = parseFloat(props.buy_amount_arr[i]);

            totalInvested = totalInvested + (price*amount);
            totalAmount = totalAmount + amount;
        }

        let lcProfit = parseFloat(profit);
        if(isNaN(lcProfit)){
            lcProfit = 0;
        }

        let lcStopLoss = parseFloat(stopLoss);
        if(isNaN(lcStopLoss)){
            lcStopLoss = 0;
        }

        if(type === PROFIT_PERCENT){
            let breakEven = totalInvested / totalAmount;
            let targetPrice = breakEven + (lcProfit / 100 * breakEven);
            targetPrice = Util.formatNumber(targetPrice, 0, props.price_precision);

            let stopLossPrice = parseFloat(targetPrice) - (lcStopLoss/100*parseFloat(targetPrice));
            stopLossPrice = Util.formatNumber(stopLossPrice, 0, props.price_precision);

            result.profit_info = targetPrice;
            result.stop_loss_info = stopLossPrice;
        }

        if(type === EXACT_PRICE){
            let win = totalAmount*lcProfit;
            let newProfit = ((win*100)/totalInvested)-100;
            newProfit = Util.formatNumber(newProfit, 0, 2);
            if(newProfit > 0){
                newProfit = "+"+newProfit;
            }

            let stopLossPrice = lcProfit - (lcStopLoss/100*lcProfit);
            stopLossPrice = Util.formatNumber(stopLossPrice, 0, props.price_precision);

            result.profit_info = newProfit+"%";
            result.stop_loss_info = stopLossPrice;
        }


        return result;

    }

    let loader = null;
    if(loading){
        loader = (<div className={styles.loading}><Loader /></div>);
    }

    let typeCss = {
        EXACT_PRICE: "",
        PROFIT_PERCENT: "",
    }
    typeCss[type] = styles.active;

    let profitLabel = "";
    let profitInfo = "";
    let confirmTxt = "Set ";
    if(type === PROFIT_PERCENT){
        profitLabel = "Activate at Profit %";
        profitInfo = props.coin2+" price est:"
        confirmTxt += "profit percent: "+profit+" % ";
    }

    if(type === EXACT_PRICE){
        profitLabel = "Activate at "+props.coin2;
        profitInfo = "Profit percent est:"
        confirmTxt += "exact price: "+profit+" "+props.coin2+" ";
    }

    let profitInfoValue = getProfitInfo();

    confirmTxt += "with stop loss: "+stopLoss+" % ?";

    let ttpTxt = "TTP";
    if(props.sellType === "LIMIT"){
        ttpTxt = "TP";
    }

    return (

        <TradeDialogStyled
            open={props.open}
            onClose={onCancel}>

            <div className={styles.content}>
                <div className={styles.head}>
                    <div className={styles.title}>{props.sellType === "MARKET" ? "Trailing Target Price" : "Target Price"} for {props.coin1}</div>
                    <div className={styles.close} onClick={onCancel}>
                        <IcoX fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyCenter}>

                        <div className={styles.switchInput} style={{marginTop: 10}}>
                            <div className={styles.left+" "+typeCss[EXACT_PRICE]} onClick={()=>{onChangeType(EXACT_PRICE)}}>Exact Price</div>
                            <div className={styles.right+" "+typeCss[PROFIT_PERCENT]} onClick={()=>{onChangeType(PROFIT_PERCENT)}}>Profit Percent</div>
                        </div>

                        <div className={styles.item} style={{marginTop: 16}}>
                            <div className={styles.textInput+" "}>
                                <div className={styles.leftLabel}>{profitLabel}</div>
                                <input ref={profitElm} type="number" value={profit} onChange={onChangeProfit} />

                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={onChangeProfitPlus}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={onChangeProfitMinus}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.inputInfo} style={{marginTop: 12}}>
                                <div className={styles.left}>{profitInfo}</div>
                                <div className={styles.right}>{profitInfoValue.profit_info}</div>
                            </div>
                        </div>

                        {props.sellType === "MARKET" ? (<div className={styles.item} style={{marginTop: 30}}>
                            <div className={styles.textInput+" "}>
                                <div className={styles.leftLabel}>Stop loss %</div>
                                <input type="number" value={stopLoss} onChange={onChangeStopLoss} />

                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={onChangeStopLossPlus}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={onChangeStopLossMinus}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.inputInfo} style={{marginTop: 12}}>
                                <div className={styles.left}>{true ? props.coin2+" price est:" : ""}</div>
                                <div className={styles.right}>{profitInfoValue.stop_loss_info}</div>
                            </div>
                        </div>) : null}

                        <div className={styles.errorText} style={{marginTop: 16}}>{errorText}</div>

                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.btnCancel} onClick={onCancel}>Cancel</div>
                    <div className={styles.btnOk} onClick={onSave}>Set new {ttpTxt}</div>
                </div>

                {loader}
            </div>

            <ConfirmDialog open={confirmDialog} title={"Set new "+ttpTxt+" ?"} text={confirmTxt} okLabel="OK" cancelLabel="Cancel" onOk={onOk} onCancel={()=>{setConfirmDialog(false)}} />  

        </TradeDialogStyled>

    );
}


export default TTPDialog;