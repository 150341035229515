import React from 'react';
import PropTypes from 'prop-types';

import styles from './ItemCollapsible.module.css';

ItemCollapsible.defaultProps = {
    collapsed: true,
    maxHeight: 0,
    visibleChildren: null,
    collapsibleChildren: null,
};

ItemCollapsible.propTypes = {
    collapsed: PropTypes.bool,
    maxHeight: PropTypes.number,
    visibleChildren: PropTypes.node,
    collapsibleChildren: PropTypes.node,
}

function ItemCollapsible(props) {
    let rootClass =  styles.root + " " +props.className;

    let maxHeight = props.maxHeight;
    if(props.collapsed){
        maxHeight = 0;
    }

    return (
        <div className={rootClass}>

            <div 
                className={styles.visibleContent}>
                {props.visibleChildren}
            </div>

            <div 
                className={styles.collapsibleContent} 
                style={{maxHeight: maxHeight}}>
                    {props.collapsibleChildren}
            </div>

        </div>
    );
}


export default ItemCollapsible;