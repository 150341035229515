import React, {useEffect, useState, useRef} from 'react';
import {Link, useParams, useHistory} from "react-router-dom";

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import Loader from "../../components/Loader";
import ItemBalanceHistory from "../../components/ItemBalanceHistory";

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import IcoX from '../../icons/IcoX';

import styles from './PaperTrades.module.css';

const puDayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const TradeVariablesDialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: "transparent",
    }
}));

function PaperTrades(props) {

    const params = useParams();
    const history = useHistory()

    const [loading, setLoading] = useState(false);
    const [paperTrades, setPaperTrades] = useState([]);

    const [sort_by, setSortBy] = useState("");
    const [sort_type, setSortType] = useState("");

    const [filters, setFilters] = useState({
        "symbol": "",
    });

    const tickers = useRef({});

    const [tradeVariablesDialogOpen, setTradeVariablesDialogOpen] = useState(false);
    const [tradeSlot, setTradeSlot] = useState(null);

    const [strategy, setStrategy] = useState(null);

    useEffect(()=>{

        if(Constants.STRATEGIES["id"+params.strategy_id]){
            setStrategy(Constants.STRATEGIES["id"+params.strategy_id]);
        }else{
            history.replace("/");
        }

        return ()=>{
            //console.log("unmount");
        }
    }, [params.strategy_id]);

    useEffect(()=>{
        if(strategy == null){
            return;
        }

        let title = "PT ";
        if(props.status === "active"){
            title += "Active "
        }else{
            title += "Finished "
        }
        title += strategy.name;

        document.title = title;

        getTickers(()=>{
            getData();
        });

        return ()=>{
            //console.log("unmount");
        }
    }, [props.status, strategy]);

    function getData(cb){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/paperTrades.php?status="+props.status+"&strategy_id="+strategy.id;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {   
                
                let newPaperTrades = [];

                if(props.status === "active" && json.paper_tradings != null){
                    let paperTradings = Object.values(json.paper_tradings);
                    paperTradings.sort((b, a) => {
                        if (a.date_buy < b.date_buy) {
                          return -1;
                        }
                        if (a.date_buy > b.date_buy) {
                          return 1;
                        }
                      
                        return 0;
                    });

                    for(let i = 0; i < paperTradings.length; i++){
                        let symbol = paperTradings[i].symbol;
                        let coin = paperTradings[i].coin1;
                        let price_now = tickers.current[symbol] ? tickers.current[symbol] : 0;
                        let profit = (price_now*100/parseFloat(paperTradings[i].price_buy))-100;
                        
                        if(strategy.id == 9){
                            profit = -profit;
                        }

                        let tpp = parseFloat(paperTradings[i].tp_percent);
                        let tp = parseFloat(paperTradings[i].price_buy) + (tpp/100*parseFloat(paperTradings[i].price_buy));

                        if(strategy.id == 9){
                            tp = parseFloat(paperTradings[i].price_buy) - (tpp/100*parseFloat(paperTradings[i].price_buy));
                        }
                        
                        tp = parseFloat(tp.toFixed(8));

                        let date_buy_arr = paperTradings[i].date_buy.split(" ");
                        let date_buy = date_buy_arr[0]+"T"+date_buy_arr[1]+".000+00:00";

                        let nowTs = Date.now()/1000;
                        let buyTs = Date.parse(date_buy)/1000;
                        let trade_time = nowTs-buyTs;

                        newPaperTrades.push({
                            id_slot: paperTradings[i].id_slot,
                            symbol: symbol,
                            coin: coin,
                            timeframe: paperTradings[i].interval,
                            price_highest: parseFloat(paperTradings[i].price_highest),
                            price_lowest: parseFloat(paperTradings[i].price_lowest),
                            price_buy: parseFloat(paperTradings[i].price_buy),
                            price_sell: parseFloat(paperTradings[i].price_sell),
                            date_buy: paperTradings[i].date_buy,
                            date_sell: paperTradings[i].date_sell,
                            trade_time: trade_time,

                            price_now: price_now,
                            profit: profit,

                            tpp: tpp,
                            tp: tp,
                            spp: paperTradings[i].trail_sell_price_percent,
                        });
                    }
                }

                if(props.status === "finished" && json.paper_tradings.length > 0){
                    let paperTradings = json.paper_tradings;
                    paperTradings.sort((b, a) => {
                        if (a.date_buy < b.date_buy) {
                          return -1;
                        }
                        if (a.date_buy > b.date_buy) {
                          return 1;
                        }
                      
                        return 0;
                    });

                    for(let i = 0; i < paperTradings.length; i++){
                        let symbol = paperTradings[i].symbol;
                        let coin = paperTradings[i].coin1;
                        let price_now = tickers.current[symbol] ? tickers.current[symbol] : 0;
                        let profit = (parseFloat(paperTradings[i].price_sell)*100/parseFloat(paperTradings[i].price_buy))-100;

                        if(strategy.id == 9){
                            profit = -profit;
                        }

                        let tpp = parseFloat(paperTradings[i].tp_percent);
                        let tp = parseFloat(paperTradings[i].price_buy) + (tpp/100*parseFloat(paperTradings[i].price_buy));

                        if(strategy.id == 9){
                            tp = parseFloat(paperTradings[i].price_buy) - (tpp/100*parseFloat(paperTradings[i].price_buy));
                        }

                        tp = parseFloat(tp.toFixed(8));

                        let sellTs = Date.parse(paperTradings[i].date_sell)/1000;
                        let buyTs = Date.parse(paperTradings[i].date_buy)/1000;
                        let trade_time = sellTs-buyTs;

                        newPaperTrades.push({
                            id_slot: paperTradings[i].id_slot,
                            symbol: symbol,
                            coin: coin,
                            timeframe: paperTradings[i].timeframe,
                            price_highest: parseFloat(paperTradings[i].price_highest),
                            price_lowest: parseFloat(paperTradings[i].price_lowest),
                            price_buy: parseFloat(paperTradings[i].price_buy),
                            price_sell: parseFloat(paperTradings[i].price_sell),
                            date_buy: paperTradings[i].date_buy,
                            date_sell: paperTradings[i].date_sell,
                            trade_time: trade_time,

                            price_now: price_now,
                            profit: profit,

                            tpp: tpp,
                            tp: tp,
                            spp: 0,
                        });
                    }
                }

                setPaperTrades(newPaperTrades);
                setLoading(false); 

                if(cb){
                    cb();
                }
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function getTickers(cb){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/getTickers.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    
            
                if(json.tickers !== null){
                    tickers.current = {};

                    for(let i = 0; i < json.tickers.length; i++){
                        // if(json.tickers[i].symbol.endsWith("BUSD")){
                        //     tickers.current[json.tickers[i].symbol] = parseFloat(json.tickers[i].price);
                        // }
                        tickers.current[json.tickers[i].symbol] = parseFloat(json.tickers[i].price);
                    }
                }

                if(cb){
                    cb();
                }
            })
            .catch((error) => {
                // console.log(error);
                if(cb){
                    cb();
                }
            });
    }

    function sortByCol(col){
        if(sort_by === col){
            if(sort_type === "asc"){
                setSortType("desc");
            }

            if(sort_type === "desc"){
                setSortBy("");
                setSortType("");
            }
        }else{
            setSortBy(col);
            setSortType("asc");
        }
    }

    function sortItems(newPaperTrades){
        if(sort_by === "" && sort_type === ""){
            return newPaperTrades;
        }

        if(sort_by !== ""){
            if(sort_type === "asc"){
                newPaperTrades.sort((a, b) => {
                    if (a[sort_by] < b[sort_by]) {
                        return -1;
                    }
                    if (a[sort_by] > b[sort_by]) {
                        return 1;
                    }
                  
                    return 0;
                });
            }

            if(sort_type === "desc"){
                newPaperTrades.sort((b, a) => {
                    if (a[sort_by] < b[sort_by]) {
                        return -1;
                    }
                    if (a[sort_by] > b[sort_by]) {
                        return 1;
                    }
                  
                    return 0;
                });
            }
        }

        return newPaperTrades;
    }

    function handleFiltersChange(e, filter){
        let newFilters = {...filters};
        newFilters[filter] = e.target.value.trim();
        setFilters(newFilters);
    }

    function clearFilters(){
        let newFilters = {...filters};

        newFilters["symbol"] = "";
        
        setFilters(newFilters);
    }

    function filterItems(paperTrades){
        let newPaperTrades = [];

        for(let i = 0; i < paperTrades.length; i++){
            if(filters["symbol"] !== ""){
                if(Util.search(filters["symbol"], paperTrades[i]["coin"])){
                    //ok
                }else{
                    continue;
                }
            }

            newPaperTrades.push(paperTrades[i]);
        }

        return newPaperTrades;
    }

    function onSymbolClick(row){
        if(props.status !== "active"){
            return;
        }
        
        setTradeSlot(row);
        setTradeVariablesDialogOpen(true);
    }

    function onCloseTradeVariablesDialog(){
        setTradeVariablesDialogOpen(false);
    }


    if(loading){
        return (<Loader />);
    }

    let title = "";
    if(props.status === "active"){
        title = "Paper Trading - Active";
    }

    if(props.status === "finished"){
        title = "Paper Trading - Finished";
    }

    title = document.title;

    // console.log(paperTrades);
    // console.log(tickers);

    let paperTradesFiltered = filterItems(paperTrades);  
    let paperTradesSorted = sortItems(paperTradesFiltered);

    return (
        <div className={styles.root}>
            <table style={{width: "1300px"}}>
                <caption style={{marginBottom: 32}}>
                    {title}
                </caption>

                <thead>
                    <tr className={styles.filters}>
                        <th style={{width:66}}>
                            <div className={styles.actionBtn} onClick={clearFilters}>Clear</div>
                        </th>
                        <th>
                            <div className={styles.textInput}>
                                <input type="text" placeholder="Coin" value={filters["symbol"]} onChange={(e)=>{handleFiltersChange(e, "symbol")}} />
                            </div>
                        </th>
                    </tr>

                    <tr>
                        <th style={{width:50}}>Nr.</th>
                        <th style={{width:100}} onClick={()=>{sortByCol("coin")}}>Coin {sort_by === "coin" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>
                        <th style={{width:50}} onClick={()=>{sortByCol("timeframe")}}>TF {sort_by === "timeframe" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>

                        <th style={{width:100}} onClick={()=>{sortByCol("profit")}}>Profit % {sort_by === "profit" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>
                        <th style={{width:100}} onClick={()=>{sortByCol("price_now")}}>Price now $ {sort_by === "price_now" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>
                        <th style={{width:100}} onClick={()=>{sortByCol("price_buy")}}>Buy $ {sort_by === "price_buy" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>

                        <th style={{width:70}} onClick={()=>{sortByCol("tpp")}}>TP {sort_by === "tpp" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>
                        <th style={{width:100}} onClick={()=>{sortByCol("tp")}}>TP $ {sort_by === "tp" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>

                        <th style={{width:100}} onClick={()=>{sortByCol("price_highest")}}>Max Price {sort_by === "price_highest" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>
                        <th style={{width:100}} onClick={()=>{sortByCol("price_lowest")}}>Min Price {sort_by === "price_lowest" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>

                        <th style={{width:150}} onClick={()=>{sortByCol("date_buy")}}>Date buy {sort_by === "date_buy" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>
                        <th style={{width:150}} onClick={()=>{sortByCol("date_sell")}}>Date sell {sort_by === "date_sell" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>
                        <th onClick={()=>{sortByCol("trade_time")}}>Trade time {sort_by === "trade_time" ? (sort_type === "asc" ? <ArrUp /> : <ArrDown />) : null}</th>
                    </tr>
                </thead>
                <tbody>
                    {paperTradesSorted.map((row, index) => {
                        return (
                            <Item 
                                key={row.id_slot}  
                                nr={index+1} 
                                item={row}
                                onSymbolClick={()=>{onSymbolClick(row)}}
                            >
                                
                            </Item>
                        )
                    })}
                </tbody>
            </table>

            <TradeVariablesDialog 
                tradeSlot={tradeSlot}
                open={tradeVariablesDialogOpen} 
                onClose={onCloseTradeVariablesDialog} />
        </div>
    );
}

export default PaperTrades;


function Item(props){
    let item = props.item;

    let tradeTimeTxt = formatSeconds(item.trade_time);

    let profitTxt = 0;
    let profitStyle = {};
    if(item.profit > 0){
        profitTxt = "+"+item.profit.toFixed(2);
        profitStyle.color = "rgb(35, 215, 133)";
    }else{
        profitTxt = item.profit.toFixed(2);
    }

    let sppTxt = "";
    if(item.spp > 0){
        sppTxt = "-"+item.spp+"%";
    }

    return (
        <tr>
            <td className={styles.col1}>{props.nr}</td>
            <td className={styles.col2} onClick={props.onSymbolClick}>{item.coin}</td>
            <td className={styles.col3}>{item.timeframe}</td>

            <td className={styles.col3} style={profitStyle}>{profitTxt}</td>
            <td className={styles.col3}>{item.price_now}</td>
            <td className={styles.col3}>{item.price_buy}</td>

            <td className={styles.col3}>{item.tpp}% {sppTxt}</td>
            <td className={styles.col3}>{item.tp}</td>

            <td className={styles.col3}>{item.price_highest}</td>
            <td className={styles.col3}>{item.price_lowest}</td>

            <td className={styles.col3}>{item.date_buy}</td>
            <td className={styles.col3}>{item.date_sell}</td>
            <td className={styles.col3}>{tradeTimeTxt}</td>
        </tr>
    )
}

function ArrUp(props){
    return(<span className={styles.arrUp}></span>);
}

function ArrDown(props){
    return(<span className={styles.arrDown}></span>);
}


function TradeVariablesDialog(props){

    const [loading, setLoading] = useState(false);
    const [paper_tradings, setPaperTradings] = useState(null);
    const [cross_wt, setCrossWt] = useState(null);

    useEffect(()=>{
        return ()=>{
            //console.log("unmount");
        }
    }, []);

    useEffect(()=>{
        setLoading(true);
        
        if(props.open){
            getData();
        }
    }, [props.open]);

    function onClose(){
        if(props.onClose){
            props.onClose();
        }
    }

    function getData(){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/getSlotVariables.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {   

                // console.log(json);

                // json.paper_tradings = JSON.parse(json.paper_tradings);
                // json.cross_wt = JSON.parse(json.cross_wt);

                let slot = tradeSlot.symbol+"_"+tradeSlot.timeframe;

                let one_paper_trading = null;
                if(json.paper_tradings){
                    if(json.paper_tradings[slot]){
                        one_paper_trading = json.paper_tradings[slot];
                    }
                }

                let one_cross_wt = null;
                if(json.cross_wt){
                    if(json.cross_wt[slot]){
                        one_cross_wt = json.cross_wt[slot];
                    }
                }

                setPaperTradings(one_paper_trading);
                setCrossWt(one_cross_wt);
                setLoading(false); 
            })
            .catch((error) => {
                // console.log(error);
                setLoading(false); 
            });
    }

    let loader = null;
    if(loading){
        loader = (<div className={styles.loading}><Loader /></div>);
    }

    let tradeSlot = props.tradeSlot;
    if(!tradeSlot){
        return null;
    }

    return (
        <TradeVariablesDialogStyled
            open={props.open}>
            <div className={styles.contentTradeVariables}>
                <div className={styles.head}>
                    <div className={styles.title}>Trade variables for {tradeSlot.coin} {tradeSlot.timeframe}</div>
                    <div className={styles.close} onClick={onClose}>
                        <IcoX fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyCenter}>
                        <div>Paper Trade</div>
                        <pre>{paper_tradings !== null ? JSON.stringify(paper_tradings, null, 2) : null}</pre>

                        <div style={{marginTop: 20}}>Cross WT</div>
                        <pre>{cross_wt !== null ? JSON.stringify(cross_wt, null, 2) : null}</pre>
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.btnCancel} onClick={onClose}>Close</div>
                </div>

                {loader}
            </div>
        </TradeVariablesDialogStyled>
    );
}


function formatSeconds(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = "00d:";
    if(d > 0){
        if(d < 10){
            dDisplay = "0"+d+"d:";
        }else{
            dDisplay = d+"d:";
        }
    }

    var hDisplay = "00h:";
    if(h > 0){
        if(h < 10){
            hDisplay = "0"+h+"h:";
        }else{
            hDisplay = h+"h:";
        }
    }

    var mDisplay = "00m:";
    if(m > 0){
        if(m < 10){
            mDisplay = "0"+m+"m:";
        }else{
            mDisplay = m+"m:";
        }
    }

    var sDisplay = "00s";
    if(s > 0){
        if(s < 10){
            sDisplay = "0"+s+"s";
        }else{
            sDisplay = s+"s";
        }
    }

    if(m == 0 && h == 0 && d == 0){
        dDisplay = "";
        hDisplay = "";
        mDisplay = "";
    }

    if(h == 0 && d == 0){
        dDisplay = "";
        hDisplay = "";
    }

    if(d == 0){
        dDisplay = "";
    }

    return dDisplay + hDisplay + mDisplay + sDisplay;
}

