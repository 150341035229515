import PropTypes from 'prop-types';

IcoBuySteps.defaultProps = {
    fill: "#FFFFFF",
};

IcoBuySteps.propTypes = {
    fill: PropTypes.string,
}

function IcoBuySteps(props){
    return(
        <svg {...props} width="24px" height="14px" viewBox="0 0 24 14">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-54.000000, -84.000000)" fill={props.fill} fillRule="nonzero">
                    <path d="M77.5,97 L77.5,98 L58,98 L58,97 L77.5,97 Z M77.5,91 L77.5,92 L58,92 L58,91 L77.5,91 Z M55.5,84 C56.328,84 57,84.672 57,85.5 C57,86.328 56.328,87 55.5,87 C54.672,87 54,86.328 54,85.5 C54,84.672 54.672,84 55.5,84 Z M77.5,85 L77.5,86 L58,86 L58,85 L77.5,85 Z" id="ico-Steps"></path>
                </g>
            </g>
        </svg>
    );
}

export default IcoBuySteps;