import React, {useEffect, useState, useRef} from 'react';
import {Link} from "react-router-dom";

import Loader from "../../components/Loader";
import ItemBalanceHistory from "../../components/ItemBalanceHistory";
// import TreeView from '../../components/TreeView/TreeView';

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Home.module.css';


const puDayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function Home() {

    const [loading, setLoading] = useState(false);
    const [userBalanceHistory, setUserBalanceHistory] = useState([]);

    const [timeline, setTimeline] = useState("day");

    const [btcDumpStatus, setBtcDumpStatus] = useState(null);
    const [tradeConditions, setTradeConditions] = useState(null)

    const [tradeConditions9, setTradeConditions9] = useState(null)

    // const treeViewRef = useRef(null);
    // const [newNodeName, setNewNodeName] = useState('');
    // function handleAddNode(){
    //     if (newNodeName.trim() !== '') {
    //         // Add the new node to the root (passing null as parentId)
    //         const newNodeId = treeViewRef.current.addNode(null, { name: newNodeName });
    //         console.log(`Added new node with ID: ${newNodeId}`);
    //         // setNewNodeName('');
    //     }
    // };

    useEffect(()=>{
        if(!localStorage["timeline"]) {
            localStorage["timeline"] = "day";
        }
        setTimeline(localStorage["timeline"]);

        getData();

        return ()=>{
            //console.log("unmount");
        }
    }, []);

    function getData(cb){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/home.php?timeline="+localStorage.timeline;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {   

                setBtcDumpStatus(json.btc_dump_status);
                setTradeConditions(json.trade_conditions);
                setTradeConditions9(json.trade_conditions_9);
                
                // json.balances_history = [];
                // for(let i = 0; i < 30; i++){
                //     let item = {
                //         id: "0",
                //         strategy:"",
                //         symbol:"",
                //         amount: 0,
                //         profit: 0,
                //         date_create:"0000-00-00 00:00:00",
                //         date_create_ts: 0
                //     }
                    
                //     for(let j = 0; j < json.balances_history_strategies.length; j++){
                //         let strategy = json.balances_history_strategies[j];

                //         if(json["balances_history_"+strategy][i]){
                //             let balanceItem = json["balances_history_"+strategy][i];
                            
                //             item.id = balanceItem.id;
                //             item.symbol = balanceItem.symbol;
                //             item.amount += parseInt(balanceItem.amount);
                //             item.profit += parseInt(balanceItem.profit);
                //             item.date_create = balanceItem.date_create;
                //             item.date_create_ts = balanceItem.date_create_ts;
                            
                //         }
                //     }

                //     if(item.id !== "0"){
                //         json.balances_history.push(item);
                //     }
                // }


                //user balance history
                // let userBalanceHistory = [];

                // for(let i = 0; i < json.balances_history.length; i++){
                //     let balance = json.balances_history[i];
                //     let balanceNext = null;
                //     if(json.balances_history[i+1]){
                //         balanceNext = json.balances_history[i+1];
                //     }

                //     let date_obj = new Date(parseInt(balance.date_create_ts)*1000);
                //     let day = puDayOfWeek[date_obj.getDay()];
                //     let date = balance.date_create;
                //     let symbol = balance.symbol;
                //     let amount = parseInt(balance.amount);
                //     let revenue = 0;
                //     if(balanceNext){
                //         revenue = amount - parseInt(balanceNext.amount);
                //     }
                //     let profit = parseInt(balance.profit);

                //     userBalanceHistory.push({
                //         day: day,
                //         date: date,
                //         symbol: symbol,
                //         amount: amount,
                //         revenue: revenue,
                //         profit: profit,
                //     });
                // }

                // setUserBalanceHistory(userBalanceHistory);

                setLoading(false); 

                if(cb){
                    cb();
                }
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function clickTimeline(value){
        localStorage["timeline"] = value;
        setTimeline(localStorage["timeline"]);
        getData();
    }

    if(loading){
        return (<Loader />);
    }

    let timelineDayCss = "";
    let timelineMonthCss = "";

    if(timeline === "day"){
        timelineDayCss = styles.active;
    }

    if(timeline === "month"){
        timelineMonthCss = styles.active;
    }


    let tradingStatusElm = null;
    if(tradeConditions && btcDumpStatus){

        tradingStatusElm = (
            <div className={styles.status+" "+styles.on}>
                {Constants.STRATEGIES["id3"].name} trading: On<br/>
            </div>
        );

        if(tradeConditions.master_switch_buy){
            if(tradeConditions.btc_dump_cond && !btcDumpStatus.can_buy){

                let cooldownTxt = "";
                if(btcDumpStatus.cooldown_eta != 0){
                    let min = parseInt(btcDumpStatus.cooldown_eta/60);
                    let sec = parseInt(btcDumpStatus.cooldown_eta%60);
                    if(min < 10){
                        min = "0"+min;
                    }
                    if(sec < 10){
                        sec = "0"+sec;
                    }

                    cooldownTxt = "Cooldown: "+min+"m "+sec+"s (Exit if BTC -"+tradeConditions.btc_dump_cooldown_exit_limit+"%)";
                }

                let row3 = null;

                if(cooldownTxt != ""){
                    row3 = <div>{cooldownTxt}</div>
                }else{
                    row3 = <div>Re-Activate if BTC at -{tradeConditions.btc_dump_buy_after_cooldown_if_dump_lower}%</div>
                }

                tradingStatusElm = (
                    <div className={styles.status+" "+styles.off}>
                        {Constants.STRATEGIES["id3"].name} trading: Off<br/>
                        Trigger: BTC dump condition<br/>
                        {row3}
                    </div>
                );
            }
        }else{
            tradingStatusElm = (
                <div className={styles.status+" "+styles.off}>
                    {Constants.STRATEGIES["id3"].name} trading: Off<br/>
                    Trigger: Master Switch
                </div>
            );
        }

    }


    let tradingStatusElm9 = null;
    if(tradeConditions9){
        if(tradeConditions9.master_switch_buy){

            tradingStatusElm9 = (
                <div className={styles.status+" "+styles.on}>
                    {Constants.STRATEGIES["id9"].name} trading: On<br/>
                </div>
            );

        }else{

            tradingStatusElm9 = (
                <div className={styles.status+" "+styles.off}>
                    {Constants.STRATEGIES["id9"].name} trading: Off<br/>
                    Trigger: Master Switch
                </div>
            );
            
        }
    }

    // const tree = new Tree(1, 'AB');

    // tree.insert(1, 11, 'AC');
    // tree.insert(1, 12, 'BC');
    // tree.insert(12, 121, 'BG');

    // tree.log();

    return (
        <div className={styles.root}>
            <div className={styles.mobile}>

                {/* <div>
                    <Link to="/1m"><div className={styles.btn}>1m</div></Link>
                    <Link to="/1m2"><div className={styles.btn}>1m 1.3%</div></Link>
                    <Link to="/1mi"><div className={styles.btn}>1m Ind</div></Link>
                </div>

                <div>
                    <Link to="/15m"><div className={styles.btn}>15m</div></Link>
                </div>

                <div>
                    <Link to="/1h"><div className={styles.btn}>1h</div></Link>
                </div>

                <div>
                    <Link to="/4h"><div className={styles.btn}>4h</div></Link>
                    <Link to="/4h2"><div className={styles.btn}>4h Dump</div></Link>
                </div>

                <div>
                    <Link to="/lo"><div className={styles.btn}>LO</div></Link>
                </div> */}

                {/* <div>
                    <Link to="/cc"><div className={styles.btn}>CC</div></Link>
                </div> */}

                {/* <div>
                    <br/>
                    <Link to="/wt"><div className={styles.btn}>WT</div></Link>
                    <Link to="/wt2"><div className={styles.btn}>WT New</div></Link>
                </div> */}

                {tradingStatusElm}
                <div style={{height:10}}></div>
                {tradingStatusElm9}
                

                <div className={styles.items}>
                    <div className={styles.item}>
                        <Link to="/indicators"><div className={styles.btn}>All Coins Table</div></Link>
                        {/* <Link to="/monitor"><div className={styles.btn}>Priority</div></Link> */}
                        {/* <Link to="/strategy-settings"><div className={styles.btn}>Strategy Settings</div></Link> */}
                    </div>

                    <div className={styles.label}>Strategy Settings</div>
                    <div className={styles.item}>
                        <Link to="/strategy-settings/3"><div className={styles.btn}>{Constants.STRATEGIES["id3"].name}</div></Link>
                        <Link to="/strategy-settings/9"><div className={styles.btn}>{Constants.STRATEGIES["id9"].name}</div></Link>
                    </div>

                    <div className={styles.label}>Paper Trading - Active</div>
                    <div className={styles.item}>
                        {/* <Link to="/trades/active/1"><div className={styles.btn}>{Constants.STRATEGIES["id1"].name}</div></Link>
                        <Link to="/trades/active/2"><div className={styles.btn}>{Constants.STRATEGIES["id2"].name}</div></Link>
                        <Link to="/trades/active/5"><div className={styles.btn}>{Constants.STRATEGIES["id5"].name}</div></Link>
                        <Link to="/trades/active/6"><div className={styles.btn}>{Constants.STRATEGIES["id6"].name}</div></Link>

                        <div className={styles.space}></div> */}

                        <Link to="/trades/active/3"><div className={styles.btn}>{Constants.STRATEGIES["id3"].name}</div></Link>
                        <Link to="/trades/active/9"><div className={styles.btn}>{Constants.STRATEGIES["id9"].name}</div></Link>
                        {/* <Link to="/trades/active/4"><div className={styles.btn}>{Constants.STRATEGIES["id4"].name}</div></Link>
                        <Link to="/trades/active/7"><div className={styles.btn}>{Constants.STRATEGIES["id7"].name}</div></Link>
                        <Link to="/trades/active/8"><div className={styles.btn}>{Constants.STRATEGIES["id8"].name}</div></Link> */}
                    </div>
                    
                    <div className={styles.label}>Paper Trading - Finished</div>
                    <div className={styles.item}>
                        {/* <Link to="/trades/finished/1"><div className={styles.btn}>{Constants.STRATEGIES["id1"].name}</div></Link>
                        <Link to="/trades/finished/2"><div className={styles.btn}>{Constants.STRATEGIES["id2"].name}</div></Link>
                        <Link to="/trades/finished/5"><div className={styles.btn}>{Constants.STRATEGIES["id5"].name}</div></Link>
                        <Link to="/trades/finished/6"><div className={styles.btn}>{Constants.STRATEGIES["id6"].name}</div></Link>

                        <div className={styles.space}></div> */}

                        <Link to="/trades/finished/3"><div className={styles.btn}>{Constants.STRATEGIES["id3"].name}</div></Link>
                        <Link to="/trades/finished/9"><div className={styles.btn}>{Constants.STRATEGIES["id9"].name}</div></Link>
                        {/* <Link to="/trades/finished/4"><div className={styles.btn}>{Constants.STRATEGIES["id4"].name}</div></Link>
                        <Link to="/trades/finished/7"><div className={styles.btn}>{Constants.STRATEGIES["id7"].name}</div></Link>
                        <Link to="/trades/finished/8"><div className={styles.btn}>{Constants.STRATEGIES["id8"].name}</div></Link> */}
                    </div>
                </div>



                {/* <div className={styles.title}>
                    Balance history 
                    <span className={styles.timeline+" "+timelineDayCss} onClick={()=>{clickTimeline("day")}} style={{marginLeft: "20px"}}>1D</span> 
                    <span className={styles.timeline+" "+timelineMonthCss} onClick={()=>{clickTimeline("month")}}>1M</span>

                    <a className={styles.actionBtn+" "} style={{marginLeft: "10px"}} target="_blank" href={"/chart-balance"} rel="noreferrer">Chart</a> 
                </div>
                <div className={styles.balanceHistory}>
                    {userBalanceHistory.map((row, index) => {
                        return (
                            <ItemBalanceHistory 
                                key={index}  
                                view_type="list" 
                                nr_crt={index+1} 
                                day={row.day} 
                                date={row.date} 
                                symbol={row.symbol} 
                                amount={row.amount} 
                                revenue={row.revenue} 
                                profit={row.profit} 
                            />
                        )
                    })}
                </div> */}



                {/* <h1 style={{marginTop: 20, marginBottom: 20}}>Tree View</h1>
                <div>
                    <input
                    type="text"
                    value={newNodeName}
                    onChange={(e) => setNewNodeName(e.target.value)}
                    placeholder="Enter new node name"
                    />
                    <button onClick={handleAddNode}>Add Node</button>
                </div>
                <TreeView ref={treeViewRef} /> */}

                    
                
                
            </div>
        </div>
    );
}

export default Home;




// class TreeNode {
//     constructor(key, value = key, parent = null) {
//         this.key = key;
//         this.value = value;
//         this.parent = parent;
//         this.children = [];
//     }
  
//     get isLeaf() {
//         return this.children.length === 0;
//     }
  
//     get hasChildren() {
//         return !this.isLeaf;
//     }
// }

// class Tree {
//     constructor(key, value = key) {
//         this.root = new TreeNode(key, value);
//     }
  
//     *preOrderTraversal(node = this.root) {
//         yield node;
//         if (node.children.length) {
//             for (let child of node.children) {
//                 yield* this.preOrderTraversal(child);
//             }
//         }
//     }
  
//     *postOrderTraversal(node = this.root) {
//         if (node.children.length) {
//             for (let child of node.children) {
//                 yield* this.postOrderTraversal(child);
//             }
//         }
//         yield node;
//     }
  
//     insert(parentNodeKey, key, value = key) {
//         for (let node of this.preOrderTraversal()) {
//             if (node.key === parentNodeKey) {
//                 node.children.push(new TreeNode(key, value, node));
//                 return true;
//             }
//         }
//         return false;
//     }
  
//     remove(key) {
//         for (let node of this.preOrderTraversal()) {
//             const filtered = node.children.filter(c => c.key !== key);
//             if (filtered.length !== node.children.length) {
//                 node.children = filtered;
//                 return true;
//             }
//         }
//         return false;
//     }
  
//     find(key) {
//         for (let node of this.preOrderTraversal()) {
//             if (node.key === key) return node;
//         }
//         return undefined;
//     }

//     log(){
//         for (let node of this.preOrderTraversal()) {
//             console.log(node);
//         }
//     }
// }


