import PropTypes from 'prop-types';

IcoPlay.defaultProps = {
    fill: "#FFFFFF",
};

IcoPlay.propTypes = {
    fill: PropTypes.string,
}

function IcoPlay(props){
    return(
        <svg {...props} width="18px" height="21px" viewBox="0 0 18 21">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-393.000000, -219.000000)" fill={props.fill} fillRule="nonzero">
                    <path d="M393.44688,219.006081 C393.187102,219.046834 392.996835,219.270441 393,219.531221 L393,239.486495 C393.004204,239.672006 393.106781,239.84157 393.269876,239.932546 C393.432971,240.023522 393.632374,240.022405 393.794422,239.929608 L410.741243,229.951955 C410.901689,229.857263 411,229.685795 411,229.50065 C411,229.315505 410.901689,229.144037 410.741243,229.049345 L393.794422,219.071724 C393.689587,219.010989 393.566891,218.987814 393.44688,219.006081 L393.44688,219.006081 Z M394.059216,220.450215 L409.450372,229.508842 L394.059216,238.567501 L394.059216,220.450215 L394.059216,220.450215 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
    );
}

export default IcoPlay;