import PropTypes from 'prop-types';

function IcoRemove(props){
    return(
        // <svg {...props} width="17px" height="17px" viewBox="0 0 17 17">
        //     <path d="M4.75,7.95 L4.75,9.55 L12.75,9.55 L12.75,7.95 L4.75,7.95 Z M8.75,0.75 C4.334,0.75 0.75,4.334 0.75,8.75 C0.75,13.166 4.334,16.75 8.75,16.75 C13.166,16.75 16.75,13.166 16.75,8.75 C16.75,4.334 13.166,0.75 8.75,0.75 Z M8.75,15.15 C5.222,15.15 2.35,12.278 2.35,8.75 C2.35,5.222 5.222,2.35 8.75,2.35 C12.278,2.35 15.15,5.222 15.15,8.75 C15.15,12.278 12.278,15.15 8.75,15.15 Z"></path>
        

        // </svg>

        <svg {...props} width="16px" height="16px">

            <rect stroke="#E8E8E8" stroke-width="1" stroke-linejoin="square" fill="#FFFFFF" fill-rule="evenodd" x="0.5" y="0.5" width="180" height="87" rx="6"></rect>
            <path d="M4,7.2 L4,8.8 L12,8.8 L12,7.2 L4,7.2 Z M8,0 C3.584,0 0,3.584 0,8 C0,12.416 3.584,16 8,16 C12.416,16 16,12.416 16,8 C16,3.584 12.416,0 8,0 Z M8,14.4 C4.472,14.4 1.6,11.528 1.6,8 C1.6,4.472 4.472,1.6 8,1.6 C11.528,1.6 14.4,4.472 14.4,8 C14.4,11.528 11.528,14.4 8,14.4 Z" id="ico-Delete"></path>
                                
        </svg>
    );
}

export default IcoRemove;