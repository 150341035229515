import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import IcoX from '../../icons/IcoX';
import IcoArrow from '../../icons/IcoArrow';

import Loader from "../../components/Loader";
import Util, { capitalizeFirstLetter } from '../../utils/Util';
import Fetch from '../../utils/Fetch';
import Constants from '../../Constants';

import ConfirmDialog from '../ConfirmDialog';

import styles from './BuyStepsDialog.module.css';

const WebSocketClient = require('websocket').w3cwebsocket;

BuyStepsDialog.defaultProps = {
    open: false,
    strategy: "0",
    coin1: "COIN1",
    coin2: "COIN2",

    buy_steps: [],
    buy_count: 0,
    price_precision: 2,
    price_move: 0.01,

    onOk: null,
    onCancel: null,
};

BuyStepsDialog.propTypes = {
    open: PropTypes.bool,
    strategy: PropTypes.string,
    coin1: PropTypes.string,
    coin2: PropTypes.string,

    buy_steps: PropTypes.array,
    buy_count: PropTypes.number,
    price_precision: PropTypes.number,
    price_move: PropTypes.number,

    onOk: PropTypes.func,
    onCancel: PropTypes.func,
}

const TradeDialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: "transparent",
    }
}));

function BuyStepsDialog(props) {

    const [loading, setLoading] = useState(true);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const [buySteps, setBuySteps] = useState([0,0,0, 0,0,0, 0,0,0]);
    const [buyStepsError, setBuyStepsError] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ]);
    const [errorText, setErrorText] = useState("\u00A0");

    useEffect(()=>{
        return ()=>{
            //console.log("unmount");
        }
    }, []);
    

    useEffect(()=>{
        if(props.open){
            //clear error 
            let newBuyStepsError = [...buyStepsError];
            for(let i = 0; i < newBuyStepsError.length; i++){
                newBuyStepsError[i] = false;
            }
            setBuyStepsError(newBuyStepsError);
            setErrorText("\u00A0");

            //reset errors            
            let newBuySteps = [...buySteps];
            for(let i = 0; i < newBuySteps.length; i++){
                if(props.buy_steps[i]){
                    let step = parseFloat(props.buy_steps[i]).toFixed(props.price_precision);
                    step = parseFloat(step);
                    newBuySteps[i] = parseFloat(step);
                }
            }
            setBuySteps(newBuySteps);

            setLoading(false); 
        }else{

        }
    }, [props.open]);

    function onOk(){
        let resultBuySteps = [];
        for(let i = 0; i < buySteps.length; i++){
            resultBuySteps.push(parseFloat(buySteps[i]));
        }
        setConfirmDialog(false);
        setTimeout(()=>{
            if(props.onOk){
                props.onOk(resultBuySteps);
            }
        }, 300)
    }

    function onCancel(){
        if(props.onCancel){
            props.onCancel();
        }
    }

    function onSave(){

        //clear error
        let newBuyStepsError = [...buyStepsError];
        for(let i = 0; i < newBuyStepsError.length; i++){
            newBuyStepsError[i] = false;
        }
        setBuyStepsError(newBuyStepsError);
        setErrorText("\u00A0");

        //check error
        for(let i = 0; i < buySteps.length; i++){
            if(i === 0){
                continue;
            }

            let hasError = false;

            if(isNaN(parseFloat(buySteps[i]))){
                hasError = true;
            }

            if(parseFloat(buySteps[i]) > parseFloat(buySteps[i-1])){
                hasError = true;
            }

            if(i < buySteps.length-1 && parseFloat(buySteps[i]) === 0){
                if(parseFloat(buySteps[i]) < parseFloat(buySteps[i+1])){
                    hasError = true;
                }
            }

            //show error
            if(hasError){
                let newBuyStepsError = [...buyStepsError];
                newBuyStepsError[i] = true;
                setBuyStepsError(newBuyStepsError);
                setErrorText("B"+(i+1)+" seems to have a wrong value.");
                return;
            }
        }
    
        setConfirmDialog(true);
    }

    function onChangeBuyStep(e, index){
        let value = parseFloat(e.target.value);

        let newBuySteps = [...buySteps];

        if(isNaN(value)){
            newBuySteps[index] = e.target.value;
        }else if(value < 0){
            newBuySteps[index] = 0;
        }else{
            newBuySteps[index] = e.target.value;
        }

        setBuySteps(newBuySteps);
        
    }

    function onChangeBuyStepPlus(e, index){
        let newBuySteps = [...buySteps];

        let value = parseFloat(newBuySteps[index]);
        if(isNaN(value)){
            value = 0;
        }

        value += parseFloat(props.price_move);
        value = Util.round(value, props.price_precision);
        newBuySteps[index] = value;
        setBuySteps(newBuySteps);
    }

    function onChangeBuyStepMinus(e, index){
        let newBuySteps = [...buySteps];

        let value = parseFloat(newBuySteps[index]);
        if(isNaN(value)){
            value = 0;
        }

        value -= parseFloat(props.price_move);
        value = Util.round(value, props.price_precision);
        if(value < 0){
            value = 0;
        }
        newBuySteps[index] = value;
        setBuySteps(newBuySteps);
    }

    let loader = null;
    if(loading){
        loader = (<div className={styles.loading}><Loader /></div>);
    }


    let buyStepsReadOnly = [];
    for(let i = 0; i < buySteps.length; i++){
        if(i < props.buy_count){
            buyStepsReadOnly.push({
                readOnly: "readOnly",
                disabledCss: styles.disabled,
                errorCss: buyStepsError[i] ? styles.error : ""
            });
        }else{
            buyStepsReadOnly.push({
                readOnly: "",
                disabledCss: "",
                errorCss: buyStepsError[i] ? styles.error : ""
            });
        }
    }

    return (

        <TradeDialogStyled
            open={props.open}
            onClose={onCancel}>

            <div className={styles.content}>
                <div className={styles.head}>
                    <div className={styles.title}>Buy steps for {props.coin1}</div>
                    <div className={styles.close} onClick={onCancel}>
                        <IcoX fill="rgba(255,255,255,0.4)" />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.bodyCenter}>

                        <div className={styles.item} style={{marginTop: 10}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[0].disabledCss+" "+buyStepsReadOnly[0].errorCss}>
                                <div className={styles.leftLabel}>B1 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[0].readOnly} value={buySteps[0]} onChange={(e)=>{onChangeBuyStep(e, 0)}} />
                            </div>
                        </div>

                        <div className={styles.item} style={{marginTop: 16}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[1].disabledCss+" "+buyStepsReadOnly[1].errorCss}>
                                <div className={styles.leftLabel}>B2 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[1].readOnly} value={buySteps[1]} onChange={(e)=>{onChangeBuyStep(e, 1)}} />

                                {buyStepsReadOnly[1].disabledCss === "" ?
                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={(e)=>{onChangeBuyStepPlus(e, 1)}}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={(e)=>{onChangeBuyStepMinus(e, 1)}}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                                : null}

                            </div>
                            <span className={styles.percent}>-{Math.abs((buySteps[1] * 100 / buySteps[0])-100).toFixed(2)} %</span>
                        </div>

                        <div className={styles.item} style={{marginTop: 16}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[2].disabledCss+" "+buyStepsReadOnly[2].errorCss}>
                                <div className={styles.leftLabel}>B3 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[2].readOnly} value={buySteps[2]} onChange={(e)=>{onChangeBuyStep(e, 2)}} />

                                {buyStepsReadOnly[2].disabledCss === "" ?
                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={(e)=>{onChangeBuyStepPlus(e, 2)}}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={(e)=>{onChangeBuyStepMinus(e, 2)}}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                                : null}

                            </div>
                            <span className={styles.percent}>-{Math.abs((buySteps[2] * 100 / buySteps[0])-100).toFixed(2)} %</span>
                        </div>

                        <div className={styles.item} style={{marginTop: 30}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[3].disabledCss+" "+buyStepsReadOnly[3].errorCss}>
                                <div className={styles.leftLabel}>B4 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[3].readOnly} value={buySteps[3]} onChange={(e)=>{onChangeBuyStep(e, 3)}} />

                                {buyStepsReadOnly[3].disabledCss === "" ?
                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={(e)=>{onChangeBuyStepPlus(e, 3)}}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={(e)=>{onChangeBuyStepMinus(e, 3)}}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                                : null}

                            </div>
                            <span className={styles.percent}>-{Math.abs((buySteps[3] * 100 / buySteps[0])-100).toFixed(2)} %</span>
                        </div>

                        <div className={styles.item} style={{marginTop: 16}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[4].disabledCss+" "+buyStepsReadOnly[4].errorCss}>
                                <div className={styles.leftLabel}>B5 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[4].readOnly} value={buySteps[4]} onChange={(e)=>{onChangeBuyStep(e, 4)}} />

                                {buyStepsReadOnly[4].disabledCss === "" ?
                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={(e)=>{onChangeBuyStepPlus(e, 4)}}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={(e)=>{onChangeBuyStepMinus(e, 4)}}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                                : null}

                            </div>
                            <span className={styles.percent}>-{Math.abs((buySteps[4] * 100 / buySteps[0])-100).toFixed(2)} %</span>
                        </div>

                        <div className={styles.item} style={{marginTop: 16}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[5].disabledCss+" "+buyStepsReadOnly[5].errorCss}>
                                <div className={styles.leftLabel}>B6 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[5].readOnly} value={buySteps[5]} onChange={(e)=>{onChangeBuyStep(e, 5)}} />

                                {buyStepsReadOnly[5].disabledCss === "" ?
                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={(e)=>{onChangeBuyStepPlus(e, 5)}}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={(e)=>{onChangeBuyStepMinus(e, 5)}}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                                : null}

                            </div>
                            <span className={styles.percent}>-{Math.abs((buySteps[5] * 100 / buySteps[0])-100).toFixed(2)} %</span>
                        </div>

                        <div className={styles.item} style={{marginTop: 30}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[6].disabledCss+" "+buyStepsReadOnly[6].errorCss}>
                                <div className={styles.leftLabel}>B7 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[6].readOnly} value={buySteps[6]} onChange={(e)=>{onChangeBuyStep(e, 6)}} />

                                {buyStepsReadOnly[6].disabledCss === "" ?
                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={(e)=>{onChangeBuyStepPlus(e, 6)}}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={(e)=>{onChangeBuyStepMinus(e, 6)}}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                                : null}

                            </div>
                            <span className={styles.percent}>-{Math.abs((buySteps[6] * 100 / buySteps[0])-100).toFixed(2)} %</span>
                        </div>

                        <div className={styles.item} style={{marginTop: 16}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[7].disabledCss+" "+buyStepsReadOnly[7].errorCss}>
                                <div className={styles.leftLabel}>B8 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[7].readOnly} value={buySteps[7]} onChange={(e)=>{onChangeBuyStep(e, 7)}} />

                                {buyStepsReadOnly[7].disabledCss === "" ?
                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={(e)=>{onChangeBuyStepPlus(e, 7)}}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={(e)=>{onChangeBuyStepMinus(e, 7)}}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                                : null}

                            </div>
                            <span className={styles.percent}>-{Math.abs((buySteps[7] * 100 / buySteps[0])-100).toFixed(2)} %</span>
                        </div>

                        <div className={styles.item} style={{marginTop: 16}}>
                            <div className={styles.textInput+" "+buyStepsReadOnly[8].disabledCss+" "+buyStepsReadOnly[8].errorCss}>
                                <div className={styles.leftLabel}>B9 at {props.coin2}</div>
                                <input type="number" readOnly={buyStepsReadOnly[8].readOnly} value={buySteps[8]} onChange={(e)=>{onChangeBuyStep(e, 8)}} />

                                {buyStepsReadOnly[8].disabledCss === "" ?
                                <div className={styles.topDown}>
                                    <div className={styles.top} onClick={(e)=>{onChangeBuyStepPlus(e, 8)}}>
                                        <IcoArrow direction="up" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                    <div className={styles.down} onClick={(e)=>{onChangeBuyStepMinus(e, 8)}}>
                                        <IcoArrow direction="down" fill="rgba(255,255,255,0.4)" />
                                    </div>
                                </div>
                                : null}

                            </div>
                            <span className={styles.percent}>-{Math.abs((buySteps[8] * 100 / buySteps[0])-100).toFixed(2)} %</span>
                        </div>

                        <div className={styles.errorText} style={{marginTop: 16}}>{errorText}</div>

                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.btnCancel} onClick={onCancel}>Cancel</div>
                    <div className={styles.btnOk} onClick={onSave}>Set new Buy steps</div>
                </div>

                {loader}
            </div>

            <ConfirmDialog open={confirmDialog} title="Buy steps" text="Set new Buy steps ?" okLabel="OK" cancelLabel="Cancel" onOk={onOk} onCancel={()=>{setConfirmDialog(false)}} />  

        </TradeDialogStyled>

    );
}


export default BuyStepsDialog;