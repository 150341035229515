import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";
import IcoX from '../../icons/IcoX';

import styles from './ItemSlot.module.css';

ItemSlot.defaultProps = {
    nr_crt: 0,
    pair: "",
    coin: "",
    coin2: "",
    stop_trade: false,
};

ItemSlot.propTypes = {
    nr_crt: PropTypes.number,
    pair: PropTypes.string,
    coin: PropTypes.string,
    coin2: PropTypes.string,
    stop_trade: PropTypes.bool,
}

function ItemSlot(props) {

    function onSlotRemove(){
        if(props.onSlotRemove){
            props.onSlotRemove(props.pair);
        }
    }

    function onSlotTradeStop(){
        if(props.onSlotTradeStop){
            props.onSlotTradeStop(props.pair);
        }
    }

    let textStatus = "";
    let textStatusColor = "#fff";
    let playLabel = "";
    if(props.stop_trade){
        textStatus = "Stopped !";
        textStatusColor = "#F5465D";
        playLabel = "Turn On";
    }else{
        textStatus = "Is running";
        playLabel = "Turn Off";
    }


    return (
        <div className={styles.tradesItem}>
            <div className={styles.content}>
                
                <div className={styles.col1}>
                    <div className={styles.row1}>
                        <div className={styles.nr}>{props.nr_crt}</div>
                    </div>
                </div>

                <div className={styles.col2}>
                    <div className={styles.row1}>
                        <div className={styles.symbol} style={{color: textStatusColor}}>{props.coin} <span style={{color: "rgba(255, 255, 255, 1)"}}>/{props.coin2}</span></div>
                    </div>
                    <div className={styles.row2}>
                        <div className={styles.amount}>{textStatus}</div>
                    </div>
                </div>

                <div className={styles.col4}>
                    <div className={styles.play} onClick={onSlotTradeStop}>{playLabel}</div>
                </div>

                <div className={styles.col3}>
                    <div className={styles.remove} onClick={onSlotRemove}><IcoX fill="rgba(255,255,255,0.5)" /></div>
                </div>

            </div>
        </div>

    );
    
}


export default ItemSlot;