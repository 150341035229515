import PropTypes from 'prop-types';

IcoIn.defaultProps = {
    fill: "#FFFFFF",
};

IcoIn.propTypes = {
    fill: PropTypes.string,
}

function IcoIn(props){
    return(
        <svg {...props} width="21px" height="21px" viewBox="0 0 21 21">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-88.000000, -97.000000)" fillRule="nonzero">
                    <g id="ico" transform="translate(88.000000, 97.000000)">
                        <path d="M11.9,13.57 C11.7345973,13.2227162 11.3846594,13.0010889 11,13 L8,13 L8,5 C8,4.44771525 7.55228475,4 7,4 L5,4 C4.44771525,4 4,4.44771525 4,5 L4,13 L1,13 C0.607492173,13.0004965 0.251569607,13.2305711 0.0899607361,13.5882654 C-0.0716481351,13.9459597 -0.00905568698,14.3651224 0.25,14.66 L5.48,20.66 C5.66956984,20.8757843 5.94277285,20.9996363 6.23,21 L6.23,21 C6.52722714,20.9926129 6.80575405,20.8533495 6.99,20.62 L11.76,14.62 C12.003434,14.3254897 12.0577655,13.9180036 11.9,13.57 Z" id="Path" fill={props.fill}></path>
                        <path d="M14.4024228,0.68984565 C14.9336787,0.523881135 15.0580112,0.571701987 15.1430897,0.668540144 L15.1430897,0.668540144 L20.3743676,6.6700004 C20.4391315,6.7437198 20.4793375,6.83297484 20.493252,6.92615592 C20.5071665,7.019337 20.4947894,7.11644413 20.4543872,7.2058677 C20.413985,7.29529128 20.3492935,7.36876239 20.2701709,7.41990864 C20.1910483,7.47105489 20.0974945,7.49987628 20,7.5 L20,7.5 L16.5,7.5 L16.5,16 C16.5,16.1380712 16.4440356,16.2630712 16.3535534,16.3535534 C16.2630712,16.4440356 16.1380712,16.5 16,16.5 L16,16.5 L14,16.5 C13.8619288,16.5 13.7369288,16.4440356 13.6464466,16.3535534 C13.5559644,16.2630712 13.5,16.1380712 13.5,16 L13.5,16 L13.5,7.49999967 L10.0014154,7.500002 C9.90525054,7.49972978 9.81342587,7.47189025 9.73530476,7.42241354 C9.65718364,7.37293684 9.59276607,7.30182295 9.55538903,7.22355187 C9.51594765,7.13655097 9.5030184,7.04211476 9.51516411,6.95102188 C9.52730983,6.859929 9.56453052,6.77217945 9.63138718,6.69115281 L9.63138718,6.69115281 Z" id="Path" stroke={props.fill}></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default IcoIn;