import React, {useEffect, useRef, useState} from 'react';
// import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import { createChart, CrosshairMode, LineStyle, LineType } from 'lightweight-charts';
// const MovingAverages = require('moving-averages');
import * as MovingAverages from "moving-averages";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import Loader from "../../components/Loader";
import ConfirmDialog from '../../components/ConfirmDialog';
import TradeDialog from '../../components/TradeDialog';
import BuyStepsDialog from '../../components/BuyStepsDialog';
import TTPDialog from '../../components/TTPDialog';

import IcoPlay from '../../icons/IcoPlay';
import IcoOut from '../../icons/IcoOut';
import IcoIn from '../../icons/IcoIn';
import IcoBuySteps from '../../icons/IcoBuySteps';
import IcoTTP from '../../icons/IcoTTP';

import styles from './Chart.module.css';


const WebSocketClient = require('websocket').w3cwebsocket;

const WS_CONN_RESTART_DELAY = 1000;
const TIMEZONE_OFFSET = -(new Date().getTimezoneOffset()/60);
// const TIMEZONE_OFFSET = 0;
var lastPriceAsk = 0;
var lastKline = null;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Chart() {
    const query = useQuery();

    const [loading, setLoading] = useState(true);
    const chartElm = useRef(null);
    const legendElm = useRef(null);
    const legendElm2 = useRef(null);
    const chart = useRef(null);
    const candleSeries = useRef(null);
    const buyLines = useRef([]);
    const tpLine = useRef(null);
    const spLine = useRef(null);

    const exLowLine = useRef(null);
    const exHighLine = useRef(null);

    const slot = useRef(null);
    const priceFormat = useRef({
        precision: 2,
        minMove: 0.01,
    });

    const getVariables = useRef({
        strategy: "",
        pair: "",
        coin1: "",
        coin2: "",
        interval: "1m",
        test: "0",
    });

    const puWsConnLocal = useRef(null);

    const [trailingDialog, setTrailingDialog] = useState(false);
    const [sellDialog, setSellDialog] = useState(false);
    const [buyStepsDialog, setBuyStepsDialog] = useState(false);
    const [ttpDialog, setTtpDialog] = useState(false);
    


    const [tradeDialogOpen, setTradeDialogOpen] = useState(false);
    const [snackbarTradeOpen, setSnackbarTradeOpen] = useState(false);
    const [statusTrade, setStatusTrade] = useState("");
    const [sideTrade, setSideTrade] = useState("buy");

    const sellType = useRef("MARKET");

    useEffect(()=>{
        
        setVariables();

        if(getVariables.current.strategy === "cc"){
            document.title = "S CC - "+getVariables.current.pair;
        }else{
            document.title = "S"+getVariables.current.strategy+" - "+getVariables.current.pair;
        }

        if(getVariables.current.strategy === "1m"
        || getVariables.current.strategy === "1m2"
        || getVariables.current.strategy === "1mi"){
            sellType.current = "LIMIT";
        }
        
        getData((json)=>{

            let lcSlot = null;
            if(json.trades[getVariables.current.pair]){
                lcSlot = json.trades[getVariables.current.pair];
            }
            slot.current = lcSlot;

            setLoading(false);

            startUpdateingLegend();
            startLocalWsConnection();
            startSymbolBookTicker();

            if(getVariables.current.test === "0"){
                getKlines(getVariables.current.pair, getVariables.current.interval, 1000, (ohlcArr)=>{
                    setupChart(ohlcArr, lcSlot);
                    startKlineUpdate(getVariables.current.pair, getVariables.current.interval);

                    // calculateExtremum();

                });
            }else{
                setupChart([], lcSlot);
                startKlineUpdate(getVariables.current.pair, getVariables.current.interval);

                // calculateExtremum();
            }            
            
        });

        window.addEventListener('resize', onChartResize);
        
        return ()=>{
            //console.log("unmount");
            window.removeEventListener('resize', onChartResize);
        }
    }, []);

    function setVariables(){
        if(query.get("strategy") != null){
            getVariables.current.strategy = query.get("strategy");
        }

        if(query.get("pair") != null){
            let coins = query.get("pair").split('_');
            getVariables.current.coin1 = coins[0].toUpperCase();
            getVariables.current.coin2 = coins[1].toUpperCase();
            getVariables.current.pair =getVariables.current.coin1+getVariables.current.coin2;
        }

        if(query.get("interval") != null){
            getVariables.current.interval = query.get("interval");
        }

        if(query.get("test") != null){
            getVariables.current.test = query.get("test");
        }
    }

    function getData(cb){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/chart.php?strategy="+getVariables.current.strategy;
  
        Fetch.request(url, options, 9000)
            .then((response) => response.json())
            .then((json) => {  
                
                //exchange info
                if(json.exchange_info.symbols && Array.isArray(json.exchange_info.symbols)){    
                    let symbols = json.exchange_info.symbols;
                    for(let i = 0; i < symbols.length; i++){
                        let symbol = symbols[i].symbol;
                        let filters = symbols[i].filters;

                        if(symbol !== getVariables.current.pair){
                            continue;
                        }
    
                        for(let j = 0; j < filters.length; j++){
                            let filter = filters[j];
                            if(filter.filterType === "PRICE_FILTER"){
                                let min_trade_price = parseFloat(filter.minPrice);
                                priceFormat.current.minMove = min_trade_price;
                                priceFormat.current.precision = Util.getDecimalsCount(min_trade_price);
                            }
                        }
                    } 
                }


                if(cb){
                    cb(json);
                }

            })
            .catch((error) => {
                // console.log(error);
            });

    }

    function setupChart(ohlcArr, slot){
        let chartOptions = {
            width: 1024,
            height: 768,
            layout: {
                backgroundColor: '#151924',
                textColor: 'rgba(255, 255, 255, 0.6)',
                fontSize: 12,
            },
            grid: {
                vertLines: {
                    color: '#242733',
                },
                horzLines: {
                    color: '#242733',
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            rightPriceScale: {
                borderColor: '#323740',
            },
            timeScale: {
                borderColor: '#323740',
            },
        }
        chart.current = createChart(chartElm.current, chartOptions);
        chart.current.applyOptions({
            timeScale: {
                rightOffset: 12,
                barSpacing: 3,
                fixLeftEdge: true,
                lockVisibleTimeRangeOnResize: true,
                rightBarStaysOnScroll: true,
                borderVisible: false,
                borderColor: '#fff000',
                visible: true,
                timeVisible: true,
                secondsVisible: false,
            },
        });

        candleSeries.current = chart.current.addCandlestickSeries({
            upColor: '#27A69A',
            downColor: '#EF5351',
            priceFormat: priceFormat.current,
        });
        candleSeries.current.setData(ohlcArr);

        
    
        if(slot != null){
            //ex lines
            if(slot.ex_h){
                exHighLine.current = candleSeries.current.createPriceLine({
                    price: slot.ex_h,
                    color: 'rgba(0,230,118, 0.28)',
                    axisLabelVisible: true,
                    title: 'Fib 0',
                });
        
                exLowLine.current = candleSeries.current.createPriceLine({
                    price: slot.ex_l,
                    color: 'rgba(224,64,251, 0.45)',
                    axisLabelVisible: true,
                    title: 'Fib 1',
                });
            }

            //tp lines
            if(slot.target_price){
                tpLine.current = candleSeries.current.createPriceLine({
                    price: slot.target_price,
                    color: 'rgba(255, 224, 178, 0.7)',
                    axisLabelVisible: true,
                    title: sellType.current === "MARKET" ? "TTP" : "TP"
                });

                if(sellType.current === "MARKET"){
                    let sell_price = slot.sell_price;
                    if(sell_price == null){
                        sell_price = slot.target_price - (slot.trail_sell_price_percent/100*slot.target_price);
                    }

                    spLine.current = candleSeries.current.createPriceLine({
                        price: sell_price,
                        color: 'rgba(255, 224, 178, 0.7)',
                        axisLabelVisible: true,
                    });
                }
            }

            //price lines
            for(let i = 0; i < slot.buy_percent_limit.length; i++){
                let percent = slot.buy_percent_limit[i];
                let color = "rgba(124, 186, 225, 0.7)";
                if(i < slot.buy_count){
                    color = "rgba(88, 114, 128, 0.9)";
                }

                let buyCountLabel = "";
                if(i+1 === 1){
                    buyCountLabel = "1st";
                }
                if(i+1 === 2){
                    buyCountLabel = "2nd";
                }
                if(i+1 === 3){
                    buyCountLabel = "3rd";
                }
                if(i+1 > 3){
                    buyCountLabel = (i+1)+"th";
                }
                let title = buyCountLabel;
                if(i+1 > 1){
                    title = buyCountLabel+" -"+percent+"%";
                }

                let priceLimit = slot.price_initial - (percent/100)*slot.price_initial;

                let buyLine = candleSeries.current.createPriceLine({
                    price: priceLimit,
                    color: color,
                    axisLabelVisible: true,
                    title: title,
                });
                buyLines.current.push(buyLine);
            }
            if(slot.buy_steps){
                for(let i = slot.buy_percent_limit.length; i < slot.buy_steps.length; i++){
                    let priceLimit = slot.buy_steps[i];
                    let percent = Math.abs((priceLimit * 100 / slot.price_initial)-100);
                    percent = percent.toFixed(2);
                    percent = parseFloat(percent);

                    let color = "rgba(124, 186, 225, 0.7)";
                    if(i < slot.buy_count){
                        color = "rgba(88, 114, 128, 0.9)";
                    }
    
                    let buyCountLabel = "";
                    if(i+1 === 1){
                        buyCountLabel = "1st";
                    }
                    if(i+1 === 2){
                        buyCountLabel = "2nd";
                    }
                    if(i+1 === 3){
                        buyCountLabel = "3rd";
                    }
                    if(i+1 > 3){
                        buyCountLabel = (i+1)+"th";
                    }
                    let title = buyCountLabel;
                    if(i+1 > 1){
                        title = buyCountLabel+" -"+percent+"%";
                    }

                    let buyLine = candleSeries.current.createPriceLine({
                        price: priceLimit,
                        color: color,
                        axisLabelVisible: true,
                        title: title,
                    });
                    buyLines.current.push(buyLine);
                }
            }

            //markers
            let markersArr = [];
            for(let i = 0; i < slot.buy_date_arr.length; i++){
                let buyCountLabel = "";
                if(i+1 === 1){
                    buyCountLabel = "1st";
                }
                if(i+1 === 2){
                    buyCountLabel = "2nd";
                }
                if(i+1 === 3){
                    buyCountLabel = "3rd";
                }
                if(i+1 > 3){
                    buyCountLabel = (i+1)+"th";
                }

                // let time = slot.buy_date_arr[i] + 60*60*TIMEZONE_OFFSET;
                // var date = new Date(time * 1000);
                // date.setSeconds(0);
                // time = date.getTime()/1000;
                // time = time - 60;
                let time = normalizeCandleTime(slot.buy_date_arr[i]);

                markersArr.push({
                    time: time,
                    position: 'belowBar',
                    color: 'rgba(255, 224, 178, 0.85)',
                    shape: 'arrowUp',
                    text: buyCountLabel+' Buy',
                });
            }

            if(slot.last_sell_id != null){
                let time = normalizeCandleTime(slot.final_sell_date);

                let totalInvested = 0;
                let totalAmount = 0;
                for(let i = 0; i < slot.buy_arr.length; i++){
                    let price = parseFloat(slot.buy_price_arr[i]);
                    let amount = parseFloat(slot.buy_amount_arr[i]);

                    totalInvested = totalInvested + (price*amount);
                    totalAmount = totalAmount + amount;
                }

                let win = totalAmount*parseFloat(slot.final_sell_price);
                let profit = ((win*100)/totalInvested)-100;
                if(profit > 0){
                    profit = "+"+profit.toFixed(2);
                }else{
                    profit = profit.toFixed(2);
                }

                markersArr.push({
                    time: time,
                    position: 'belowBar',
                    color: 'rgba(255, 224, 178, 0.85)',
                    shape: 'arrowUp',
                    text: "Sell ⚡️ "+profit+"%",
                });
            }   
            
            candleSeries.current.setMarkers(markersArr);
            

        }


        onChartResize();

        // chart.current.subscribeCrosshairMove((param) => {
        //     if (param.time) {
        //         const price = param.seriesPrices.get(candleSeries.current);
        //         if(price){
        //             legendElm.current.innerText = 'BTC / USDT  Binance O'+price.open+' H'+price.high+' L'+price.low+' C'+price.close;
        //         }
        //     }else {
        //         legendElm.current.innerText = 'BTC / USDT  Binance';
        //     }
        // });
    }

    function calculateExtremum(cb){
        function nz(x, y = null){
            if(isNaN(x)){
                if(y != null){
                    return y;
                }
                return 0;
            }
        
            return x;
        }
    
        let result = {
            ex_l: 0,
            ex_h: 0
        }


        let symbol = getVariables.current.pair;
        let strategy = getVariables.current.strategy;
        let interval = "15m";
        let limit = 1000;

        if(strategy === "1m"){
            interval = "15m";
        }

        if(strategy === "15m"){
            interval = "4h";
        }

        if(strategy === "4h"){
            interval = "12h";
        }

    
        let url = Constants.API_URL+"/api/klines.php?symbol="+symbol+"&interval="+interval+"&limit="+limit;
        
        Fetch.request(url, {}, 60000)
            .then((response) => response.json())
            .then((json) => {
                if(!Array.isArray(json)){
                    if(cb){
                        cb(result);
                    }
                    return;
                }
    
                if(json.length < 3){
                    if(cb){
                        cb(result);
                    }
                    return;
                }
    
                try{
    
                    const ATRPeriod = 28;
                    const ATRFactor = 5;
    
                    let norm_o = [];
                    let norm_h = [];
                    let norm_l = [];
                    let norm_c = [];
    
                    for(let i = 0; i < json.length; i++){
                        norm_o.push(parseFloat(json[i][1]));
                        norm_h.push(parseFloat(json[i][2]));
                        norm_l.push(parseFloat(json[i][3]));
                        norm_c.push(parseFloat(json[i][4]));
                    }
    
                    //true range calculations
                    let HiLo = [];
                    let HRef = [];
                    let LRef = [];
                    // let trueRange = [];
                    let wildMA = [];
                    let loss = [];
                    let TrendUp = [];
                    let TrendDown = [];
                    let Trend = [];
                    let ex = [];
    
                    let normHMinusNormL = [];
                    for(let i = 0; i < json.length; i++){
                        normHMinusNormL.push(norm_h[i] - norm_l[i]);
                    }
                    let mvaNormHMinusNormL = MovingAverages.ma(normHMinusNormL, ATRPeriod);
    
                    for(let i = 0; i < json.length; i++){
                        let min  = Math.min(normHMinusNormL[i], 1.5*nz(mvaNormHMinusNormL[i]));
                        HiLo.push(min);
    
                        let href = 0;
                        let lref = 0;
                        if(i > 0){
                            if(norm_l[i] <= norm_h[i-1]){
                                href = norm_h[i] - norm_c[i-1];
                            }else{
                                href = (norm_h[i] - norm_c[i-1]) - (0.5 * (norm_l[i] - norm_h[i-1]));
                            }
    
                            if(norm_h[i] >= norm_l[i-1]){
                                lref = norm_c[i-1] - norm_l[i];
                            }else{
                                lref = (norm_c[i-1] - norm_l[i]) - (0.5 * (norm_l[i-1] - norm_h[i]))
                            }
                        }
                        HRef.push(href);
                        LRef.push(lref);
                    }
    
                    for(let i = 0; i < json.length; i++){
                        // trueRange.push(Math.max(HiLo[i], HRef[i], LRef[i]));
                        let max = Math.max(HiLo[i], HRef[i], LRef[i]);
                        let lastWild = 0;
                        if(i > 0){
                            lastWild = wildMA[i-1];
                        }
    
                        let newWild = nz(lastWild) + (max - nz(lastWild)) / ATRPeriod;
                        wildMA.push(newWild);
                        let newLoss = ATRFactor * newWild;
                        loss.push(newLoss);
    
                        let newUp = 0;
                        let newDown = 0;
                        if(i > 0){
                            if(norm_c[i-1] > TrendUp[i-1]){
                                newUp = Math.max(norm_c[i] - newLoss, TrendUp[i-1]);
                            }else{
                                newUp = norm_c[i] - newLoss;
                            }
    
                            if(norm_c[i-1] < TrendDown[i-1]){
                                newDown = Math.min(norm_c[i] + newLoss, TrendDown[i-1]);
                            }else{
                                newDown = norm_c[i] + newLoss;
                            }
                        }
                        TrendUp.push(newUp);
                        TrendDown.push(newDown);
    
                        let newTrend = 0;
                        if(i > 0){
                            if(norm_c[i] > TrendDown[i-1]){
                                newTrend = 1;
                            }else if(norm_c[i] < TrendUp[i-1]){
                                newTrend = -1;
                            }else{
                                newTrend = nz(Trend[i-1], 1);
                            }
                        }
                        Trend.push(newTrend);
    
    
                        let newEx = 0;
                        if(i > 0){
                            if(Trend[i] == 1){
                                newEx = Math.max(ex[i-1], norm_h[i]);
                            }else if(Trend[i] == -1){
                                newEx = Math.min(ex[i-1], norm_l[i]);
                            }else{
                                newEx = ex[i-1];
                            }
                        }
                        ex.push(newEx);
                    }
    
                    let currentPrice = norm_c[norm_c.length-1];
                    let exHigh = 0;
                    let exLow = 0;
                    let exLowTmp = 0;
                    let exLowIndex = -1;
                    let exLowOccurance = 0;
                    
                    for(let i = json.length-1; i >= 0; i--){
                        if(Trend[i] == -1 && currentPrice > ex[i]){
                            if(exLowTmp == ex[i]){
                                exLowOccurance++;
                                if(exLowOccurance >= 4 && exLow == 0){
                                    exLow = exLowTmp;
                                }
                            }else{
                                if(exLow != 0){
                                    exLowIndex = i;
                                    break;
                                }
                                exLowTmp = ex[i];
                                exLowOccurance = 1;
                            }
                        }else{
                            if(exLow != 0){
                                exLowIndex = i;
                                break;
                            }
                            exLowTmp = 0;
                            exLowOccurance = 0;
                        }
                    }
    
                    if(exLowIndex > -1){
                        for(let i = exLowIndex; i < json.length; i++){
                            if(norm_h[i] > exHigh){
                                exHigh = norm_h[i];
                            }
                        }
                    }
    
                    result.ex_l = exLow;
                    result.ex_h = exHigh;

                    if(cb){
                        cb(result);
                    }
                    
                    console.log(result);   

                    if(exLowLine.current){
                        exLowLine.current.applyOptions({
                            price: result.ex_l,
                        });
                    }

                    if(exHighLine.current){
                        exHighLine.current.applyOptions({
                            price: result.ex_h,
                        });
                    }                 
    
                }catch(error){
                    console.log(error, "calculateExtremum");
                }
    
                if(cb){
                    cb(result);
                }
            })
            .catch((error) => {
                console.log(error, "calculateExtremum");
                if(cb){
                    cb(result);
                }
            });
    }

    function getKlines(symbol, interval, limit, cb){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/klines.php?symbol="+symbol+"&interval="+interval+"&limit="+limit;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(Array.isArray(json)){
                    let ohlcArr = [];
                    for(let i = 0; i < json.length; i++){
                        let item = json[i];
                        let time = (item[0]/1000) + 60*60*TIMEZONE_OFFSET;
                        ohlcArr.push({
                            time: time, 
                            open: parseFloat(item[1]),
                            high: parseFloat(item[2]), 
                            low: parseFloat(item[3]), 
                            close: parseFloat(item[4])
                        });
                    }
                    if(cb){
                        cb(ohlcArr);
                    }
                    return;
                }
            
                if(cb){
                   cb([]);
                }

            })
            .catch((error) => {
                // console.log(error);
                if(cb){
                    cb([]);
                }
            });
    }

    function startKlineUpdate(pair, interval){
        pair = pair.toLowerCase();
    
        var webSocketClient = new WebSocketClient("wss://stream.binance.com:9443/ws/"+pair+"@kline_"+interval);

        webSocketClient.onerror = function() {
            console.log('Kline WebSocketClient Connection Error');
            try {
                webSocketClient.close();
            } catch(e) {
                console.log(e);
            }
        };

        webSocketClient.onopen = function() {
            console.log('Kline WebSocketClient Connected');
        };

        webSocketClient.onclose = function() {
            console.log('Kline WebSocketClient Closed');
            setTimeout(startKlineUpdate, WS_CONN_RESTART_DELAY);
        };

        webSocketClient.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);

                    if(data.k){
                        let time = (data.k.t/1000) + 60*60*TIMEZONE_OFFSET;

                        if(getVariables.current.test === "0"){
                            lastKline = {
                                time: time, 
                                open: parseFloat(data.k.o),
                                high: parseFloat(data.k.h), 
                                low: parseFloat(data.k.l), 
                                close: parseFloat(data.k.c)
                            }
                            candleSeries.current.update(lastKline);
                        }else{
                            if(lastKline == null || lastKline.time !== time){
                                lastKline = {
                                    time: time, 
                                    open: parseFloat(data.k.o),
                                    high: parseFloat(data.k.o), 
                                    low: parseFloat(data.k.o), 
                                    close: parseFloat(data.k.o)
                                }
                                candleSeries.current.update(lastKline);
                            }
                        }

                    }
                    
                }
            } catch(e) {
                console.log("Kline WebSocketClient Message Error");
            }
        };
    }

    function startSymbolBookTicker(){

        let pair = getVariables.current.pair.toLowerCase();
    
        // var webSocketClient = new WebSocketClient("wss://stream.binance.com:9443/ws/!bookTicker");
        var webSocketClient = new WebSocketClient("wss://stream.binance.com:9443/ws/"+pair+"@bookTicker");

        webSocketClient.onerror = function() {
            console.log('Ticker WebSocketClient Connection Error');
            try {
                webSocketClient.close();
            } catch(e) {
                console.log(e);
            }
        };

        webSocketClient.onopen = function() {
            console.log('Ticker WebSocketClient Connected');
        };

        webSocketClient.onclose = function() {
            console.log('Ticker WebSocketClient Closed');
            setTimeout(startSymbolBookTicker, WS_CONN_RESTART_DELAY);
        };

        webSocketClient.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);

                    if(data.s){
                        if(data.s === getVariables.current.pair){
                            if(lastPriceAsk !== parseFloat(data.a)){
                                lastPriceAsk = parseFloat(data.a);
                                legendElm2.current.innerText = Util.formatNumber(lastPriceAsk, 0, priceFormat.current.precision);
                                if(lastKline != null){
                                    lastKline.close = lastPriceAsk;
                                    if(lastKline.close > lastKline.high){
                                        lastKline.high = lastKline.close;
                                    }
                                    if(lastKline.close < lastKline.low){
                                        lastKline.low = lastKline.close;
                                    }
                                    candleSeries.current.update(lastKline);
                                }
                                
                            }
                        }                    
                    }
                    
                }
            } catch(e) {
                console.log("Ticker WebSocketClient Message Error");
            }
        };
    }

    function startLocalWsConnection(){
    
        puWsConnLocal.current = new WebSocketClient("wss://at.ptx.ro/ws/");

        puWsConnLocal.current.onerror = function() {
            console.log('Local WebSocketClient Connection Error');
            try {
                puWsConnLocal.current.close();
            } catch(e) {
                console.log(e);
            }
        };

        puWsConnLocal.current.onopen = function() {
            console.log('Local WebSocketClient Connected');
        };

        puWsConnLocal.current.onclose = function() {
            console.log('Local WebSocketClient Closed');
            setTimeout(startLocalWsConnection, WS_CONN_RESTART_DELAY);
        };

        puWsConnLocal.current.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);
                    if(data.event && data.event === "STATE_UPDATE"){
                        if(data.strategy && data.strategy === getVariables.current.strategy){
                            if(data.state[getVariables.current.pair]){
                                if(slot.current == null){
                                    // window.location.reload(false);
                                }else{
                                    onSlotUpdate(data.state[getVariables.current.pair]);
                                }
                            }else{
                                if(slot.current != null){
                                    // window.location.reload(false);
                                }
                            }
                        }
                    }
                }
            } catch(e) {
                console.log("Local WebSocketClient Message Error");
            }
        };
    }

    function onSlotUpdate(newSlot){
        if(slot.current == null){
            return;
        }

        //tp lines
        if(newSlot.target_price){
            if(newSlot.target_price !== slot.current.target_price){
                tpLine.current.applyOptions({
                    price: newSlot.target_price,
                });

                if(sellType.current === "MARKET"){
                    let sell_price = newSlot.sell_price;
                    if(sell_price == null){
                        sell_price = newSlot.target_price - (newSlot.trail_sell_price_percent/100*newSlot.target_price);
                    }

                    spLine.current.applyOptions({
                        price: sell_price,
                    });
                }
            }
        }
        

        //buy lines
        // if(newSlot.buy_count !== slot.current.buy_count){
        //     for(let i = 0; i < buyLines.current.length; i++){
        //         if(i < newSlot.buy_count){
        //             buyLines.current[i].applyOptions({
        //                 color: "rgba(88, 114, 128, 0.5)",
        //             });
        //         }
        //     }
        // }

        if(buyLines.current.length > 0){
            for(let i = 0; i < newSlot.buy_steps.length; i++){
                let priceLimit = newSlot.buy_steps[i];
                let percent = Math.abs((priceLimit * 100 / newSlot.price_initial)-100);
                percent = percent.toFixed(2);
                percent = parseFloat(percent);

                let color = "rgba(124, 186, 225, 0.7)";
                if(i < newSlot.buy_count){
                    color = "rgba(88, 114, 128, 0.9)";
                }

                let buyCountLabel = "";
                if(i+1 === 1){
                    buyCountLabel = "1st";
                }
                if(i+1 === 2){
                    buyCountLabel = "2nd";
                }
                if(i+1 === 3){
                    buyCountLabel = "3rd";
                }
                if(i+1 > 3){
                    buyCountLabel = (i+1)+"th";
                }
                let title = buyCountLabel;
                if(i+1 > 1){
                    title = buyCountLabel+" -"+percent+"%";
                }

                buyLines.current[i].applyOptions({
                    price: priceLimit,
                    color: color,
                    axisLabelVisible: true,
                    title: title,
                });
            }
        }

        //markers
        if(newSlot.buy_date_arr.length !== slot.current.buy_date_arr.length || (newSlot.last_sell_id != null && slot.current.last_sell_id == null)){
            let markersArr = [];

            for(let i = 0; i < newSlot.buy_date_arr.length; i++){
                let buyCountLabel = "";
                if(i+1 === 1){
                    buyCountLabel = "1st";
                }
                if(i+1 === 2){
                    buyCountLabel = "2nd";
                }
                if(i+1 === 3){
                    buyCountLabel = "3rd";
                }
                if(i+1 > 3){
                    buyCountLabel = (i+1)+"th";
                }

                // let time = newSlot.buy_date_arr[i] + 60*60*TIMEZONE_OFFSET;
                // let date = new Date(time * 1000);
                // date.setSeconds(0);
                // time = date.getTime()/1000;
                // time = time - 60;
                let time = normalizeCandleTime(newSlot.buy_date_arr[i]);

                markersArr.push({
                    time: time,
                    position: 'belowBar',
                    color: 'rgba(255, 224, 178, 0.85)',
                    shape: 'arrowUp',
                    text: buyCountLabel+' Buy',
                });
            }

            if(newSlot.last_sell_id != null && slot.current.last_sell_id == null){
                // let time = newSlot.final_sell_date + 60*60*TIMEZONE_OFFSET;
                // let date = new Date(time * 1000);
                // date.setSeconds(0);
                // time = date.getTime()/1000;
                // time = time - 60;
                let time = normalizeCandleTime(newSlot.final_sell_date);

                let totalInvested = 0;
                let totalAmount = 0;
                for(let i = 0; i < newSlot.buy_arr.length; i++){
                    let price = parseFloat(newSlot.buy_price_arr[i]);
                    let amount = parseFloat(newSlot.buy_amount_arr[i]);

                    totalInvested = totalInvested + (price*amount);
                    totalAmount = totalAmount + amount;
                }

                let win = totalAmount*parseFloat(newSlot.final_sell_price);
                let profit = ((win*100)/totalInvested)-100;
                if(profit > 0){
                    profit = "+"+profit.toFixed(2);
                }else{
                    profit = profit.toFixed(2);
                }

                markersArr.push({
                    time: time,
                    position: 'belowBar',
                    color: 'rgba(255, 224, 178, 0.85)',
                    shape: 'arrowUp',
                    text: "Sell ⚡️ "+profit+"%",
                });
            }

            if(candleSeries.current != null){
                candleSeries.current.setMarkers(markersArr);
            }
        }

    
        slot.current = newSlot;
    }

    function onChartResize(){
        if(chartElm.current == null){
            return;
        }
        let width = chartElm.current.offsetWidth;
        let height = chartElm.current.offsetHeight;
        if(chart.current){
            chart.current.resize(width, height);
        }
    }

    function startUpdateingLegend(){
        updateLegendElm();

        setTimeout(startUpdateingLegend, 1000);
    }

    function updateLegendElm(){
        // BTC / USDT ∙ 1 ∙ Binance ∙ TTP: ▲ 1.46% ∙ NB: ▼ 1.96% ∙ BE: 49650.21 ▲ 0.27% ∙ P: +0.51%

        let text = "";
        if(getVariables.current.strategy !== ""){
            text += "S"+getVariables.current.strategy+" ∙ ";
        }

        text += getVariables.current.coin1+" / "+getVariables.current.coin2+" ∙ ";
        text += getVariables.current.interval+" ∙ Binance";

        if(slot.current){
            //target price
            if(lastPriceAsk === 0){
                text += " ∙ "+(sellType.current === "MARKET" ? "TTP" : "TP")+": ▲ 0% ∙ ";
            }else{
                let ttp = (lastPriceAsk * 100 / slot.current.target_price)-100;
                ttp = ttp.toFixed(2);
                text += " ∙ "+(sellType.current === "MARKET" ? "TTP" : "TP")+": ▲ "+ttp+"% ∙ ";
            }

            //next buy
            if(lastPriceAsk === 0 || slot.current.next_buy_price === 0){
                text += "NB: ▼ 0% ∙ ";
            }else{
                let nb = (slot.current.next_buy_price * 100 / lastPriceAsk)-100;
                nb = Math.abs(nb);
                nb = nb.toFixed(2);
                text += "NB: ▼ "+nb+"% ∙ ";
            }

            //break even
            let totalInvested = 0;
            let totalAmount = 0;
            for(let i = 0; i < slot.current.buy_arr.length; i++){
                let price = parseFloat(slot.current.buy_price_arr[i]);
                let amount = parseFloat(slot.current.buy_amount_arr[i]);

                totalInvested = totalInvested + (price*amount);
                totalAmount = totalAmount + amount;
            }

            let be = totalInvested / totalAmount;
            // be = Util.toFixed(be, priceFormat.current.precision);
            be = Util.formatNumber(be, 0, priceFormat.current.precision);
            text += "BE: "+be+" ∙ ";

            //profit
            if(lastPriceAsk === 0){
                text += "P: 0%";
            }else{
                let win = totalAmount*lastPriceAsk;
                let profit = ((win*100)/totalInvested)-100;
                if(profit > 0){
                    profit = "+"+profit.toFixed(2);
                }else{
                    profit = profit.toFixed(2);
                }

                text += "P: "+profit+"%";
            }

        }


        if(legendElm.current){
            legendElm.current.innerText = text;
        }
        
    }

    function normalizeCandleTime(time){
        time = time + 60*60*TIMEZONE_OFFSET;

        let substractTime = 0; //in seconds
        let interval = getVariables.current.interval;

        if(interval === "1m"){
            substractTime = 60;
        }

        if(interval === "3m"){
            substractTime = 60*3;
        }

        if(interval === "5m"){
            substractTime = 60*5;
        }

        if(interval === "15m"){
            substractTime = 60*15;
        }

        if(interval === "30m"){
            substractTime = 60*30;
        }

        if(interval === "1h"){
            substractTime = 60*60;
        }

        if(interval === "2h"){
            substractTime = 60*60*2;
        }

        if(interval === "4h"){
            substractTime = 60*60*4;
        }

        if(interval === "6h"){
            substractTime = 60*60*6;
        }

        if(interval === "8h"){
            substractTime = 60*60*8;
        }

        if(interval === "12h"){
            substractTime = 60*60*12;
        }

        if(interval === "1d"){
            substractTime = 60*60*24;
        }

        if(interval === "3d"){
            substractTime = 60*60*24*3;
        }

        if(interval === "1w"){
            substractTime = 60*60*24*7;
        }

        if(interval === "1M"){
            substractTime = 60*60*24*31;
        }

        return time - substractTime;
    }

    function activateTrailing(){
        setTrailingDialog(false);

        if(!slot.current){
            return;
        }

        if(slot.current.sell_price != null){
            return;
        }

        try {
            if(puWsConnLocal.current != null){
                let json = {
                    source: "WEB_APP",
                    key: "WEB_APP",
                    data:{
                        event: "SLOT_TRAILING_PLAY",
                        strategy: getVariables.current.strategy,
                        pair: getVariables.current.pair,
                    }
                }
                puWsConnLocal.current.send(JSON.stringify(json));
            }
        } catch(e) {
            console.log(e);
        }
    }

    function sellCoin(){
        setSellDialog(false);

        if(!slot.current){
            return;
        }

        try {
            if(puWsConnLocal.current != null){
                let json = {
                    source: "WEB_APP",
                    key: "WEB_APP",
                    data:{
                        event: "SLOT_SELL",
                        strategy: getVariables.current.strategy,
                        pair: getVariables.current.pair,
                    }
                }
                puWsConnLocal.current.send(JSON.stringify(json));
            }
        } catch(e) {
            console.log(e);
        }
    }

    function onTradeStatus(res){
        setStatusTrade(res.status);
        setSnackbarTradeOpen(true);
    }

    function handleSnackbarTradeClose(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarTradeOpen(false);
    }

    function newBuy(){
        setTradeDialogOpen(true);
        setSideTrade("buy");
    }

    function newSell(){
        setTradeDialogOpen(true);
        setSideTrade("sell");
    }

    function onChangeBuySteps(newBuySteps){
        setBuyStepsDialog(false);

        if(!slot.current){
            return;
        }

        try {
            if(puWsConnLocal.current != null){
                let json = {
                    source: "WEB_APP",
                    key: "WEB_APP",
                    data:{
                        event: "UPDATE_BUY_STEPS",
                        strategy: getVariables.current.strategy,
                        pair: getVariables.current.pair,
                        buy_steps: newBuySteps
                    }
                }
                puWsConnLocal.current.send(JSON.stringify(json));
            }
        } catch(e) {
            console.log(e);
        }
    }

    function onChangeTTP(result){
        setTtpDialog(false);

        if(!slot.current){
            return;
        }

        try {
            if(puWsConnLocal.current != null){
                let json = {
                    source: "WEB_APP",
                    key: "WEB_APP",
                    data:{
                        event: "UPDATE_TTP",
                        strategy: getVariables.current.strategy,
                        pair: getVariables.current.pair,
                        ttp_type: result.ttp_type,
                        profit: result.profit,
                        stop_loss: result.stop_loss,
                    }
                }
                puWsConnLocal.current.send(JSON.stringify(json));
            }
        } catch(e) {
            console.log(e);
        }
    }

    if(loading){
        return (<Loader />);
    }

    let coin1 = getVariables.current.coin1;
    let coin2 = getVariables.current.coin2;
    let pair = coin1+"_"+coin2;
    let strategy = getVariables.current.strategy;
    let interval = getVariables.current.interval;

    let linkInervalCss = {
        "1m": "",
        "15m": "",
        "1h": "",
        "4h": "",
        "12h": "",
        "1d": "",
    }
    linkInervalCss[interval] = styles.active;


    let trailPlayIcoColor = "rgba(255,255,255,0.4)";
    let trailPlayBtnCss = "";

    if(slot.current){
        if(slot.current.sell_price != null){
            trailPlayBtnCss = styles.active;
            trailPlayIcoColor = "rgba(239,186,12,0.8)";
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.chart} ref={chartElm}>

                    <div className={styles.topFixed}>
                        
                        <div className={styles.menu}>
                            <a className={styles.btn+" "+linkInervalCss["1m"]} href={"/chart?pair="+pair+"&interval=1m&strategy="+strategy}>
                                <div className={styles.bg}></div>
                                <div className={styles.txt}>1m</div>
                            </a>
                            <a className={styles.btn+" "+linkInervalCss["15m"]} href={"/chart?pair="+pair+"&interval=15m&strategy="+strategy}>
                                <div className={styles.bg}></div>
                                <div className={styles.txt}>15m</div>
                            </a>
                            <a className={styles.btn+" "+linkInervalCss["1h"]} href={"/chart?pair="+pair+"&interval=1h&strategy="+strategy}>
                                <div className={styles.bg}></div>
                                <div className={styles.txt}>1h</div>
                            </a>
                            <a className={styles.btn+" "+linkInervalCss["4h"]} href={"/chart?pair="+pair+"&interval=4h&strategy="+strategy}>
                                <div className={styles.bg}></div>
                                <div className={styles.txt}>4h</div>
                            </a>
                            <a className={styles.btn+" "+linkInervalCss["12h"]} href={"/chart?pair="+pair+"&interval=12h&strategy="+strategy}>
                                <div className={styles.bg}></div>
                                <div className={styles.txt}>12h</div>
                            </a>
                            <a className={styles.btn+" "+linkInervalCss["1d"]} href={"/chart?pair="+pair+"&interval=1d&strategy="+strategy}>
                                <div className={styles.bg}></div>
                                <div className={styles.txt}>1d</div>
                            </a>


                            {/* {slot.current != null ? */}
                            <div className={styles.btn+" "+styles.btnSwitch} onClick={()=>{setBuyStepsDialog(true)}}>
                                <div className={styles.bg}></div>
                                <div className={styles.ico}><IcoBuySteps fill="rgba(255,255,255,0.4)" /></div>
                                <div className={styles.txt}>Buy Steps</div>
                            </div>
                            {/* : null} */}

                            {/* {slot.current != null ? */}
                            <div className={styles.btn+" "+styles.btnSwitch} onClick={()=>{setTtpDialog(true)}}>
                                <div className={styles.bg}></div>
                                <div className={styles.ico}><IcoTTP fill="rgba(255,255,255,0.4)" /></div>
                                <div className={styles.txt}>{sellType.current === "MARKET" ? "TTP":"TP"}</div>
                            </div>
                            {/* : null} */}

                            {/* {slot.current != null && sellType.current === "MARKET" ? */}
                            <div className={styles.btn+" "+styles.btnSwitch+" "+trailPlayBtnCss} onClick={()=>{setTrailingDialog(true)}}>
                                <div className={styles.bg}></div>
                                <div className={styles.ico}><IcoPlay fill={trailPlayIcoColor} style={{height: 17}} /></div>
                                <div className={styles.txt}>Trailing</div>
                            </div>
                            {/* : null} */}

                            {/* {slot.current != null ? */}
                            <div className={styles.btn+" "+styles.btnSwitch} onClick={newSell}>
                                <div className={styles.bg}></div>
                                <div className={styles.ico}><IcoOut fill="rgba(255,255,255,0.4)" style={{height: 18}} /></div>
                                <div className={styles.txt}>Sell</div>
                            </div>
                            {/* : null} */}

                            {/* {slot.current != null ? */}
                            <div className={styles.btn+" "+styles.btnSwitch} onClick={()=>{setSellDialog(true)}}>
                                <div className={styles.bg}></div>
                                <div className={styles.ico}><IcoOut fill="rgba(255,255,255,0.4)" style={{height: 18}} /></div>
                                <div className={styles.txt}>Sell Market</div>
                            </div>
                            {/* : null} */}

                            {/* {slot.current != null ? */}
                            <div className={styles.btn+" "+styles.btnSwitch} onClick={newBuy}>
                                <div className={styles.bg}></div>
                                <div className={styles.ico}><IcoIn fill="rgba(255,255,255,0.4)" style={{height: 18}} /></div>
                                <div className={styles.txt}>Buy</div>
                            </div>
                            {/* : null} */}
                        </div>  

                        <div className={styles.legend}>
                            <div ref={legendElm}></div>  
                            <div className={styles.price} ref={legendElm2}>0.00</div>
                        </div>  

                    </div> 

                </div>
                
            </div>

            <ConfirmDialog 
                open={trailingDialog} 
                title="Trailing" 
                text={"Activate trailing for "+coin1+" / "+coin2+" ?"} 
                okLabel="Start Trailing" 
                cancelLabel="Cancel" 
                onOk={activateTrailing} 
                onCancel={()=>{setTrailingDialog(false)}} />    

            <ConfirmDialog open={sellDialog} title="Sell" text={"Sell market "+coin1+" -> "+coin2+" ?"} okLabel="Sell" cancelLabel="Cancel" onOk={sellCoin} onCancel={()=>{setSellDialog(false)}} />  
        
            <TradeDialog 
                open={tradeDialogOpen} 
                strategy={getVariables.current.strategy} 
                side={sideTrade} 
                coin1={getVariables.current.coin1}
                coin2={getVariables.current.coin2}
                dynamic={false}
                onStatus={onTradeStatus} 
                onCancel={()=>{setTradeDialogOpen(false)}} 
                onOk={()=>{setTradeDialogOpen(false)}} 
            />  

            <Snackbar open={snackbarTradeOpen} autoHideDuration={6000} onClose={handleSnackbarTradeClose}>
                <Alert onClose={handleSnackbarTradeClose} severity={statusTrade} sx={{ width: '100%' }}>
                    <span style={{textTransform: "capitalize"}}>{sideTrade}</span> {statusTrade === "success" ? "completed successfully." : "failed."}
                </Alert>
            </Snackbar>



            <BuyStepsDialog 
                open={buyStepsDialog} 
                strategy={getVariables.current.strategy} 
                coin1={getVariables.current.coin1}
                coin2={getVariables.current.coin2}
                buy_steps={slot.current != null ? slot.current.buy_steps : []}
                buy_count={slot.current != null ? slot.current.buy_count : 0}
                price_precision={priceFormat.current.precision}
                price_move={priceFormat.current.minMove}
                onCancel={()=>{setBuyStepsDialog(false)}} 
                onOk={onChangeBuySteps} 
            />  

            <TTPDialog 
                open={ttpDialog} 
                strategy={getVariables.current.strategy} 
                coin1={getVariables.current.coin1}
                coin2={getVariables.current.coin2}
                price_precision={priceFormat.current.precision}
                price_move={priceFormat.current.minMove}

                buy_price_arr={slot.current != null ? slot.current.buy_price_arr : []}
                buy_amount_arr={slot.current != null ? slot.current.buy_amount_arr : []}

                ttp_type={slot.current != null ? slot.current.trail_activation_type : "PROFIT_PERCENT"}
                exact_price={slot.current != null ? slot.current.trail_activation_price : 0}
                profit_percent={slot.current != null ? (Array.isArray(slot.current.trail_activation_percent) ? slot.current.trail_activation_percent[0] : slot.current.trail_activation_percent) : 0}
                stop_loss={slot.current != null ? slot.current.trail_sell_price_percent : 0}

                onCancel={()=>{setTtpDialog(false)}} 
                onOk={onChangeTTP} 

                sellType={sellType.current}
            /> 
        
        
        </div>
    );
}

export default Chart;


