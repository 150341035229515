import PropTypes from 'prop-types';

function IcoBlock(props){
    return(
        // <svg {...props} width="17px" height="17px" viewBox="0 0 17 17">
        //     <path d="M4.75,7.95 L4.75,9.55 L12.75,9.55 L12.75,7.95 L4.75,7.95 Z M8.75,0.75 C4.334,0.75 0.75,4.334 0.75,8.75 C0.75,13.166 4.334,16.75 8.75,16.75 C13.166,16.75 16.75,13.166 16.75,8.75 C16.75,4.334 13.166,0.75 8.75,0.75 Z M8.75,15.15 C5.222,15.15 2.35,12.278 2.35,8.75 C2.35,5.222 5.222,2.35 8.75,2.35 C12.278,2.35 15.15,5.222 15.15,8.75 C15.15,12.278 12.278,15.15 8.75,15.15 Z"></path>
        // </svg>

        <svg {...props} width="16px" height="19px" viewBox="0 0 16 19">
            <path d="M1.68421053,16 L1.68421053,7.57894737 L11.7894737,7.57894737 L11.7894737,8.42105263 C12.3789474,8.42105263 12.9431579,8.50526316 13.4736842,8.66526316 L13.4736842,7.57894737 C13.4736842,6.65263158 12.7157895,5.89473684 11.7894737,5.89473684 L10.9473684,5.89473684 L10.9473684,4.21052632 C10.9473684,1.88631579 9.06105263,0 6.73684211,0 C4.41263158,0 2.52631579,1.88631579 2.52631579,4.21052632 L2.52631579,5.89473684 L1.68421053,5.89473684 C0.757894737,5.89473684 0,6.65263158 0,7.57894737 L0,16 C0,16.9263158 0.757894737,17.6842105 1.68421053,17.6842105 L6.95578947,17.6842105 C6.60210526,17.1789474 6.32421053,16.6063158 6.13894737,16 L1.68421053,16 Z M4.21052632,4.21052632 C4.21052632,2.81263158 5.33894737,1.68421053 6.73684211,1.68421053 C8.13473684,1.68421053 9.26315789,2.81263158 9.26315789,4.21052632 L9.26315789,5.89473684 L4.21052632,5.89473684 L4.21052632,4.21052632 Z" id="Shape"></path>
            <path d="M11.7894737,10.1052632 C9.46526316,10.1052632 7.57894737,11.9915789 7.57894737,14.3157895 C7.57894737,16.64 9.46526316,18.5263158 11.7894737,18.5263158 C14.1136842,18.5263158 16,16.64 16,14.3157895 C16,11.9915789 14.1136842,10.1052632 11.7894737,10.1052632 Z M13.1789474,16.2947368 L11.3684211,14.4842105 L11.3684211,11.7894737 L12.2105263,11.7894737 L12.2105263,14.1389474 L13.7684211,15.6968421 L13.1789474,16.2947368 Z" id="Shape"></path>
        </svg>
    );
}

export default IcoBlock;