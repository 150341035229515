import React, {useEffect, useState, useRef} from 'react';
import {useLocation} from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Loader from "../../components/Loader";
import ItemBalance from "../../components/ItemBalance";
import ItemTrade from "../../components/ItemTrade";
import ItemTrail from "../../components/ItemTrail";
import ItemBalanceHistory from "../../components/ItemBalanceHistory";
import ToggleInput from "../../components/ToggleInput";
import TradeDialog from '../../components/TradeDialog';
import LogoutLink from '../../components/LogoutLink';
import ItemCollapsible from "../../components/ItemCollapsible";
import ItemSlot from "../../components/ItemSlot";
import ConfirmDialog from "../../components/ConfirmDialog";
import SpecsInput from "../../components/SpecsInput";
import SpecsInputDialog from "../../components/SpecsInputDialog";

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import IcoArrow from '../../icons/IcoArrow';
import IcoX from '../../icons/IcoX';
import IcoRemove from '../../icons/IcoRemove';
import IcoDolar from '../../icons/IcoDolar';

import styles from './Local.module.css';


const WebSocketClient = require('websocket').w3cwebsocket;

const WS_CONN_RESTART_DELAY = 1000;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
}));

const puDayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const SPECS = {
    "1m":{"symbol":"ABC","wt1":"50","wt2":"46","ttp":"1.8","sl":"0.5","vol_usdt":"150","buy_price1":0,"buy_price2":"1.5","buy_price3":"3","buy_price4":"7","buy_price5":"8.5","lp":true,"sma99":true,"lp_candles":"6"}
}

function Local() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [timeline, setTimeline] = useState("day");

    // const [tickers, setTickers] = useState({});
    // const [exchangeInfo, setExchangeInfo] = useState({});
    // const [userTrades, setUserTrades] = useState([]);
    const tickers = useRef({});
    const balances = useRef({});
    const exchangeInfo = useRef({});
    const exchangeInfoFull = useRef({});
    const userTrades = useRef([]);


    const [userBalances, setUserBalances] = useState([]);
    // const [userTrails, setUserTrails] = useState([]);
    const [userBalanceHistory, setUserBalanceHistory] = useState([]);

    const [systemStatus, setSystemStatus] = useState("");
    const [exitReasons, setExitReasons] = useState([]);
    const [masterSwitch, setMasterSwitch] = useState(false);
    const [extraSlot, setExtraSlot] = useState(false);

    

    const strategy = useRef("");
    const sellType = useRef("MARKET");

    const [hasSpecifications, setHasSpecifications] = useState(false);


    const [forceUpdate, setForceUpdate] = useState(0);
    const updateTimer = useRef(null);


    const [tradeDialogOpen, setTradeDialogOpen] = useState(false);
    const [snackbarTradeOpen, setSnackbarTradeOpen] = useState(false);
    const [statusTrade, setStatusTrade] = useState("");
    const [sideTrade, setSideTrade] = useState("buy");

    const puWsConnLocal = useRef(null);

    const [collapsedSlots, setCollapsedSlots] = useState(true);

    const [slots, setSlots] = useState({});
    const [selectedPair, setSelectedPair] = useState("");
    const [removeSlotDialogOpen, setRemoveSlotDialogOpen] = useState(false);

    const [specsDialogOpen, setSpecsDialogOpen] = useState(false);
    const [specs, setSpecs] = useState({});

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarStatus, setSnackbarStatus] = useState("");
    const [snackbarText, setSnackbarText] = useState("");

    const [selectedSymbol, setSelectedSymbol] = useState({
        symbol: "",
        orderId: "",
        coin1: "",
        coin2: "",
    });

    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const openMenu = Boolean(anchorElMenu);

    const [confirmCancelOrderDialog, setConfirmCancelOrderDialog] = useState(false);
    const [confirmCancelAllDialog, setConfirmCancelAllDialog] = useState(false);


    useEffect(()=>{
        strategy.current = location.pathname.replace(/\//g, "").trim();

        if(strategy.current === "1mi"){
            setHasSpecifications(true);
        }

        if(strategy.current === "cc"){
            document.title = "S CC - Trade Bot";
        }else{
            document.title = "S"+strategy.current+" - Trade Bot";
        }

        if(strategy.current === "1m"
        || strategy.current === "1m2"
        || strategy.current === "1mi"){
            sellType.current = "LIMIT";
        }

        if(!localStorage["timeline_"+strategy.current]) {
            localStorage["timeline_"+strategy.current] = "day";
        }
        setTimeline(localStorage["timeline_"+strategy.current]);

        getData(()=>{
            startLocalWsConnection();
            startSymbolBookTicker();
        });
        getSystemStatus();

        let poolSystemStatus = setInterval(()=>{
            getSystemStatus();
        }, 10000);

        updateTimer.current = setInterval(()=>{
            setForceUpdate(Date.now());
        }, 5000);

        return ()=>{
            //console.log("unmount");
            clearInterval(poolSystemStatus);

            if(updateTimer.current !== null){
                clearInterval(updateTimer.current);
                updateTimer.current = null;
            }
        }
    }, []);

    function getSystemStatus(){

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/systemStatus.php?strategy="+strategy.current;
  
        Fetch.request(url, options, 9000)
            .then((response) => response.json())
            .then((json) => {    

                
                if(json.status === "ok"){
                    // let reason = json.exit_reasons;
                    // reason = reason.replace("Bot stopped:", "");
                    // reason = reason.trim();

                    for(let i = 0; i < json.exit_reasons.length; i++){
                        let item = json.exit_reasons[i];

                        item.txt0 = "";
                        item.txt1 = "";
                        item.txt2 = "";

                        let text_arr = item.reason.split("Technical error:");
                        if(text_arr.length > 1){
                            text_arr = text_arr[1];
                        }

                        text_arr = text_arr.split("%0A");

                        if(text_arr[0]){
                            item.txt0 = text_arr[0].trim();
                        }

                        if(text_arr[1]){
                            item.txt1 = text_arr[1].trim();
                        }

                        if(text_arr[2]){
                            item.txt2 = text_arr[2].trim();
                        }
                    }
                    
                    setSystemStatus(json.system_status);
                    setExitReasons(json.exit_reasons);
                }
                

            })
            .catch((error) => {
                // console.log(error);
            });

    }


    function getData(cb){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/local.php?timeline="+localStorage["timeline_"+strategy.current]+"&strategy="+strategy.current;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                //exchange info
                let exchange_info_obj = {};
                if(json.exchange_info.symbols && Array.isArray(json.exchange_info.symbols)){    
                    exchangeInfoFull.current = json.exchange_info;
                    let symbols = json.exchange_info.symbols;
                    for(let i = 0; i < symbols.length; i++){
                        let symbol = symbols[i].symbol;
                        let filters = symbols[i].filters;
    
                        exchange_info_obj[symbol] = {};
                        exchange_info_obj[symbol].can_trade = false;
                        exchange_info_obj[symbol].coin1 = symbols[i].baseAsset;
                        exchange_info_obj[symbol].coin2 = symbols[i].quoteAsset;
    
                        if(symbols[i].status === "TRADING" && symbols[i].isSpotTradingAllowed === true){
                            if(symbols[i].orderTypes.indexOf('LIMIT') !== -1 && symbols[i].orderTypes.indexOf('MARKET') !== -1){
                                exchange_info_obj[symbol].can_trade = true;
                            }
                        }
    
                        for(let j = 0; j < filters.length; j++){
                            let filter = filters[j];
    
                            if(filter.filterType === "PRICE_FILTER"){
                                exchange_info_obj[symbol].min_trade_price = parseFloat(filter.minPrice);
                            }
    
                            if(filter.filterType === "LOT_SIZE"){
                                exchange_info_obj[symbol].min_trade_quantity = parseFloat(filter.minQty);
                            }
    
                            if(filter.filterType === "MIN_NOTIONAL"){
                                exchange_info_obj[symbol].min_trade_value = parseFloat(filter.minNotional);
                            }
                        }
                    } 
                }

                exchangeInfo.current = exchange_info_obj;

                
                //tickers
                var tickersNew = {};

                for(let i = 0; i < json.tickers.length; i++){
                    var ticker = json.tickers[i];
                    tickersNew[ticker.symbol] = parseFloat(ticker.price)
                }

                tickers.current = tickersNew;

                //balances
                // var balancesNew = {};

                // for(let i = 0; i < json.balances.balances.length; i++){
                //     var balance = json.balances.balances[i];
                //     balancesNew[balance.asset] = {
                //         free: parseFloat(balance.free),
                //         locked: parseFloat(balance.locked)
                //     }
                // }

                balances.current = JSON.parse(json.balances);


                //master switch
                if(json.master_switch === "1"){
                    setMasterSwitch(true)
                }else{
                    setMasterSwitch(false)
                }

                //extra slot
                if(json.extra_slot === "1"){
                    setExtraSlot(true)
                }else{
                    setExtraSlot(false)
                }


                //specs
                setSpecs(JSON.parse(json.specs));


                //user balances
                processUserBalances();


                //user trades
                let userTradesNew = [];
                json.open_orders = JSON.parse(json.open_orders);

                for(let i = 0; i < json.open_orders.length; i++){
                    let order = json.open_orders[i];

                    userTradesNew.push({
                        id: order.orderId.toString(),
                        time: order.time,
                        symbol: order.symbol,
                        type: order.type,
                        side: order.side,
                        price: parseFloat(order.price),
                        quantity: parseFloat(order.origQty),
                        filled_procent: parseInt((parseFloat(order.executedQty)/parseFloat(order.origQty))*100),
                        total_usdt: parseFloat(order.price)*parseFloat(order.origQty),

                        coin1: exchangeInfo.current[order.symbol] ? exchangeInfo.current[order.symbol].coin1 : "",
                        coin2: exchangeInfo.current[order.symbol] ? exchangeInfo.current[order.symbol].coin2 : "",

                        source: order.clientOrderId.startsWith(strategy.current) ? "bot" : "manual"
                    });
                }

                userTrades.current = userTradesNew;


                //user trails
                setSlots(json.trades);

                //user balance history
                let userBalanceHistory = [];

                for(let i = 0; i < json.balances_history.length; i++){
                    let balance = json.balances_history[i];
                    let balanceNext = null;
                    if(json.balances_history[i+1]){
                        balanceNext = json.balances_history[i+1];
                    }

                    let date_obj = new Date(parseInt(balance.date_create_ts)*1000);
                    let day = puDayOfWeek[date_obj.getDay()];
                    let date = balance.date_create;
                    let symbol = balance.symbol;
                    let amount = parseInt(balance.amount);
                    let revenue = 0;
                    if(balanceNext){
                        revenue = amount - parseInt(balanceNext.amount);
                    }
                    let profit = parseInt(balance.profit);

                    userBalanceHistory.push({
                        day: day,
                        date: date,
                        symbol: symbol,
                        amount: amount,
                        revenue: revenue,
                        profit: profit,
                    });
                }

                setUserBalanceHistory(userBalanceHistory);

                setLoading(false); 

                if(cb){
                    cb();
                }
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function processUserBalances(){
        let userBalances = [];
        let coins = Object.keys(balances.current);

        for(let i = 0; i < coins.length; i++){
            let coin = coins[i];

            let symbol = coin+"USDT";
            let balanceFree = 0;
            let balanceLocked = 0;

            let priceDecimals = 0;
            let quantityDecimals = 0;

            if(symbol === "USDTUSDT"){
                balanceFree = balances.current[coin].free;
                balanceLocked = balances.current[coin].locked;
            }else if(tickers.current[symbol]){
                balanceFree = tickers.current[symbol] * balances.current[coin].free;
                balanceLocked = tickers.current[symbol] * balances.current[coin].locked;

                if(exchangeInfo.current[symbol]){
                    priceDecimals = Util.getDecimalsCount(exchangeInfo.current[symbol].min_trade_price);
                    quantityDecimals = Util.getDecimalsCount(exchangeInfo.current[symbol].min_trade_quantity);
                }
            }else{
                continue;
            }

            let balanceTotal = balanceFree+balanceLocked;

            if(balanceTotal < 10 && coin !== "USDT" && coin !== "BNB"){ //dolars
                continue;
            }

            //for bnb
            let symbol2 = coin+"BNB";
            let balanceFree2 = 0;
            let balanceLocked2 = 0;

            if(symbol2 === "BNBBNB"){
                balanceFree2 = balances.current[coin].free;
                balanceLocked2 = balances.current[coin].locked;
            }else if(tickers.current[symbol2]){
                balanceFree2 = tickers.current[symbol2] * balances.current[coin].free;
                balanceLocked2 = tickers.current[symbol2] * balances.current[coin].locked;
            }

            let balanceTotal2 = balanceFree2+balanceLocked2;

            let balanceObj = {
                coin: coin,

                free: balances.current[coin].free,
                locked: balances.current[coin].locked,
                total: balances.current[coin].free+balances.current[coin].locked,

                free_usdt: balanceFree,
                locked_usdt: balanceLocked,
                total_usdt: balanceTotal,

                free_bnb: balanceFree2,
                locked_bnb: balanceLocked2,
                total_bnb: balanceTotal2,

                price_decimals: priceDecimals,
                quantity_decimals: quantityDecimals
            }

            if(coin === "BNB" || coin === "USDT"){
                userBalances.unshift(balanceObj);
            }else{
                userBalances.push(balanceObj);
            }
            
        }

        for(let i = 0; i < userBalances.length; i++){
            let item = userBalances[i];
            if(item.coin === "BNB"){
                continue;
            }

            let symbol = item.coin+"BNB";

            for(let j = 0; j < exchangeInfoFull.current.symbols.length; j++){
                let item2 = exchangeInfoFull.current.symbols[j];

                if(item2.symbol === symbol){
                    if(item2.status !== "TRADING"){
                        item.free_bnb = 0;
                        item.locked_bnb = 0;
                        item.total_bnb = 0;
                    }
                }
                
            }
        }

        setUserBalances(userBalances);
    }

    function clearLog(id){

        let newExitReasons = [...exitReasons];
        for(let i = 0; i < newExitReasons.length; i++){
            if(newExitReasons[i].id === id){
                newExitReasons.splice(i, 1);
                break;
            }
        }
        setExitReasons(newExitReasons);

        let url = Constants.API_URL+"/api/clearLog.php?id="+id;
  
        Fetch.request(url)
            .then((response) => response.json())
            .then((json) => {            

            })
            .catch((error) => {
                alert("Error");
            });
    }

    function startSymbolBookTicker(){
    
        var webSocketClient = new WebSocketClient("wss://stream.binance.com:9443/ws/!bookTicker");

        webSocketClient.onerror = function() {
            console.log('Ticker WebSocketClient Connection Error');
            try {
                webSocketClient.close();
            } catch(e) {
                console.log(e);
            }
        };

        webSocketClient.onopen = function() {
            console.log('Ticker WebSocketClient Connected');
        };

        webSocketClient.onclose = function() {
            console.log('Ticker WebSocketClient Closed');
            setTimeout(startSymbolBookTicker, WS_CONN_RESTART_DELAY);
        };

        webSocketClient.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);

                    if(data.s){
                        if(tickers.current[data.s]){
                            tickers.current[data.s] = parseFloat(data.a);                            
                        }               
                    }
                }
            } catch(e) {
                console.log("Ticker WebSocketClient Message Error");
            }
        };
    }

    function startLocalWsConnection(){
    
        puWsConnLocal.current = new WebSocketClient("wss://at.ptx.ro/ws/");

        puWsConnLocal.current.onerror = function() {
            console.log('Local WebSocketClient Connection Error');
            try {
                puWsConnLocal.current.close();
            } catch(e) {
                console.log(e);
            }
        };

        puWsConnLocal.current.onopen = function() {
            console.log('Local WebSocketClient Connected');
        };

        puWsConnLocal.current.onclose = function() {
            console.log('Local WebSocketClient Closed');
            setTimeout(startLocalWsConnection, WS_CONN_RESTART_DELAY);
        };

        puWsConnLocal.current.onmessage = function(e) {
            try {
                if (typeof e.data === 'string') {
                    let data = JSON.parse(e.data);

                    if(data.event && data.event === "STATE_UPDATE"){
                        if(data.strategy && data.strategy === strategy.current){
                            setSlots(data.state);
                        }
                    }

                    if(data.event && data.event === "ACCOUNT_UPDATE_ORDER"){
                        if(data.strategy && data.strategy === strategy.current){
                            onWsAccountUpdateOrder(data.data);
                        }
                    }

                    if(data.event && data.event === "ACCOUNT_UPDATE_BALANCE"){
                        if(data.strategy && data.strategy === strategy.current){
                            onWsAccountUpdateBalance(data.data);
                        }
                    }                    

                }
            } catch(e) {
                console.log("Local WebSocketClient Message Error");
            }
        };
    }

    //orders update
    function onWsAccountUpdateOrder(data){

        try {

            let order_id = data.i;
            let event_type = data.x;
            let event_time = data.E;
            let side = data.S;
            let order_type = data.o;
            let price = parseFloat(data.p);
            let quantity = parseFloat(data.q);
            let filled_quantity = parseFloat(data.z);
            let symbol = data.s;
            let executedQty = data.z;
            let cummulativeQuoteQty = data.Z;
            let client_order_id = data.c;

            if(order_type !== "LIMIT"){   
                return;
            }

            //new 
            if(event_type === "NEW"){

                userTrades.current.unshift({
                    id: order_id.toString(),
                    time: event_time,
                    symbol: symbol,
                    type: order_type,
                    side: side,
                    price: price,
                    quantity: quantity,
                    filled_procent: parseInt(filled_quantity/quantity)*100,
                    total_usdt: price*quantity,
    
                    coin1: exchangeInfo.current[symbol] ? exchangeInfo.current[symbol].coin1 : "",
                    coin2: exchangeInfo.current[symbol] ? exchangeInfo.current[symbol].coin2 : "",
    
                    source: client_order_id.startsWith(strategy.current) ? "bot" : "manual"
                });

                setForceUpdate(Date.now());

            }

            //update
            if(event_type === "TRADE"){
                let itemIndex = -1;

                for(let i = 0; i < userTrades.current.length; i++){
                    if(userTrades.current[i].id.toString() === order_id.toString() && userTrades.current[i].symbol === symbol){
                        itemIndex = i;
                        break;
                    }
                }

                if(itemIndex === -1){
                    return;
                }
                
                let newFilledProcent = parseInt(filled_quantity/quantity)*100;

                if(userTrades.current[itemIndex].filled_procent !== newFilledProcent){

                    userTrades.current[itemIndex].filled_procent = newFilledProcent;

                    if(newFilledProcent === 100){
                        userTrades.current.splice(itemIndex, 1);
                    }

                    setForceUpdate(Date.now());
                }
            }

            //delete
            if(event_type === "CANCELED" || event_type === "EXPIRED"){

                for(let i = 0; i < userTrades.current.length; i++){
                    if(userTrades.current[i].id.toString() === order_id.toString()){
                        userTrades.current.splice(i, 1);
                        setForceUpdate(Date.now());
                        break;
                    }
                }
            }

        } catch(e) {
            console.log("onWsAccountUpdateOrder Error");
        }
    }

    //balances update
    function onWsAccountUpdateBalance(data){
        try {

            let arr = data.B;

            for(let i = 0; i < arr.length; i++){
                balances.current[arr[i].a] = {
                    free: parseFloat(arr[i].f),
                    locked: parseFloat(arr[i].l),
                }
            }

            processUserBalances();

        } catch(e) {
            console.log("onWsAccountUpdateBalance Error");
        }
    }

    function clickTimeline(value){
        localStorage["timeline_"+strategy.current] = value;
        setTimeline(localStorage["timeline_"+strategy.current]);
        getData();
    }

    function restartScript(){
        let pass = prompt("System will be restarted. Enter password to confirm");
        if (pass === null) {
            return;
        }

        pass = pass.trim();
        if(pass === ""){
            alert("Error");
            return;
        }


        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/restartScript.php?password="+pass+"&strategy="+strategy.current;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setLoading(true);
                    setTimeout(()=>{
                        getData();
                    }, 5000);
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });

    }


    function stopScript(){
        let pass = prompt("System will be shutdown. Enter password to confirm");
        if (pass === null) {
            return;
        }

        pass = pass.trim();
        if(pass === ""){
            alert("Error");
            return;
        }


        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/stopScript.php?password="+pass+"&strategy="+strategy.current;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setLoading(true);
                    setTimeout(()=>{
                        getData();
                    }, 10000);
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });

    }

    function sellCoin(coin, coin_dest, free, free_dest){
        if(coin === "USDT" || coin === "BNB"){
            return;
        }

        if(!exchangeInfo.current[coin+coin_dest].can_trade){
            alert("Cannot trade "+coin+coin_dest+" at this moment");
            return;
        }

        if(exchangeInfo.current[coin+coin_dest].min_trade_value > free_dest){
            alert("Cannot trade "+coin+coin_dest+": No minimum trade value");
            return;
        }

        let priceDecimals = Util.getDecimalsCount(exchangeInfo.current[coin+coin_dest].min_trade_price);
        let quantityDecimals = Util.getDecimalsCount(exchangeInfo.current[coin+coin_dest].min_trade_quantity);

        let amountSell = Util.toFixed(free, quantityDecimals);
        let forAmount = Util.toFixed(free_dest, priceDecimals);

        let r = window.confirm("Sell "+coin+" "+amountSell+" = "+forAmount+" "+coin_dest+" ?");
        if (!r) {
            return;
        }

        setSelectedSymbol({
            symbol: coin+coin_dest,
            coin1: coin,
            coin2: coin_dest,
            orderId: "",
        });
        setSideTrade("sell");

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/trade2.php?strategy="+strategy.current+"&coin1="+coin+"&coin2="+coin_dest+"&side=SELL&type=MARKET&quantity="+amountSell+"&price=0&client_order_id=";
    
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    if(json.trade_result != null && json.trade_result.orderId){

                        onTradeStatus({
                            side: "sell",
                            status: "success",
                        });
                        return;
                    }
                }

                onTradeStatus({
                    side: "sell",
                    status: "error",
                });
                
            })
            .catch((error) => {
                console.log(error);
                onTradeStatus({
                    side: "sell",
                    status: "error",
                });
            });
    }

    function convertToBnb(coin, free, free_dest){
        sellCoin(coin, "BNB", free, free_dest)
    }

    function convertToUsdt(coin, free, free_dest){
        sellCoin(coin, "USDT", free, free_dest)
    }

    function onChangeMasterSwitch(value){

        let checked = 0;
        if(value){
            checked = 1;
        }

        let url = Constants.API_URL+"/api/masterSwitch.php?strategy="+strategy.current+"&value="+checked;
  
        Fetch.request(url)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setMasterSwitch(value);
                } 
            })
            .catch((error) => {
                alert("Error");
            });
    }

    function onChangeExtraSlot(value){

        let checked = 0;
        if(value){
            checked = 1;
        }

        let url = Constants.API_URL+"/api/extraSlot.php?strategy="+strategy.current+"&value="+checked;
  
        Fetch.request(url)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setExtraSlot(value);
                } 
            })
            .catch((error) => {
                alert("Error");
            });
    }

    function onTradeStatus(res){
        setStatusTrade(res.status);
        setSnackbarTradeOpen(true);
        if(res.status === "success"){
            setTimeout(()=>{
                // getData();
            }, 2000);
        }
    }

    function handleSnackbarTradeClose(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarTradeOpen(false);
    }

    function handleSnackbarClose(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }

    function onSlotRemove(pair){
        setSelectedPair(pair);
        setRemoveSlotDialogOpen(true);
    }

    function removeSlot(){
        setRemoveSlotDialogOpen(false);
        if(!slots[selectedPair]){
            return;
        }

        try {
            if(puWsConnLocal.current != null){
                let json = {
                    source: "WEB_APP",
                    key: "WEB_APP",
                    data:{
                        event: "SLOT_REMOVE",
                        strategy: strategy.current,
                        pair: selectedPair,
                        coin1: slots[selectedPair].coin,
                        coin2: slots[selectedPair].coin2,
                    }
                }
                puWsConnLocal.current.send(JSON.stringify(json));
            }
        } catch(e) {
            console.log(e);
        }
    }

    function onSlotTradeStop(pair){
        if(!slots[pair]){
            return;
        }

        try {
            if(puWsConnLocal.current != null){
                let json = {
                    source: "WEB_APP",
                    key: "WEB_APP",
                    data:{
                        event: "SLOT_SET_STOP_TRADE",
                        strategy: strategy.current,
                        pair: pair,
                        coin1: slots[pair].coin,
                        coin2: slots[pair].coin2,
                        stop_trade: !slots[pair].stop_trade
                    }
                }
                puWsConnLocal.current.send(JSON.stringify(json));
            }
        } catch(e) {
            console.log(e);
        }
    }

    function onSpecsStatus(res){
        let txt = "";
        if(res.status === "success"){
            txt = "Bot settings updated successfully."
            setSpecs(res.specs);
        }else{
            txt = "Bot settings update failed."
        }
        setSnackbarStatus(res.status);
        setSnackbarText(txt);
        setSnackbarOpen(true);
    }



    function newBuy(){
        setSelectedSymbol({
            symbol: "BTCUSDT",
            coin1: "BTC",
            coin2: "USDT",
            orderId: "",
        });
        setTradeDialogOpen(true);
        setSideTrade("buy");
    }

    function newSell(){
        let coin1 = "";
        if(userBalances[0]){
            coin1 = userBalances[0].coin;
        }
        setSelectedSymbol({
            symbol: coin1+"USDT",
            coin1: coin1,
            coin2: "USDT",
            orderId: "",
        });
        setTradeDialogOpen(true);
        setSideTrade("sell");
    }

    const handleMenuClick = (event, obj) => {
        setAnchorElMenu(event.currentTarget);
        setSelectedSymbol(obj);
    };
    const handleMenuClose = () => {
        setAnchorElMenu(null);
    };

    function onMenuItemBuy(){
        handleMenuClose();
        setTimeout(()=>{
            setTradeDialogOpen(true);
            setSideTrade("buy");
        }, 300);
    }

    function onMenuItemSell(){
        handleMenuClose();
        setTimeout(()=>{
            setTradeDialogOpen(true);
            setSideTrade("sell");
        }, 300);
    }

    function onMenuItemCancel(){
        handleMenuClose();
        setTimeout(()=>{
            setConfirmCancelOrderDialog(true);
        }, 300);
    }

    function cancelOrder(){
        setConfirmCancelOrderDialog(false);
    
        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/tradeCancel.php?coin1="+selectedSymbol.coin1+"&coin2="+selectedSymbol.coin2+"&orderId="+selectedSymbol.orderId+"&strategy="+strategy.current;
    
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    if(json.trade_result.status != null && json.trade_result.status === "CANCELED"){
                        // getData();
                    }
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
        
    }

    function cancelAll(){
        setConfirmCancelAllDialog(false);

        let symbolsObj = {};
        for(let i = 0; i < userTrades.current.length; i++){
            symbolsObj[userTrades.current[i].symbol] = "1";
        }

        let symbols = "";
        let symbolsArr = Object.keys(symbolsObj);
        for(let i = 0; i < symbolsArr.length; i++){
            symbols += symbolsArr[i]+",";
        }

        if(symbols === ""){
            return;
        }

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/tradeCancelAll.php?symbols="+symbols+"&strategy="+strategy.current;
    
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                if(json.status === "ok"){
                    getData();
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    if(loading){
        return (<Loader />);
    }

    let strategyLabel = strategy.current;
    if(strategyLabel === "cc"){
        strategyLabel = "CC";
    }

    let timelineDayCss = "";
    let timelineMonthCss = "";

    if(timeline === "day"){
        timelineDayCss = styles.active;
    }

    if(timeline === "month"){
        timelineMonthCss = styles.active;
    }

    let masterSwitchLabel = "NO";
    if(masterSwitch){
        masterSwitchLabel = "YES";
    }

    let extraSlotLabel = "OFF";
    if(extraSlot){
        extraSlotLabel = "ON";
    }

    let exitReasonElm = [];
    if(systemStatus === "down" ){
        exitReasonElm.push((
            
            <div key="-1" className={styles.exitReason}>
                <span className={styles.exitReasonTxt1}>Bot {strategyLabel} is stopped</span>
                {/* <span className={styles.exitReasonTxt2}>&nbsp;</span> */}
            </div>
            
        ));
    }

    if(exitReasons.length > 0){
        for(let i = 0; i < exitReasons.length; i++){
            let item = exitReasons[i];

            exitReasonElm.push((
            
                <div key={item.id} className={styles.exitReason}>
                    <span className={styles.exitReasonTxt1}>{item.txt0}</span>
                    <span className={styles.exitReasonTxt2}>{item.txt1+" "+item.txt2}</span>
                    <span className={styles.exitReasonClose} onClick={()=>{clearLog(item.id)}}><IcoX /></span>
                </div>
                
            ));
            
        }
    }    

    let slotsArr = [];
    let slotsKeys = Object.keys(slots);
    for(let i = 0; i < slotsKeys.length; i++){
        slotsArr.push(slots[slotsKeys[i]]);
    }

    let colapsibleHeight = (slotsArr.length+1)*80;
    colapsibleHeight = colapsibleHeight+50; //50 for extra slot

    let specificationsElm = null;
    if(hasSpecifications){
        let readOnly = false;
        if(masterSwitch || slotsArr.length > 0){
            readOnly = true;
        }

        specificationsElm = (
            <div>
                <div className={styles.title}>
                    Specifications
                    <span className={styles.actionBtn+" "} onClick={()=>{setSpecs({...SPECS["1m"]})}} style={{marginLeft: "20px"}}>s1m</span> 
                </div>
                <SpecsInput strategy={strategy.current} specs={specs} readOnly={readOnly} tickers={tickers.current} />
            </div>
        );
    }

    return (
        <div className={styles.root}>

            <div className={styles.mobile}>

                <div style={{marginTop: 30}}>
                    {exitReasonElm}
                </div>

                <LogoutLink />

                <div className={styles.title}>
                    Balances
                    <span className={styles.actionBtn+" "} onClick={newBuy} style={{marginLeft: "20px"}}>Buy</span> 
                    <span className={styles.actionBtn+" "} onClick={newSell}>Sell</span>

                    {
                        hasSpecifications ? null :
                        <span className={styles.actionBtn+" "} style={{float: "right", marginRight: 0}} onClick={()=>{setSpecsDialogOpen(true)}}>Settings</span>
                    }
                    
                </div>
                <div className={styles.balances}>
                    {userBalances.map((row, index) => {
                        return (
                            <ItemBalance 
                                key={row.coin}  
                                view_type="list" 
                                nr_crt={index+1} 
                                coin={row.coin} 
                                free={row.free} 
                                locked={row.locked} 
                                total={row.total} 
                                free_usdt={row.free_usdt} 
                                locked_usdt={row.locked_usdt} 
                                total_usdt={row.total_usdt}
                                free_bnb={row.free_bnb} 
                                locked_bnb={row.locked_bnb} 
                                total_bnb={row.total_bnb}
                                convertToBnb={convertToBnb}
                                convertToUsdt={convertToUsdt}
                                price_decimals={row.price_decimals}
                                quantity_decimals={row.quantity_decimals}
                            />
                        )
                    })}
                </div>


                {specificationsElm}



                <div className={styles.title}>Enter new trades</div>

                <div style={{display: "inline-block", width:"100%", paddingLeft: "16px", paddingRight: "16px", marginTop: "20px"}}>
                    <div style={{float: "left", marginTop: "7px"}}>Strategy {strategyLabel}: {masterSwitchLabel}</div>
                    <div style={{float: "right"}}>
                        <ToggleInput bgOff="#434C5A" bgOn="#EFBA0C" onChange={onChangeMasterSwitch} checked={masterSwitch} />
                    </div>
                </div>

                <div className={styles.title}>
                    Open orders
                    <span className={styles.actionBtn+" "} onClick={()=>{setConfirmCancelAllDialog(true)}} style={{marginLeft: "20px"}}>Cancel All</span> 
                </div>
                <div className={styles.trades}>
                    {userTrades.current.length === 0 ? (<span className={styles.tradesEmpty}>No open orders</span>) : null}
                    {userTrades.current.map((row, index) => {
                        return (
                            <ItemTrade 
                                key={row.id}  
                                view_type="list" 
                                nr_crt={index+1} 
                                id={row.id}
                                time={row.time}
                                symbol={row.symbol}
                                type={row.type}
                                side={row.side}
                                price={row.price}
                                quantity={row.quantity}
                                showMenu={true}
                                source={row.source}
                                filled_procent={row.filled_procent}
                                total_usdt={row.total_usdt}>
                                    <div onClick={(e)=>{handleMenuClick(e, {symbol: row.symbol, orderId: row.id, coin1: row.coin1, coin2: row.coin2})}} className={styles.menu}></div>
                            </ItemTrade>
                        )
                    })}
                </div>

                <div className={styles.title}>{sellType.current === "LIMIT" ? "Symbols In Slots" : "Trailing Sell"}</div>
                <div className={styles.trades}>
                    {slotsArr.length === 0 ? (<span className={styles.tradesEmpty}>{sellType.current === "LIMIT" ? "No symbols" : "No trailing sells"}</span>) : null}
                    {slotsArr.map((row, index) => {
                        return (
                            <ItemTrail 
                                key={row.pair}  
                                nr_crt={index+1} 
                                coin={row.coin}
                                coin2={row.coin2}
                                target_price={row.target_price}
                                sell_price={row.sell_price}
                                price_initial={row.price_initial}
                                price_new={row.price_new}

                                buy_amount_arr={row.buy_amount_arr}
                                buy_price_arr={row.buy_price_arr}

                                buy_count={row.buy_count}

                                strategy={strategy.current}
                                sellType={sellType.current}
                                slot={row}
                                exchange_info={exchangeInfo.current}
                                tickers={tickers.current}
                            />
                        )
                    })}
                </div>

                <ItemCollapsible 
                    collapsed={collapsedSlots}
                    maxHeight={colapsibleHeight}
                    visibleChildren={(<div className={styles.title} onClick={()=>{setCollapsedSlots(!collapsedSlots)}}>Slots <IcoArrow direction={collapsedSlots ? "down" : "up"} /></div>)}
                    collapsibleChildren={(
                        <div className={styles.trades}>
                            {/* <div style={{display: "inline-block", width:"100%", paddingLeft: "16px", paddingRight: "16px", marginTop: "20px"}}>
                                <div style={{float: "left", marginTop: "7px"}}>Extra slot: {extraSlotLabel}</div>
                                <div style={{float: "right"}}>
                                    <ToggleInput bgOff="#434C5A" bgOn="#EFBA0C" onChange={onChangeExtraSlot} checked={extraSlot} />
                                </div>
                            </div> */}
                            {slotsArr.length === 0 ? (<span className={styles.tradesEmpty}>Empty slots</span>) : null}
                            {slotsArr.map((row, index) => {
                                return (
                                    <ItemSlot 
                                        key={row.pair}  
                                        nr_crt={index+1} 
                                        pair={row.pair}  
                                        coin={row.coin}
                                        coin2={row.coin2}
                                        stop_trade={row.stop_trade}
                                        onSlotRemove={onSlotRemove}
                                        onSlotTradeStop={onSlotTradeStop}
                                    />
                                )
                            })}
                        </div>
                    )}
                />

                <div className={styles.title}>
                    Balance history 
                    <span className={styles.timeline+" "+timelineDayCss} onClick={()=>{clickTimeline("day")}} style={{marginLeft: "20px"}}>1D</span> 
                    <span className={styles.timeline+" "+timelineMonthCss} onClick={()=>{clickTimeline("month")}}>1M</span>

                    <a className={styles.actionBtn+" "} style={{marginLeft: "10px"}} target="_blank" href={"/chart-balance?strategy="+strategy.current} rel="noreferrer">Chart</a> 
                </div>
                <div className={styles.balanceHistory}>
                    {userBalanceHistory.map((row, index) => {
                        return (
                            <ItemBalanceHistory 
                                key={index}  
                                view_type="list" 
                                nr_crt={index+1} 
                                day={row.day} 
                                date={row.date} 
                                symbol={row.symbol} 
                                amount={row.amount} 
                                revenue={row.revenue} 
                                profit={row.profit} 
                            />
                        )
                    })}
                </div>

                <div className={styles.title}>
                    Bot {strategyLabel} is {systemStatus} &nbsp;
                    <span className={styles.actionBtn} onClick={restartScript}>Restart Bot</span> 
                    <span className={styles.actionBtn} onClick={stopScript}>Stop Bot</span> 
                </div>
            </div>

            <TradeDialog 
                open={tradeDialogOpen} 
                strategy={strategy.current} 
                side={sideTrade} 
                coin1={selectedSymbol.coin1}
                coin2={selectedSymbol.coin2}
                dynamic={true}
                onStatus={onTradeStatus} 
                onCancel={()=>{setTradeDialogOpen(false)}} 
                onOk={()=>{setTradeDialogOpen(false)}} 
            />  

            <Snackbar open={snackbarTradeOpen} autoHideDuration={6000} onClose={handleSnackbarTradeClose}>
                <Alert onClose={handleSnackbarTradeClose} severity={statusTrade} sx={{ width: '100%' }}>
                    <span style={{textTransform: "capitalize"}}>{sideTrade}</span> {statusTrade === "success" ? "completed successfully." : "failed."}
                </Alert>
            </Snackbar>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarStatus} sx={{ width: '100%' }}>
                    {snackbarText}
                </Alert>
            </Snackbar>

            <ConfirmDialog 
                open={removeSlotDialogOpen} 
                title="Remove slot" 
                text={"Remove "+selectedPair+" from slots ?"} 
                okLabel="Remove" 
                cancelLabel="Cancel" 
                onOk={removeSlot} 
                onCancel={()=>{setRemoveSlotDialogOpen(false)}} />  


            <SpecsInputDialog 
                open={specsDialogOpen} 
                strategy={strategy.current} 
                specs={specs}
                onStatus={onSpecsStatus} 
                onCancel={()=>{setSpecsDialogOpen(false)}} 
                onOk={()=>{setSpecsDialogOpen(false)}} 
            /> 

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorElMenu}
                open={openMenu}
                onClose={handleMenuClose}>

                <MenuItem onClick={onMenuItemBuy} disableRipple>
                    <IcoDolar fill="rgb(55, 65, 81)" style={{marginRight: 12, width:16}}/> 
                    {"Buy "+selectedSymbol.coin1+" with "+selectedSymbol.coin2}
                </MenuItem>  

                <MenuItem onClick={onMenuItemSell} disableRipple>
                    <IcoDolar fill="rgb(55, 65, 81)" style={{marginRight: 12, width:16}}/> 
                    {"Sell "+selectedSymbol.coin1+" for "+selectedSymbol.coin2}
                </MenuItem>

                <MenuItem onClick={onMenuItemCancel} disableRipple>
                    <IcoRemove style={{marginRight: 12}}/> 
                    Cancel Order
                </MenuItem>
            </StyledMenu>

            <ConfirmDialog 
                open={confirmCancelOrderDialog} 
                title="Cancel Order" 
                text={"Cancel "+selectedSymbol.symbol+" order ?"} 
                okLabel="Yes" 
                cancelLabel="No" 
                onOk={cancelOrder} 
                onCancel={()=>{setConfirmCancelOrderDialog(false)}} /> 


            <ConfirmDialog 
                open={confirmCancelAllDialog} 
                title="Cancel All Orders" 
                text={"Cancel all orders ?"} 
                okLabel="Yes" 
                cancelLabel="No" 
                onOk={cancelAll} 
                onCancel={()=>{setConfirmCancelAllDialog(false)}} /> 


        </div>
    );
}

export default Local;


